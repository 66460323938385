import React from "react";
import styled from "styled-components";
import { useMinLoadingTime } from "../../hooks/useMinLoadingTime";
import { LoadingScreen } from "./LoadingScreen";

interface MinLoadingScreenProps {
  isLoading: boolean;
  minLoadingTime?: number;
}

const FadeWrapper = styled.div<{ $isVisible: boolean }>`
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  pointer-events: ${({ $isVisible }) => ($isVisible ? "auto" : "none")};
`;

export const MinLoadingScreen: React.FC<MinLoadingScreenProps> = ({
  isLoading,
  minLoadingTime = 1000,
}) => {
  const shouldShowLoading = useMinLoadingTime({ isLoading, minLoadingTime });
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    if (shouldShowLoading) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 300); // Tempo para a animação de fade out
      return () => clearTimeout(timer);
    }
  }, [shouldShowLoading]);

  if (!shouldShowLoading && !isVisible) {
    return null;
  }

  return (
    <FadeWrapper $isVisible={isVisible}>
      <LoadingScreen />
    </FadeWrapper>
  );
};
