import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as CleeckyKit from "../../CleeckyKit";
import LogoSvg from "../../assets/images/cleecky-slogan-logo-black-minimalist-1.svg";
import LogoSvg2 from "../../assets/images/cleecky-slogan-logo-black-minimalist-2.svg";
import { AccountDropdown, AppToolsModal } from "components";
import { useAuth } from "../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { routes, RouteDefinition } from "../../routes/routes";
import { AnimatePresence, motion } from "framer-motion";

interface MainLayoutProps {
  children: React.ReactNode;
  hideNavigation?: boolean;
}

export const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  hideNavigation = false,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [currentLogo, setCurrentLogo] = useState(LogoSvg);
  const [selectedApp, setSelectedApp] = useState<RouteDefinition | null>(null);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const isHome = location.pathname === "/";

  useEffect(() => {
    setIsSidebarExpanded(false);
  }, []);

  const handleAppClick = (app: RouteDefinition) => {
    if (app.children?.length) {
      navigate(app.path);
    } else {
      navigate(app.path);
    }
  };

  const isMobile = window.innerWidth <= 768;
  const isTablet = window.innerWidth <= 1024 && window.innerWidth > 768;

  const handleSidebarToggle = () => {
    if (isMobile || isTablet) {
      setIsMobileOpen(!isMobileOpen);
    } else {
      setIsSidebarExpanded(!isSidebarExpanded);
    }
  };

  if (isHome) {
    return (
      <CleeckyKit.BaseLayout hasNavbar={false} hasSidebar={false}>
        <CleeckyKit.Background size="fullscreen" gradient>
          {children}
        </CleeckyKit.Background>
      </CleeckyKit.BaseLayout>
    );
  }

  return (
    <CleeckyKit.BaseLayout
      hasNavbar={!hideNavigation}
      hasSidebar={!hideNavigation}
      sidebarWidth={isSidebarExpanded ? "240px" : "80px"}>
      {!hideNavigation && (
        <>
          <CleeckyKit.Navbar
            isFixed
            shadow
            blur
            isSidebarExpanded={isSidebarExpanded}
            isMobileOpen={isMobileOpen}
            showIconsWhenCollapsed={true}>
            <CleeckyKit.NavbarSection align="start">
              {(isMobile || isTablet) && (
                <CleeckyKit.SidebarHamburger
                  onClick={handleSidebarToggle}
                  isOpen={isMobileOpen}
                />
              )}
              <CleeckyKit.Image
                href="/"
                src={currentLogo}
                height="3rem"
                fit="contain"
                alt="Cleecky Logo"
                format="svg"
                fill="white"
                onMouseDown={() => setCurrentLogo(LogoSvg2)}
                onMouseUp={() => setCurrentLogo(LogoSvg)}
                onMouseLeave={() => setCurrentLogo(LogoSvg)}
                animation={{
                  initial: { opacity: 0, y: -20 },
                  animate: { opacity: 1, y: 0 },
                  transition: { duration: 0.5 },
                }}
              />
            </CleeckyKit.NavbarSection>

            <CleeckyKit.NavbarSection align="end">
              <AccountDropdown />
            </CleeckyKit.NavbarSection>
          </CleeckyKit.Navbar>

          <CleeckyKit.Sidebar
            customStyle={{
              paddingTop: "4rem",
            }}
            position="left"
            isExpandable={!isMobile && !isTablet}
            defaultExpanded={false}
            onExpandChange={setIsSidebarExpanded}
            isMobileOpen={isMobileOpen}
            onMobileOpenChange={setIsMobileOpen}>
            {routes.map((route) => (
              <React.Fragment key={route.path}>
                <CleeckyKit.SidebarItem
                  icon={<FontAwesomeIcon icon={route.icon} />}
                  label={route.name}
                  onClick={() => handleAppClick(route)}
                  isActive={location.pathname === route.path}
                  isExpanded={isSidebarExpanded || isMobileOpen}
                />
                {(isSidebarExpanded || isMobileOpen) && (
                  <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.6, ease: "easeInOut" }}>
                      {route.children?.map((child, index) => (
                        <motion.div
                          key={child.path}
                          initial={{ x: -20, opacity: 0 }}
                          animate={{ x: 0, opacity: 1 }}
                          transition={{
                            duration: 0.2,
                            delay: index * 0.1,
                            ease: "easeOut",
                          }}>
                          <CleeckyKit.SidebarItem
                            icon={<FontAwesomeIcon icon={child.icon} />}
                            label={child.name}
                            isActive={location.pathname.startsWith(child.path)}
                            onClick={() => handleAppClick(child)}
                            customStyle={{
                              marginLeft: "1.5rem",
                              fontSize: "0.9em",
                              padding: "0.5rem 1rem",
                              borderLeft: "2px solid rgba(255,255,255,0.1)",
                            }}
                          />
                        </motion.div>
                      ))}
                    </motion.div>
                  </AnimatePresence>
                )}
              </React.Fragment>
            ))}
          </CleeckyKit.Sidebar>
        </>
      )}
      <CleeckyKit.Background size="fullscreen">
        <CleeckyKit.Container>
          <CleeckyKit.Row justify="center">{children}</CleeckyKit.Row>
        </CleeckyKit.Container>
      </CleeckyKit.Background>

      {selectedApp && (
        <AppToolsModal
          isOpen={!!selectedApp}
          onClose={() => setSelectedApp(null)}
          app={selectedApp}
        />
      )}
    </CleeckyKit.BaseLayout>
  );
};
