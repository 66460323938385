import { useState, useEffect } from "react";

export const useCleeckyKitScrollDirection = (
  isSidebarExpanded: boolean = false,
  isMobileOpen: boolean = false,
  showIconsWhenCollapsed: boolean = true
) => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    const handleScroll = () => {
      // No mobile, só considera o isMobileOpen
      if (isMobile) {
        if (isMobileOpen) {
          setIsVisible(true);
          return;
        }
      } else {
        // No desktop, considera tanto o expanded quanto o showIcons
        if (isSidebarExpanded || showIconsWhenCollapsed) {
          setIsVisible(true);
          return;
        }
      }

      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY) {
        setIsVisible(false); // Scroll para baixo - esconde
      } else {
        setIsVisible(true); // Scroll para cima - mostra
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [
    lastScrollY,
    isSidebarExpanded,
    isMobileOpen,
    showIconsWhenCollapsed,
    isMobile,
  ]);

  return isVisible;
};
