import React from "react";
import styled, { css } from "styled-components";
import { CleeckyKitGradientBackground } from "../../themes/themeUtilities/CleeckyKitBackground";
import { CleeckyKitGradientAnimation } from "../../themes/animations/CleeckyKitGradientAnimations";
import { useCleeckyKitCurrentTheme } from "../../themes/themeUtilities/CleeckyKitThemeProvider";

type BackgroundSize = "fullscreen" | "fullspace" | "element" | "custom";

interface BackgroundProps {
  children?: React.ReactNode;
  color?: string;
  gradient?: boolean;
  size?: BackgroundSize;
  customSize?: {
    width?: string;
    height?: string;
    minWidth?: string;
    minHeight?: string;
    maxWidth?: string;
    maxHeight?: string;
  };
  enableAnimation?: boolean;
  animation?: CleeckyKitGradientAnimation;
  className?: string;
}

const BackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
`;

const ContentLayer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: inherit;
  z-index: 1;
  flex: 1;
`;

export const CleeckyKitBackground: React.FC<BackgroundProps> = ({
  children,
  color,
  gradient = false,
  size = "element",
  customSize,
  enableAnimation = false,
  animation,
  className,
}) => {
  const { currentGradient, currentTheme } = useCleeckyKitCurrentTheme();

  if (gradient) {
    return (
      <BackgroundWrapper className={className}>
        <CleeckyKitGradientBackground
          style={{
            position: size === "fullscreen" ? "fixed" : "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: size === "fullscreen" ? "100dvw" : "100%",
            height: size === "fullscreen" ? "100dvh" : "100%",
            zIndex: -1,
            ...(size === "custom" && {
              width: customSize?.width || "auto",
              height: customSize?.height || "auto",
              minWidth: customSize?.minWidth || "auto",
              minHeight: customSize?.minHeight || "auto",
              maxWidth: customSize?.maxWidth || "none",
              maxHeight: customSize?.maxHeight || "none",
            }),
          }}
          $enableAnimation={enableAnimation}
          $animation={animation || currentGradient?.animation}
        />
        <ContentLayer>{children}</ContentLayer>
      </BackgroundWrapper>
    );
  }

  return (
    <BackgroundWrapper className={className}>
      <div
        style={{
          position: size === "fullscreen" ? "fixed" : "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: size === "fullscreen" ? "100dvw" : "100%",
          height: size === "fullscreen" ? "100dvh" : "100%",
          zIndex: -1,
          background: color || currentTheme.colors.background.base,
          ...(size === "custom" && {
            width: customSize?.width || "auto",
            height: customSize?.height || "auto",
            minWidth: customSize?.minWidth || "auto",
            minHeight: customSize?.minHeight || "auto",
            maxWidth: customSize?.maxWidth || "none",
            maxHeight: customSize?.maxHeight || "none",
          }),
        }}
      />
      <ContentLayer>{children}</ContentLayer>
    </BackgroundWrapper>
  );
};
