import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

export interface ListItemProps {
  children?: React.ReactNode;
  orderLabel?: string;
  icon?: React.ReactNode;
  title?: string;
  subtitle?: string;
  description?: string;
  value?: React.ReactNode;
  actions?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  background?: string;
  borderRadius?: string;
  className?: string;
  hoverable?: boolean;
}

interface StyledProps {
  $disabled?: boolean;
  $clickable?: boolean;
  $background?: string;
  $borderRadius?: string;
  $hoverable?: boolean;
}

const ItemContainer = styled(motion.div)<StyledProps>`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: ${({ $background, theme }) =>
    $background || theme.colors.background.card};
  border: none;
  border-radius: ${({ $borderRadius }) => $borderRadius || "0.5rem"};
  cursor: ${({ $disabled, $clickable }) =>
    $disabled ? "not-allowed" : $clickable ? "pointer" : "default"};
  width: 100%;
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  transition: all 0.2s ease;

  ${({ $hoverable, theme, $background }) =>
    $hoverable &&
    `
    &:hover:not(:disabled) {
      background: ${
        $background ? `${$background}dd` : theme.colors.background.hover
      };
      transform: translateY(-2px);
    }
  `}
`;

const OrderLabel = styled.div`
  min-width: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: 500;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex: 1;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1rem;
  font-weight: 500;
`;

const Subtitle = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.875rem;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.875rem;
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: 500;
  margin-left: auto;
  padding-left: 1rem;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 1rem;
`;

export const CleeckyKitListItem: React.FC<ListItemProps> = ({
  children,
  orderLabel,
  icon,
  title,
  subtitle,
  description,
  value,
  actions,
  onClick,
  disabled = false,
  background,
  borderRadius,
  className,
  hoverable = true,
}) => {
  const content = children || (
    <>
      {orderLabel && <OrderLabel>{orderLabel}</OrderLabel>}
      {icon && <IconContainer>{icon}</IconContainer>}
      <ContentContainer>
        {(title || subtitle) && (
          <TitleContainer>
            {title && <Title>{title}</Title>}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </TitleContainer>
        )}
        {description && <Description>{description}</Description>}
      </ContentContainer>
      {value && <Value>{value}</Value>}
      {actions && (
        <ActionsContainer onClick={(e) => e.stopPropagation()}>
          {actions}
        </ActionsContainer>
      )}
    </>
  );

  return (
    <ItemContainer
      className={className}
      $disabled={disabled}
      $clickable={!!onClick}
      $background={background}
      $borderRadius={borderRadius}
      $hoverable={hoverable}
      onClick={disabled ? undefined : onClick}
      whileHover={hoverable && !disabled ? { scale: 1.02 } : {}}
      whileTap={hoverable && !disabled ? { scale: 0.98 } : {}}>
      {content}
    </ItemContainer>
  );
};
