import { createGlobalStyle } from "styled-components";
import { CleeckyKitTheme } from "./CleeckyKitTheme";

export const CleeckyKitGlobalStyle = createGlobalStyle<{
  theme: CleeckyKitTheme;
}>`
  :root {
    font-size: ${({ theme }) => theme.responsive.fontSize.base};

    @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
      font-size: ${({ theme }) => theme.responsive.fontSize.mobile};
    }
  }

  body {
    margin: 0;
    padding: 0;
    min-height: 100dvh;
    width: 100%;
    overflow-x: hidden;
    
  }

  h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}


  /* Seleção de texto */
  ::selection {
    background-color: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.selection.background};
    color: ${({ theme }) => theme.colors.text.onGradient};
  }
`;
