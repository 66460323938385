import React from "react";
import styled from "styled-components";

interface CleeckyKitGridItemProps {
  children: React.ReactNode;
  row?: number;
  col?: number;
  className?: string;
}

const StyledGridItem = styled.div<{ $row?: number; $col?: number }>`
  grid-row: ${({ $row }) => ($row ? `span ${$row}` : "auto")};
  grid-column: ${({ $col }) => ($col ? `span ${$col}` : "auto")};
  min-width: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  box-sizing: border-box;

  /* Previne que animações dos filhos afetem o layout */
  & > * {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0);
    will-change: transform;
  }
`;

export const CleeckyKitGridItem: React.FC<CleeckyKitGridItemProps> = ({
  children,
  row,
  col,
  className,
}) => {
  return (
    <StyledGridItem $row={row} $col={col} className={className}>
      {children}
    </StyledGridItem>
  );
};
