import React, { useState } from "react";
import * as CleeckyKit from "CleeckyKit";
import { t } from "i18next";
import {
  faCalendarDays,
  faEarthAmericas,
  faExpand,
  faLightbulb,
  faLink,
  faPeopleGroup,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flag from "react-world-flags";

export const DetailedMetrics: React.FC = () => {
  const { currentGradient, currentTheme } =
    CleeckyKit.useCleeckyKitCurrentTheme();
  const [selectedPeriodAccesses, setSelectedPeriodAccesses] =
    useState("current_week");
  const [selectedPeriodLocations, setSelectedPeriodLocations] =
    useState("current_week");

  const periodOptions = [
    {
      label: t("project:common.filters.period.current_week"),
      value: "current_week",
    },
    {
      label: t("project:common.filters.period.current_month"),
      value: "current_month",
    },
    {
      label: t("project:common.filters.period.current_year"),
      value: "current_year",
    },
  ];

  return (
    <CleeckyKit.Section>
      <CleeckyKit.Row>
        <CleeckyKit.Column gap="1rem">
          <CleeckyKit.Title level={3}>
            {t(
              "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.title"
            )}
          </CleeckyKit.Title>
        </CleeckyKit.Column>
      </CleeckyKit.Row>

      <CleeckyKit.Grid mode="bento" gap="1rem">
        <CleeckyKit.GridItem row={2} col={2}>
          <CleeckyKit.Card>
            <CleeckyKit.Column justify="between">
              <CleeckyKit.Section>
                <CleeckyKit.Column>
                  <CleeckyKit.Row justify="between" noBreak>
                    <CleeckyKit.Title level={6}>
                      {" "}
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.acesses"
                      )}
                    </CleeckyKit.Title>
                    <CleeckyKit.Select
                      options={periodOptions}
                      value={selectedPeriodAccesses}
                      onChange={(value) =>
                        setSelectedPeriodAccesses(value as string)
                      }
                      placeholder={t(
                        "project:common.filters.period.select_period"
                      )}
                    />
                  </CleeckyKit.Row>
                  <CleeckyKit.Row justify="center">
                    <CleeckyKit.BarChart
                      data={{
                        categories: [
                          "Dom",
                          "Seg",
                          "Ter",
                          "Qua",
                          "Qui",
                          "Sex",
                          "Sáb",
                        ],
                        values: [10, 15, 20, 30, 25, 18, 12],
                        highlightIndex: 3,
                      }}
                      showAverage={true}
                    />
                  </CleeckyKit.Row>
                </CleeckyKit.Column>
              </CleeckyKit.Section>

              <CleeckyKit.Section>
                <CleeckyKit.Column>
                  <CleeckyKit.Row justify="start">
                    <CleeckyKit.Title level={6}>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.access_sources"
                      )}
                    </CleeckyKit.Title>
                  </CleeckyKit.Row>
                  <CleeckyKit.Row>
                    <CleeckyKit.List gap="0.5rem" hoverable={false}>
                      <CleeckyKit.ListItem
                        icon={
                          <FontAwesomeIcon icon={faPeopleGroup} color="white" />
                        }
                        title={t(
                          "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.social_media"
                        )}
                        value="40%"
                        background={currentGradient?.highlightColor}
                        borderRadius="0.75rem"
                      />
                      <CleeckyKit.ListItem
                        icon={<FontAwesomeIcon icon={faLink} color="white" />}
                        title={t(
                          "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.direct_link"
                        )}
                        value="35%"
                        background={currentTheme.colors.background.card}
                        borderRadius="0.75rem"
                      />
                      <CleeckyKit.ListItem
                        icon={<FontAwesomeIcon icon={faSearch} color="white" />}
                        title={t(
                          "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.searches_and_sites"
                        )}
                        value="25%"
                        background={currentTheme.colors.background.card}
                        borderRadius="0.75rem"
                      />
                    </CleeckyKit.List>
                  </CleeckyKit.Row>
                </CleeckyKit.Column>
              </CleeckyKit.Section>
            </CleeckyKit.Column>
          </CleeckyKit.Card>
        </CleeckyKit.GridItem>

        <CleeckyKit.GridItem row={1} col={1}>
          <CleeckyKit.Card backgroundColor={currentGradient?.highlightColor}>
            <CleeckyKit.Column justify="between">
              <CleeckyKit.Row noBreak gap={2}>
                <CleeckyKit.Title level={4}>
                  {t(
                    "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.suggestion_card.title"
                  )}
                </CleeckyKit.Title>
                <CleeckyKit.Title level={3}>
                  <FontAwesomeIcon icon={faLightbulb} />
                </CleeckyKit.Title>
              </CleeckyKit.Row>
              <CleeckyKit.Row>
                <CleeckyKit.Text>
                  {t(
                    "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.suggestion_card.description"
                  )}
                </CleeckyKit.Text>
              </CleeckyKit.Row>
            </CleeckyKit.Column>
          </CleeckyKit.Card>
        </CleeckyKit.GridItem>

        <CleeckyKit.GridItem row={1} col={1}>
          <CleeckyKit.Card>
            <CleeckyKit.Column gap="1rem">
              <CleeckyKit.Row justify="center">
                <CleeckyKit.PieChart
                  data={{
                    value: 27,
                    valueLabel: t(
                      "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.interaction_rate_card.pie_chart.interacted_access"
                    ),
                    reamingLabel: t(
                      "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.interaction_rate_card.pie_chart.accesses_without_interaction"
                    ),
                    total: 110,
                    label: t(
                      "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.interaction_rate_card.title"
                    ),
                  }}
                />
              </CleeckyKit.Row>
              <CleeckyKit.Row justify="between" noBreak>
                <CleeckyKit.Column>
                  <CleeckyKit.Row justify="center">
                    <CleeckyKit.Section
                      background={currentTheme.colors.charts.pie.background}
                      borderRadius={10}
                      width="fit-content"
                      height="fit-content">
                      <CleeckyKit.Column justify="center">
                        <CleeckyKit.Title level={6} align="center">
                          110
                        </CleeckyKit.Title>
                      </CleeckyKit.Column>
                    </CleeckyKit.Section>
                  </CleeckyKit.Row>
                  <CleeckyKit.Row justify="center">
                    <CleeckyKit.Text align="center">
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.interaction_rate_card.pie_chart.total_access"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Row>
                </CleeckyKit.Column>
                <CleeckyKit.Column>
                  <CleeckyKit.Row justify="center">
                    <CleeckyKit.Section
                      background={currentGradient?.highlightColor}
                      borderRadius={10}
                      width="fit-content"
                      height="fit-content">
                      <CleeckyKit.Title level={6} align="center">
                        27
                      </CleeckyKit.Title>
                    </CleeckyKit.Section>
                  </CleeckyKit.Row>
                  <CleeckyKit.Row justify="center">
                    <CleeckyKit.Text align="center">
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.interaction_rate_card.pie_chart.interacted_access"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Row>
                </CleeckyKit.Column>
              </CleeckyKit.Row>
            </CleeckyKit.Column>
          </CleeckyKit.Card>
        </CleeckyKit.GridItem>

        <CleeckyKit.GridItem row={1} col={2}>
          <CleeckyKit.Card>
            <CleeckyKit.Column>
              <CleeckyKit.Row justify="between" noBreak>
                <CleeckyKit.Title level={6}>Localidades</CleeckyKit.Title>
                <CleeckyKit.Select
                  options={periodOptions}
                  value={selectedPeriodLocations}
                  onChange={(value) =>
                    setSelectedPeriodLocations(value as string)
                  }
                  placeholder={t("project:common.filters.period.select_period")}
                />
              </CleeckyKit.Row>
              <CleeckyKit.Row>
                <CleeckyKit.List
                  hoverable={false}
                  orderType="number"
                  startOrder={1}
                  gap="0.5rem"
                  background={currentTheme.colors.background.card}
                  borderRadius="0.75rem">
                  <CleeckyKit.ListItem
                    icon={<Flag code="BR" height="16" />}
                    title="São Paulo (SP)"
                    value="2.000"
                  />
                  <CleeckyKit.ListItem
                    icon={<Flag code="BR" height="16" />}
                    title="Marília (SP)"
                    value="1.020"
                  />
                  <CleeckyKit.ListItem
                    icon={<Flag code="CA" height="16" />}
                    title="Vancouver (BC)"
                    value="131"
                  />
                  <CleeckyKit.ListItem
                    icon={<Flag code="BR" height="16" />}
                    title="Cuiabá (MT)"
                    value="20"
                  />
                  <CleeckyKit.ListItem
                    icon={<FontAwesomeIcon icon={faEarthAmericas} />}
                    title="Localidade não compartilhada"
                    value="306"
                  />
                </CleeckyKit.List>
              </CleeckyKit.Row>
            </CleeckyKit.Column>
          </CleeckyKit.Card>
        </CleeckyKit.GridItem>
      </CleeckyKit.Grid>
    </CleeckyKit.Section>
  );
};
