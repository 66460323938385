import { CleeckysInsiderData } from "cleecky@types";
import api from "../axiosConfig";

export const cleeckysInsidersService = {
  subscribe: async (data: CleeckysInsiderData) => {
    const response = await api.post("/shared/cleeckys-insider", data);
    return response.data;
  },

  getMySubscriptions: async () => {
    const response = await api.get("/shared/cleeckys-insider/my-subscriptions");
    return response.data;
  },
};
