import React from "react";
import * as yup from "yup";
import * as CleeckyKit from "../../CleeckyKit/components";
import { t } from "i18next";
import { authService } from "services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt } from "@fortawesome/free-solid-svg-icons";

interface SignUpModalProps {
  onToggleMode: () => void;
  onClose?: () => void;
}

export const SignUpModal: React.FC<SignUpModalProps> = ({
  onToggleMode,
  onClose,
}) => {
  const handleSubmit = async (data: any) => {
    try {
      const { confirmPassword, ...userData } = data;
      await authService.signup(userData);
      onClose?.();
    } catch (error) {
      console.error("Erro no registro:", error);
    }
  };

  return (
    <CleeckyKit.Form onSubmit={handleSubmit} resetOnSubmit={false}>
      <CleeckyKit.FormGroup>
        <CleeckyKit.FormItem span={6}>
          <CleeckyKit.TextInput
            name="firstName"
            label={t("cleeckykit:common.form.name.label")}
            placeholder={t("cleeckykit:common.form.name.placeholder")}
            borderRadius="1rem"
            fullWidth
            validationVariant="tooltip"
            validations={{
              required: true,
            }}
          />
        </CleeckyKit.FormItem>

        <CleeckyKit.FormItem span={6}>
          <CleeckyKit.TextInput
            name="lastName"
            label={t("cleeckykit:common.form.lastName.label")}
            placeholder={t("cleeckykit:common.form.lastName.placeholder")}
            borderRadius="1rem"
            fullWidth
            validationVariant="tooltip"
            validations={{
              required: true,
            }}
          />
        </CleeckyKit.FormItem>
      </CleeckyKit.FormGroup>

      <CleeckyKit.FormGroup>
        <CleeckyKit.FormItem span={12}>
          <CleeckyKit.TextInput
            name="username"
            label={t("cleeckykit:common.form.username.label")}
            placeholder={t("cleeckykit:common.form.username.placeholder")}
            borderRadius="1rem"
            fullWidth
            validationVariant="tooltip"
            prefix={<FontAwesomeIcon icon={faAt} />}
            validations={{
              required: true,
            }}
          />
        </CleeckyKit.FormItem>
      </CleeckyKit.FormGroup>

      <CleeckyKit.FormGroup>
        <CleeckyKit.FormItem span={12}>
          <CleeckyKit.EmailInput
            name="email"
            label={t("cleeckykit:common.form.email.label")}
            placeholder={t("cleeckykit:common.form.email.placeholder")}
            borderRadius="1rem"
            fullWidth
            validationVariant="tooltip"
            validations={{
              required: true,
            }}
          />
        </CleeckyKit.FormItem>
      </CleeckyKit.FormGroup>

      <CleeckyKit.FormGroup>
        <CleeckyKit.FormItem span={6}>
          <CleeckyKit.PasswordInput
            name="password"
            label={t("cleeckykit:common.form.password.label")}
            placeholder={t("cleeckykit:common.form.password.placeholder")}
            borderRadius="1rem"
            showToggleButton
            showStrengthMeter
            fullWidth
            validationVariant="tooltip"
            validations={{
              required: true,
            }}
          />
        </CleeckyKit.FormItem>

        <CleeckyKit.FormItem span={6}>
          <CleeckyKit.ConfirmPasswordInput
            name="confirmPassword"
            label={t("cleeckykit:common.form.confirmPassword.label")}
            placeholder={t(
              "cleeckykit:common.form.confirmPassword.placeholder"
            )}
            borderRadius="1rem"
            showToggleButton
            fullWidth
            validationVariant="tooltip"
            validations={{
              required: true,
            }}
          />
        </CleeckyKit.FormItem>
      </CleeckyKit.FormGroup>

      <CleeckyKit.FormGroup>
        <CleeckyKit.FormItem span={12}>
          <CleeckyKit.Button
            type="submit"
            text={t("cleeckykit:common.actions.register")}
            variant="solid"
            borderRadius={true}
            size="small"
            fullWidth
            highlighted
          />
        </CleeckyKit.FormItem>
      </CleeckyKit.FormGroup>

      <CleeckyKit.FormGroup>
        <CleeckyKit.FormItem span={12}>
          <CleeckyKit.Button
            type="button"
            text={t("cleeckykit:common.auth.modal.register.form.login")}
            variant="link"
            onClick={onToggleMode}
            fullWidth
          />
        </CleeckyKit.FormItem>
      </CleeckyKit.FormGroup>
    </CleeckyKit.Form>
  );
};
