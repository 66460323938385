import React from "react";
import * as CleeckyKit from "../../CleeckyKit";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { RouteDefinition } from "../../routes/routes";

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-style: italic;
`;

const BrandText = styled.span`
  font-weight: 800;
  font-size: 1.5rem;
`;

const Separator = styled.span`
  margin: 0 0.5rem;
  font-size: 1.5rem;
`;

const AppName = styled.span`
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.gradient?.highlightColor};
  }
`;

interface AppToolsModalProps {
  isOpen: boolean;
  onClose: () => void;
  app: RouteDefinition;
}

export const AppToolsModal: React.FC<AppToolsModalProps> = ({
  isOpen,
  onClose,
  app,
}) => {
  const navigate = useNavigate();

  const handleAppClick = () => {
    navigate(app.path);
    onClose();
  };

  const modalTitle = (
    <TitleContainer>
      <BrandText>Cleecky</BrandText>
      <Separator>/</Separator>
      <AppName onClick={handleAppClick}>{app.name}</AppName>
    </TitleContainer>
  );

  return (
    <CleeckyKit.Modal
      isOpen={isOpen}
      onClose={onClose}
      title={modalTitle}
      size="medium"
      animation="scale">
      <CleeckyKit.Grid gap="1rem" columns={{ sm: 1, md: 3 }} justify="between">
        {app.children?.map((tool) => (
          <CleeckyKit.Button
            key={tool.path}
            format="square"
            icon={<FontAwesomeIcon icon={tool.icon} />}
            text={tool.name}
            onClick={() => {
              navigate(tool.path);
              onClose();
            }}
            variant="solid"
            size="small"
            borderRadius="1rem"
            textPosition="outside-right"
            animation={{
              type: "scale",
              trigger: "hover",
            }}
          />
        ))}
      </CleeckyKit.Grid>
    </CleeckyKit.Modal>
  );
};
