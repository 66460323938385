import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styled, { useTheme } from "styled-components";
import * as CleeckyKit from "CleeckyKit";

interface StatsCardProps {
  icon: IconProp;
  title: string;
  value: string | number;
  color?: string;
}

const StatsCardContainer = styled.div<{ color?: string }>`
  background: ${({ theme }) => theme.colors.background.card};
  border-radius: 25px;
  padding: 1.5rem;
  box-shadow: ${({ theme }) => theme.shadows.card.default};
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.shadows.card.hover};
  }
`;

const IconContainer = styled.div<{ color?: string }>`
  background: ${({ color, theme }) =>
    (color || theme.colors.interactive.primary) + "20"};
  color: ${({ color, theme }) => color || theme.colors.interactive.primary};
  width: 48px;
  height: 48px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
`;

export const CleeckyKitStatsCard: React.FC<StatsCardProps> = ({
  icon,
  title,
  value,
  color,
}) => {
  const theme = useTheme();

  return (
    <StatsCardContainer color={color}>
      <CleeckyKit.Column gap="1rem">
        <IconContainer color={color}>
          <FontAwesomeIcon icon={icon} />
        </IconContainer>
        <CleeckyKit.Column gap="0.5rem">
          <CleeckyKit.Text
            align="left"
            customStyle={{ color: theme.colors.text.secondary }}>
            {title}
          </CleeckyKit.Text>
          <CleeckyKit.Title level={3}>{value}</CleeckyKit.Title>
        </CleeckyKit.Column>
      </CleeckyKit.Column>
    </StatsCardContainer>
  );
};
