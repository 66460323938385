import React from "react";
import { CleeckyKitTextHighlight } from "../components/Typography/CleeckyKitTextHighlight";

interface TagConfig {
  highlight?: boolean;
  color?: string;
  bold?: boolean;
  underline?: "below" | "behind-middle" | "behind-bottom";
  marker?: boolean;
  link?: string;
}

export const formatText = (text: string | React.ReactNode): React.ReactNode => {
  if (typeof text !== "string") {
    return text;
  }

  const parts: React.ReactNode[] = [];
  let currentText = "";
  const tagStack: { tag: string; value?: string; index: number }[] = [];
  let currentTags: TagConfig = {};

  const regex =
    /{(\/?(?:highlight|color|bold|underline|marker|link))(?:=([^}]+))?}/g;
  let lastIndex = 0;
  let match;

  const processCurrentText = (upToIndex: number) => {
    const textToProcess = text.slice(lastIndex, upToIndex);
    if (textToProcess) {
      // Aplica os estilos em camadas, começando do mais interno
      let styledText: React.ReactNode = textToProcess;

      // Se houver tags ativas, aplica os estilos em ordem
      if (Object.keys(currentTags).length > 0) {
        if (currentTags.link) {
          styledText = (
            <a
              href={currentTags.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}>
              <CleeckyKitTextHighlight
                key={parts.length}
                color={currentTags.color}
                mode={{
                  highlight: currentTags.highlight,
                  marker: currentTags.marker,
                  underline: currentTags.underline || "below",
                  bold: currentTags.bold,
                }}>
                {textToProcess}
              </CleeckyKitTextHighlight>
            </a>
          );
        } else {
          styledText = (
            <CleeckyKitTextHighlight
              key={parts.length}
              color={currentTags.color}
              mode={{
                highlight: currentTags.highlight,
                marker: currentTags.marker,
                underline: currentTags.underline,
                bold: currentTags.bold,
              }}>
              {textToProcess}
            </CleeckyKitTextHighlight>
          );
        }
      }

      parts.push(styledText);
    }
  };

  while ((match = regex.exec(text)) !== null) {
    const [fullMatch, tag, value] = match;
    const isClosing = tag.startsWith("/");
    const tagName = isClosing ? tag.slice(1) : tag;

    processCurrentText(match.index);
    lastIndex = regex.lastIndex;

    if (isClosing) {
      // Remove a tag mais recente que corresponde ao fechamento
      const openTagIndex = tagStack
        .slice()
        .reverse()
        .findIndex((item) => item.tag === tagName);

      if (openTagIndex !== -1) {
        const actualIndex = tagStack.length - 1 - openTagIndex;
        tagStack.splice(actualIndex);

        // Reconstrói o estado atual das tags mantendo a ordem
        currentTags = {};
        tagStack.forEach((item) => {
          switch (item.tag) {
            case "highlight":
              currentTags.highlight = true;
              break;
            case "color":
              currentTags.color = item.value;
              break;
            case "bold":
              currentTags.bold = true;
              break;
            case "underline":
              currentTags.underline = (item.value as any) || "below";
              break;
            case "marker":
              currentTags.marker = true;
              break;
            case "link":
              currentTags.link = item.value;
              break;
          }
        });
      }
    } else {
      // Adiciona nova tag ao stack
      tagStack.push({ tag: tagName, value, index: match.index });

      // Atualiza as tags atuais
      switch (tagName) {
        case "highlight":
          currentTags.highlight = true;
          break;
        case "color":
          currentTags.color = value;
          break;
        case "bold":
          currentTags.bold = true;
          break;
        case "underline":
          currentTags.underline = (value as any) || "below";
          break;
        case "marker":
          currentTags.marker = true;
          break;
        case "link":
          currentTags.link = value;
          break;
      }
    }
  }

  // Processa o texto restante
  processCurrentText(text.length);

  return parts;
};
