import { Theme } from "../CleeckyKit/types";
import { createCleeckyKitTheme } from "../CleeckyKit/themes";
import { defaultResponsiveConfig } from "../CleeckyKit/configs/CleeckyKitResponsiveConfig";

interface InterfaceThemes {
  [key: string]: Theme;
}

export const lightTheme = createCleeckyKitTheme({
  name: "light",
  type: "interface",
  responsive: defaultResponsiveConfig,
  colors: {
    background: {
      base: "#f7f4ee",
      content: "#F0F0F0",
      card: "#FFFFFF",
      navbar: {
        background: "#FFFFFF",
        text: "#2E2E2E",
        hover: "rgba(0, 0, 0, 0.05)",
        active: "#E8E8E8",
        border: "#E5E7EB",
      },
      searchBar: "#FBFBFB",
      appBox: "#FFFFFF",
      input: "#FFFFFF",
      modal: "#FFFFFF",
      overlay: "rgba(0, 0, 0, 0.5)",
      dropdown: "#FFFFFF",
    },
    text: {
      primary: "#2E2E2E",
      secondary: "#575757",
      onGradient: "#FFFFFF",
      placeholder: "#AFB7E0",
      error: "#DC2626",
      success: "#059669",
      onPrimary: "#FFFFFF",
    },
    icon: {
      primary: "#2E2E2E",
      secondary: "#575757",
      onGradient: "#AFB7E0",
    },
    border: {
      input: "#E5E7EB",
      error: "#DC2626",
      success: "#059669",
    },
    scroll: {
      track: "rgba(0, 0, 0, 0.1)",
      thumb: "#C0C0C0",
      line: "#E0E0E0",
    },
    interactive: {
      primary: "#2563EB",
      primaryHover: "#1D4ED8",
      hover: "rgba(0, 0, 0, 0.05)",
      clickable: "#D0D0D0",
      switcherOn: "#D0D0D0",
      switcherOff: "#F0F0F0",
      buttonOn: "#2E2E2E",
      buttonOff: "#2E2E2E",
      buttonDisabled: "#C0C0C0",
      active: "#E8E8E8",
      selected: "#D5D5D5",
    },
    status: {
      error: "#DC2626",
      success: "#059669",
      warning: "#D97706",
      info: "#2563EB",
    },
    selection: {
      background: "#D0D0D0",
      text: "#2E2E2E",
    },
    charts: {
      background: "#FFFFFF",
      text: "#2E2E2E",
      grid: {
        line: "#E5E7EB",
        text: "#575757",
      },
      tooltip: {
        background: "#FFFFFF",
        text: "#2E2E2E",
        border: "#E5E7EB",
      },
      bar: {
        default: "#4B5563",
        hover: "#6B7280",
        active: "#383838",
        average: "#383838",
      },
      pie: {
        default: "#9B6DFF",
        hover: "#B388FF",
        active: "#7C4DFF",
        background: "#E5E7EB",
      },
      line: {
        default: "#FF6B6B",
        hover: "#FF8787",
        active: "#FF4949",
      },
    },
  },
  shadows: {
    navbar: "0 2px 4px rgba(0, 0, 0, 0.1)",
    card: {
      default: "0 1px 3px rgba(0, 0, 0, 0.1)",
      hover: "0 4px 6px rgba(0, 0, 0, 0.1)",
      elevated: "0 8px 16px rgba(0, 0, 0, 0.1)",
    },
  },
});

export const darkTheme = createCleeckyKitTheme({
  name: "dark",
  type: "interface",
  responsive: defaultResponsiveConfig,
  colors: {
    background: {
      base: "#2E2E2E",
      content: "#393939",
      card: "#404040",
      navbar: {
        background: "#262626",
        text: "#F5F5F5",
        hover: "rgba(255, 255, 255, 0.1)",
        active: "#404040",
        border: "#4B5563",
      },
      searchBar: "#333333",
      appBox: "#333333",
      input: "#333333",
      modal: "#333333",
      overlay: "rgba(0, 0, 0, 0.7)",
      dropdown: "#333333",
    },
    text: {
      primary: "#F5F5F5",
      secondary: "#D4D4D4",
      onGradient: "#FFFFFF",
      placeholder: "#9CA3AF",
      error: "#EF4444",
      success: "#10B981",
      onPrimary: "#FFFFFF",
    },
    icon: {
      primary: "#D4D4D4",
      secondary: "#F5F5F5",
      onGradient: "#F5F5F5",
    },
    border: {
      input: "#4B5563",
      error: "#EF4444",
      success: "#10B981",
    },
    scroll: {
      track: "rgba(255, 255, 255, 0.1)",
      thumb: "#525252",
      line: "#404040",
    },
    interactive: {
      primary: "#3B82F6",
      primaryHover: "#2563EB",
      hover: "rgba(255, 255, 255, 0.1)",
      clickable: "#373737",
      switcherOn: "#404040",
      switcherOff: "#262626",
      buttonOn: "#F5F5F5",
      buttonOff: "#D4D4D4",
      buttonDisabled: "#525252",
      active: "#525252",
      selected: "#404040",
    },
    status: {
      error: "#EF4444",
      success: "#10B981",
      warning: "#F59E0B",
      info: "#3B82F6",
    },
    selection: {
      background: "#404040",
      text: "#F5F5F5",
    },
    charts: {
      background: "#404040",
      text: "#F5F5F5",
      grid: {
        line: "#4B5563",
        text: "#D4D4D4",
      },
      tooltip: {
        background: "#333333",
        text: "#F5F5F5",
        border: "#4B5563",
      },
      bar: {
        default: "#6B7280",
        hover: "#9CA3AF",
        active: "#B388FF",
        average: "#383838",
      },
      pie: {
        default: "#B388FF",
        hover: "#D1C4E9",
        active: "#9575CD",
        background: "#4B5563",
      },
      line: {
        default: "#FF8787",
        hover: "#FFA8A8",
        active: "#FF6B6B",
      },
    },
  },
  shadows: {
    navbar: "0 2px 4px rgba(0, 0, 0, 0.2)",
    card: {
      default: "0 1px 3px rgba(0, 0, 0, 0.1)",
      hover: "0 4px 6px rgba(0, 0, 0, 0.1)",
      elevated: "0 8px 16px rgba(0, 0, 0, 0.1)",
    },
  },
});

export const nocturnTheme = createCleeckyKitTheme({
  name: "nocturn",
  type: "interface",
  responsive: defaultResponsiveConfig,
  colors: {
    background: {
      base: "#1B1F2A",
      content: "#252A3A",
      card: "#2A2F42",
      navbar: {
        background: "#141A24",
        text: "#FFFFFF",
        hover: "rgba(255, 255, 255, 0.1)",
        active: "#2F3545",
        border: "#3B4162",
      },
      searchBar: "#1F4357",
      appBox: "#1F4357",
      input: "#1F4357",
      modal: "#252A3A",
      overlay: "rgba(0, 0, 0, 0.7)",
      dropdown: "#252A3A",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#E0E0FF",
      onGradient: "#FFFFFF",
      placeholder: "#AFB7E0",
      error: "#FF4444",
      success: "#10B981",
      onPrimary: "#FFFFFF",
    },
    icon: {
      primary: "#A3A3C2",
      secondary: "#E0E0FF",
      onGradient: "#F5F5F5",
    },
    border: {
      input: "#3B4162",
      error: "#FF4444",
      success: "#10B981",
    },
    scroll: {
      track: "rgba(255, 255, 255, 0.1)",
      thumb: "#3B4162",
      line: "#1B1F2A",
    },
    interactive: {
      primary: "#4B6BFB",
      primaryHover: "#3B5BEB",
      hover: "rgba(255, 255, 255, 0.1)",
      clickable: "#1F4357",
      switcherOn: "#3B4162",
      switcherOff: "#1B1F2A",
      buttonOn: "#E0E0FF",
      buttonOff: "#A3A3C2",
      buttonDisabled: "#252A3A",
      active: "#2F3545",
      selected: "#262B3A",
    },
    status: {
      error: "#FF4444",
      success: "#10B981",
      warning: "#F59E0B",
      info: "#4B6BFB",
    },
    selection: {
      background: "#3B4162",
      text: "#FFFFFF",
    },
    charts: {
      background: "#2A2F42",
      text: "#FFFFFF",
      grid: {
        line: "#3B4162",
        text: "#E0E0FF",
      },
      tooltip: {
        background: "#252A3A",
        text: "#FFFFFF",
        border: "#3B4162",
      },
      bar: {
        default: "#4B6BFB",
        hover: "#6B8BFF",
        active: "#B388FF",
        average: "#383838",
      },
      pie: {
        default: "#B388FF",
        hover: "#D1C4E9",
        active: "#9575CD",
        background: "#3B4162",
      },
      line: {
        default: "#FF8787",
        hover: "#FFA8A8",
        active: "#FF6B6B",
      },
    },
  },
  shadows: {
    navbar: "0 2px 8px rgba(0, 0, 0, 0.3)",
    card: {
      default: "0 1px 3px rgba(0, 0, 0, 0.1)",
      hover: "0 4px 6px rgba(0, 0, 0, 0.1)",
      elevated: "0 8px 16px rgba(0, 0, 0, 0.1)",
    },
  },
});

const themes: InterfaceThemes = {
  light: lightTheme,
  dark: darkTheme,
  nocturn: nocturnTheme,
};

export default themes;
