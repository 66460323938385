import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

type ImageFormat = "png" | "jpeg" | "webp" | "gif" | "svg";
type ImageSource = "public" | "external" | "file";

interface ImageProps {
  // Propriedades essenciais
  src: string;
  sourceType?: ImageSource;
  alt: string;
  format?: ImageFormat;

  // Dimensões
  width?: string | number;
  height?: string | number;
  aspectRatio?: number | string;

  // Estilo
  fit?: "cover" | "contain" | "fill" | "none" | "scale-down";
  position?: "center" | "top" | "bottom" | "left" | "right";
  borderRadius?: boolean | number | string;
  className?: string;
  fill?: string;
  stroke?: string;

  // Comportamento
  loading?: "lazy" | "eager";
  draggable?: boolean;
  fallbackSrc?: string;
  crossOrigin?: "anonymous" | "use-credentials";
  referrerPolicy?: React.HTMLAttributeReferrerPolicy;

  // Eventos do mouse
  onMouseDown?: (e: React.MouseEvent) => void;
  onMouseUp?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;

  // Animação
  animation?: {
    initial?: any;
    animate?: any;
    transition?: any;
  };

  // Link
  href?: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
}

const ImageContainer = styled(motion.div)<{
  $width?: string | number;
  $height?: string | number;
  $aspectRatio?: number | string;
}>`
  position: relative;
  width: ${({ $width }) =>
    typeof $width === "number" ? `${$width}px` : $width || "auto"};
  height: ${({ $height }) =>
    typeof $height === "number" ? `${$height}px` : $height || "auto"};
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || "auto"};
  overflow: hidden;
`;

const StyledImage = styled.img<{
  $fit?: ImageProps["fit"];
  $position?: ImageProps["position"];
  $borderRadius?: ImageProps["borderRadius"];
}>`
  width: 100%;
  height: 100%;
  object-fit: ${({ $fit }) => $fit || "cover"};
  object-position: ${({ $position }) => $position || "center"};
  border-radius: ${({ $borderRadius }) => {
    if (typeof $borderRadius === "boolean" && $borderRadius) {
      return "9999px";
    }
    if (typeof $borderRadius === "number") {
      return `${$borderRadius}px`;
    }
    if (typeof $borderRadius === "string") {
      return $borderRadius;
    }
    return "0";
  }};
`;

const StyledSVG = styled.div<{
  $fill?: string;
  $stroke?: string;
  $borderRadius?: ImageProps["borderRadius"];
}>`
  width: 100%;
  height: 100%;
  border-radius: ${({ $borderRadius }) => {
    if (typeof $borderRadius === "boolean" && $borderRadius) {
      return "9999px";
    }
    if (typeof $borderRadius === "number") {
      return `${$borderRadius}px`;
    }
    if (typeof $borderRadius === "string") {
      return $borderRadius;
    }
    return "0";
  }};

  svg {
    width: 100%;
    height: 100%;
    fill: ${({ $fill }) => $fill || "currentColor"};
    stroke: ${({ $stroke }) => $stroke || "none"};
  }
`;

const Fallback = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background.disabled};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.875rem;
`;

const processImagePath = (
  src: string,
  sourceType: ImageSource = "file"
): string => {
  switch (sourceType) {
    case "public":
      return `${process.env.PUBLIC_URL}/${
        src.startsWith("/") ? src.slice(1) : src
      }`;
    case "external":
      return src;
    case "file":
    default:
      return src;
  }
};

const ImageLink = styled.a`
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const CleeckyKitImage: React.FC<ImageProps> = ({
  src,
  sourceType = "file",
  alt,
  format,
  width,
  height,
  aspectRatio,
  fit = "cover",
  position = "center",
  borderRadius,
  className,
  loading = "lazy",
  draggable = false,
  fallbackSrc,
  crossOrigin = "anonymous",
  referrerPolicy = "no-referrer",
  animation,
  fill,
  stroke,
  href,
  target = "_self",
  onMouseDown,
  onMouseUp,
  onMouseLeave,
}) => {
  const [error, setError] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [svgContent, setSvgContent] = React.useState<string | null>(null);

  const processedSrc = processImagePath(src, sourceType);

  const handleError = () => {
    setError(true);
    setLoaded(false);
  };

  const handleLoad = () => {
    setLoaded(true);
    setError(false);
  };

  React.useEffect(() => {
    if (format === "svg") {
      fetch(processedSrc)
        .then((response) => response.text())
        .then((data) => {
          setSvgContent(data);
        })
        .catch(() => {
          handleError();
        });
    }
  }, [processedSrc, format]);

  const validateFormat = (url: string): boolean => {
    if (!format) return true;
    const extension = url.split(".").pop()?.toLowerCase();
    return extension === format;
  };

  if (!validateFormat(processedSrc)) {
    console.error(`Formato de imagem inválido. Esperado: ${format}`);
    return null;
  }

  const isExternalUrl = (url: string): boolean => {
    return (
      sourceType === "external" ||
      (sourceType === "file" && url.startsWith("http"))
    );
  };

  const renderImage = () => {
    if (error && fallbackSrc) {
      return (
        <StyledImage
          src={processImagePath(fallbackSrc, sourceType)}
          alt={alt}
          $fit={fit}
          $position={position}
          $borderRadius={borderRadius}
          loading={loading}
          draggable={draggable}
          onLoad={handleLoad}
          onError={handleError}
          crossOrigin={isExternalUrl(fallbackSrc) ? crossOrigin : undefined}
          referrerPolicy={
            isExternalUrl(fallbackSrc) ? referrerPolicy : undefined
          }
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseLeave}
        />
      );
    }

    if (error) {
      return <Fallback>Erro ao carregar imagem</Fallback>;
    }

    if (format === "svg" && svgContent) {
      return (
        <StyledSVG
          $fill={fill}
          $stroke={stroke}
          $borderRadius={borderRadius}
          dangerouslySetInnerHTML={{ __html: svgContent }}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseLeave}
        />
      );
    }

    return (
      <StyledImage
        src={processedSrc}
        alt={alt}
        $fit={fit}
        $position={position}
        $borderRadius={borderRadius}
        loading={loading}
        draggable={draggable}
        onLoad={handleLoad}
        onError={handleError}
        crossOrigin={isExternalUrl(src) ? crossOrigin : undefined}
        referrerPolicy={isExternalUrl(src) ? referrerPolicy : undefined}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
      />
    );
  };

  return (
    <ImageContainer
      $width={width}
      $height={height}
      $aspectRatio={aspectRatio}
      className={className}
      style={{ position: "relative" }}
      {...animation}>
      {renderImage()}
      {href && (
        <ImageLink
          href={href}
          target={target}
          onClick={(e) => {
            e.stopPropagation();
            if (target !== "_blank") {
              window.location.href = href;
            }
          }}
        />
      )}
    </ImageContainer>
  );
};
