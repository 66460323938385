import { ResolvedPageData } from "cleecky@types";
import api from "axiosConfig";
import { AggregatorPage } from "pages";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { LoadingScreen, MainLayout } from "components";

interface DynamicPageResolverProps {
  routeId: string;
  hideNavigation?: boolean;
}

const DynamicPageResolver: React.FC<DynamicPageResolverProps> = () => {
  const { username, slug } = useParams();
  const navigate = useNavigate();
  const [pageData, setPageData] = useState<ResolvedPageData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const resolvePage = async () => {
      if (!username || !slug) return;

      try {
        const cleanUsername = username.startsWith("@")
          ? username.slice(1)
          : username;

        const resolution = await api.get(
          `/shared/route-resolver/${cleanUsername}/${slug}`
        );

        if (resolution.data.type === "NOT_FOUND") {
          navigate("/404");
          return;
        }

        if (resolution.data.type === "RESERVED") {
          navigate(resolution.data.destination);
          return;
        }

        const response = await api.get(resolution.data.endpoint);
        setPageData({
          type: resolution.data.type,
          data: response.data,
        });
      } catch (error) {
        console.error("Erro ao resolver rota:", error);
        navigate("/404");
      } finally {
        setLoading(false);
      }
    };

    resolvePage();
  }, [username, slug, navigate]);

  if (loading) {
    return (
      <MainLayout hideNavigation>
        <LoadingScreen />
      </MainLayout>
    );
  }

  if (!pageData) {
    return null;
  }

  // Renderiza o AggregatorPage fora do MainLayout
  return (
    <MainLayout hideNavigation>
      <AggregatorPage data={pageData.data} />
    </MainLayout>
  );
};

export default DynamicPageResolver;
