import { useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { routes, RouteDefinition } from "../routes/routes";

export interface AuthRedirectState {
  selectedTool?: string;
  from?: string;
}

const getPublicRoutes = (routeDefinitions: RouteDefinition[]): string[] => {
  return routeDefinitions.reduce((acc: string[], route) => {
    if (route.isPublic) {
      acc.push(route.path);
    }
    if (route.children) {
      acc.push(...getPublicRoutes(route.children));
    }
    return acc;
  }, []);
};

const findParentRoute = (path: string): RouteDefinition | undefined => {
  const segments = path.split("/").filter(Boolean);
  if (segments.length < 2) return undefined;

  const parentPath = `/${segments[0]}`;
  return routes.find((route) => route.path === parentPath);
};

export const useAuthRedirect = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const publicRoutes = useMemo(() => getPublicRoutes(routes), []);

  useEffect(() => {
    const firstSegment = location.pathname.split("/")[1];
    if (firstSegment && firstSegment.startsWith("@")) {
      return;
    }

    if (!user && !publicRoutes.includes(location.pathname)) {
      const parentRoute = findParentRoute(location.pathname);
      const currentPath = location.pathname.split("/").pop() || "";

      if (parentRoute) {
        if (parentRoute.isPublic) {
          navigate(parentRoute.path, {
            replace: true,
            state: {
              selectedTool: currentPath,
              from: location.pathname,
            } as AuthRedirectState,
          });
        } else {
          navigate("/", { replace: true });
        }
      } else {
        navigate("/", { replace: true });
      }
    }
  }, [user, location.pathname, navigate, publicRoutes]);
};
