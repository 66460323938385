import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LogoSvg from "../../assets/images/cleecky-slogan-logo-black-minimalist-1.svg";
import * as CleeckyKit from "../../CleeckyKit/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

const NotFoundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background.base};
  z-index: 9999;
  gap: 2rem;
  padding: 1rem;
`;

const Logo = styled(CleeckyKit.Image)`
  width: min(200px, 80dvw);
  height: auto;
  filter: ${({ theme }) => (theme.name === "dark" ? "invert(1)" : "invert(0)")};
`;

const ContentColumn = styled(CleeckyKit.Column)`
  width: min(600px, 100%);
  gap: 2rem;
  text-align: center;
`;

export const NotFoundScreen: React.FC = () => {
  const { t } = useTranslation();

  return (
    <NotFoundContainer>
      <ContentColumn>
        <CleeckyKit.Row justify="center">
          <Logo
            src={LogoSvg}
            alt="Cleecky Logo"
            sourceType="file"
            format="svg"
          />
        </CleeckyKit.Row>

        <CleeckyKit.Row justify="center">
          <CleeckyKit.Title level={2} align="center">
            {t("project:pages.error_pages.404.title")}
          </CleeckyKit.Title>
        </CleeckyKit.Row>

        <CleeckyKit.Row justify="center" align="center">
          <CleeckyKit.Text align="center">
            {t("project:pages.error_pages.404.description")}
          </CleeckyKit.Text>
        </CleeckyKit.Row>

        <CleeckyKit.Row justify="center">
          <CleeckyKit.Button
            variant="solid"
            textPosition="inside-right"
            href="/"
            text={t("project:pages.error_pages.404.back_to_home")}
            icon={<FontAwesomeIcon icon={faHome} />}
          />
        </CleeckyKit.Row>
      </ContentColumn>
    </NotFoundContainer>
  );
};
