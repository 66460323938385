import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Z_INDEX } from "CleeckyKit/themes/zindex";
import { useCleeckyKitScrollDirection } from "CleeckyKit/hooks";

interface NavbarProps {
  children: React.ReactNode;
  isFixed?: boolean;
  transparent?: boolean;
  height?: string;
  shadow?: boolean;
  blur?: boolean;
  className?: string;
  isSidebarExpanded?: boolean;
  isMobileOpen?: boolean;
  showIconsWhenCollapsed?: boolean;
}

const NavbarContainer = styled(motion.nav)<{
  $isFixed?: boolean;
  $transparent?: boolean;
  $height?: string;
  $shadow?: boolean;
  $blur?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${({ $height }) => $height || "4rem"};
  padding: 0 1.5rem;
  background: ${({ theme, $transparent }) =>
    $transparent ? "transparent" : theme.colors.background.navbar.background};
  color: ${({ theme }) => theme.colors.background.navbar.text};
  position: ${({ $isFixed }) => ($isFixed ? "fixed" : "relative")};
  top: 0;
  left: 0;
  right: 0;
  z-index: ${Z_INDEX.navbar.container};
  backdrop-filter: ${({ $blur, $transparent }) =>
    !$transparent && $blur ? "blur(10px)" : "none"};
  box-shadow: ${({ theme, $shadow, $transparent }) =>
    !$transparent && $shadow ? theme.shadows.navbar : "none"};
  transition: transform 0.3s ease;

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.sm}) {
    padding: 0 1rem;
  }
`;

const NavbarContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: ${({ theme }) => theme.responsive.containerMaxWidths.xl};
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.xl}) {
    max-width: ${({ theme }) => theme.responsive.containerMaxWidths.lg};
  }

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.lg}) {
    max-width: ${({ theme }) => theme.responsive.containerMaxWidths.md};
  }

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    max-width: ${({ theme }) => theme.responsive.containerMaxWidths.sm};
  }

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.sm}) {
    max-width: ${({ theme }) => theme.responsive.containerMaxWidths.xs};
  }
`;

export const CleeckyKitNavbar: React.FC<NavbarProps> = ({
  children,
  isFixed = false,
  transparent = false,
  height,
  shadow = true,
  blur = true,
  className,
  isSidebarExpanded = false,
  isMobileOpen = false,
  showIconsWhenCollapsed = true,
}) => {
  const isVisible = useCleeckyKitScrollDirection(
    isSidebarExpanded,
    isMobileOpen,
    showIconsWhenCollapsed
  );

  return (
    <NavbarContainer
      $isFixed={isFixed}
      $transparent={transparent}
      $height={height}
      $shadow={shadow}
      $blur={blur}
      className={className}
      initial={false}
      animate={{
        transform: isVisible ? "translateY(0)" : "translateY(-100%)",
      }}
      transition={{ duration: 0.3 }}>
      <NavbarContent>{children}</NavbarContent>
    </NavbarContainer>
  );
};
