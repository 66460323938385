import React, { useState } from "react";
import styled from "styled-components";
import * as CleeckyKit from "CleeckyKit";
import { useAuth } from "../../contexts/AuthContext";
import * as yup from "yup";
import { AuthModal } from "components/Auth/AuthModal";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { NotificationSettingsModal } from "./NotificationSettingsModal";
import { cleeckysInsidersService } from "../../services/cleeckyInsidersService";
import { useCleeckyKitCurrentTheme } from "CleeckyKit";
import { t } from "i18next";

export const CleeckyInsidersWaitingList: React.FC = () => {
  const { user } = useAuth();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [alternativeEmail, setAlternativeEmail] = useState<string>();
  const [authMode, setAuthMode] = useState<"login" | "register">("login");
  const { currentGradient, currentTheme } = useCleeckyKitCurrentTheme();

  const handleAuthAction = (mode: "login" | "register") => {
    setAuthMode(mode);
    setIsAuthModalOpen(true);
  };

  const navigate = useNavigate();

  const handleAlternativeEmailSubmit = async (formData: {
    alternativeEmail: string;
  }) => {
    console.log("Form Data:", formData);
    setAlternativeEmail(formData.alternativeEmail);
    console.log("Alternative Email Set:", formData.alternativeEmail);
    setIsNotificationModalOpen(true);
  };

  const handleNotificationSettingsSubmit = async (settings: any) => {
    try {
      console.log("Submitting with email:", alternativeEmail);
      await cleeckysInsidersService.subscribe({
        ...settings,
        alternativeEmail,
        useCleeckyAccount: !alternativeEmail && !!user,
      });
      setIsSubscribed(true);
    } catch (error) {
      console.log("Error submitting:", error);
    }
  };

  if (isSubscribed || user?.tags?.includes("CLEECKYS_INSIDER")) {
    return (
      <>
        <CleeckyKit.Section
          background={currentGradient?.highlightColor}
          padding="5rem 0">
          <CleeckyKit.Column justify="center" align="center" gap="1rem">
            <CleeckyKit.Title level={2} align="center">
              {t("project:common.cleeckys_insiders.congratulations")}
            </CleeckyKit.Title>
            <CleeckyKit.Text align="center">
              {t(
                "project:common.cleeckys_insiders.congratulations_description"
              )}
            </CleeckyKit.Text>
            <CleeckyKit.Row justify="center" margin="1rem 0">
              <CleeckyKit.Button
                icon={<FontAwesomeIcon icon={faHistory} />}
                text={t("project:common.cleeckys_insiders.check_changelog")}
                variant="link"
                onClick={() => navigate("/changelog")}
                size="medium"
                textPosition="inside-right"
              />
            </CleeckyKit.Row>
          </CleeckyKit.Column>
        </CleeckyKit.Section>
      </>
    );
  }

  return (
    <>
      <CleeckyKit.Section
        background={currentGradient?.highlightColor}
        padding="5rem 0">
        <CleeckyKit.Column justify="center">
          <CleeckyKit.Row justify="center">
            <div
              style={{
                padding: "0 2rem",
                minWidth: "350px",
                maxWidth: "650px",
              }}>
              <CleeckyKit.Title level={2} align="center">
                {t("project:common.cleeckys_insiders.title")}
              </CleeckyKit.Title>
            </div>
          </CleeckyKit.Row>
          <CleeckyKit.Row margin="2rem 0" justify="center">
            <div
              style={{
                padding: "0 2rem",
                minWidth: "350px",
                maxWidth: "650px",
              }}>
              <CleeckyKit.Text align="center">
                {t("project:common.cleeckys_insiders.description")}
              </CleeckyKit.Text>
            </div>
          </CleeckyKit.Row>

          <CleeckyKit.Row justify="center">
            <CleeckyKit.Text align="center">
              {t("project:common.cleeckys_insiders.use_your_cleecky")}
            </CleeckyKit.Text>
          </CleeckyKit.Row>

          {!user ? (
            <CleeckyKit.Row justify="center" noBreak={true}>
              <CleeckyKit.Button
                text={t("cleeckykit:common.actions.register")}
                variant="outline"
                onClick={() => handleAuthAction("register")}
                size="medium"
                borderRadius="1rem"
              />
              <CleeckyKit.Button
                text={t("cleeckykit:common.actions.login")}
                variant="solid"
                onClick={() => handleAuthAction("login")}
                size="medium"
                borderRadius="1rem"
              />
            </CleeckyKit.Row>
          ) : (
            <CleeckyKit.Row justify="center">
              <CleeckyKit.Button
                text={t(
                  "project:common.cleeckys_insiders.continue_with_account",
                  {
                    username: user.username,
                  }
                )}
                variant="solid"
                onClick={() => setIsNotificationModalOpen(true)}
              />
            </CleeckyKit.Row>
          )}

          <CleeckyKit.Row justify="center">
            <CleeckyKit.Divider
              lineOrientation="horizontal"
              thickness="2px"
              textPosition="center"
              size="35rem">
              {t("project:common.cleeckys_insiders.or")}
            </CleeckyKit.Divider>
          </CleeckyKit.Row>

          <CleeckyKit.Row justify="center">
            <CleeckyKit.Text align="center">
              {t("project:common.cleeckys_insiders.use_specific_email")}
            </CleeckyKit.Text>
          </CleeckyKit.Row>

          <CleeckyKit.Row justify="center">
            <CleeckyKit.Form
              onSubmit={handleAlternativeEmailSubmit}
              resetOnSubmit={false}
              style={{ maxWidth: "500px", width: "100%" }}>
              <CleeckyKit.FormGroup gap="0.5rem">
                <CleeckyKit.FormItem span={8}>
                  <CleeckyKit.EmailInput
                    name="alternativeEmail"
                    label="none"
                    borderRadius="1rem"
                    validations={{
                      required: true,
                    }}
                  />
                </CleeckyKit.FormItem>
                <CleeckyKit.FormItem span={4}>
                  <CleeckyKit.Button
                    text={t("project:common.cleeckys_insiders.subscribe")}
                    variant="solid"
                    type="submit"
                    borderRadius="1rem"
                    size="small"
                  />
                </CleeckyKit.FormItem>
              </CleeckyKit.FormGroup>
            </CleeckyKit.Form>
          </CleeckyKit.Row>

          <CleeckyKit.Row justify="center" margin="1rem 0">
            <CleeckyKit.Button
              icon={<FontAwesomeIcon icon={faHistory} />}
              text={t("project:common.cleeckys_insiders.check_changelog")}
              variant="link"
              onClick={() => navigate("/changelog")}
              size="medium"
              textPosition="inside-right"
            />
          </CleeckyKit.Row>
        </CleeckyKit.Column>
      </CleeckyKit.Section>

      {isAuthModalOpen && (
        <AuthModal
          isOpen={isAuthModalOpen}
          onClose={() => setIsAuthModalOpen(false)}
          initialMode={authMode}
        />
      )}

      <NotificationSettingsModal
        isOpen={isNotificationModalOpen}
        onClose={() => setIsNotificationModalOpen(false)}
        onSubmit={handleNotificationSettingsSubmit}
        email={alternativeEmail || user?.email}
      />
    </>
  );
};
