import React from "react";
import styled from "styled-components";
import { useCleeckyKitCurrentTheme } from "CleeckyKit";

interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  label?: string;
  labelPosition?: "left" | "right";
}

const SwitchContainer = styled.label<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const SwitchLabel = styled.span`
  margin: 0 0.5rem;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const SwitchInput = styled.input`
  display: none;
`;

const SwitchSlider = styled.span<{
  size: "small" | "medium" | "large";
  checked: boolean;
  background: string;
}>`
  position: relative;
  display: inline-block;
  width: ${({ size }) =>
    ({
      small: "32px",
      medium: "40px",
      large: "48px",
    }[size])};
  height: ${({ size }) =>
    ({
      small: "16px",
      medium: "20px",
      large: "24px",
    }[size])};
  background-color: ${({ checked, background, theme }) =>
    checked ? background : theme.colors.border.default};
  border-radius: 34px;
  transition: 0.4s;

  &:before {
    content: "";
    position: absolute;
    height: ${({ size }) =>
      ({
        small: "12px",
        medium: "16px",
        large: "20px",
      }[size])};
    width: ${({ size }) =>
      ({
        small: "12px",
        medium: "16px",
        large: "20px",
      }[size])};
    left: ${({ size }) =>
      ({
        small: "2px",
        medium: "2px",
        large: "2px",
      }[size])};
    bottom: ${({ size }) =>
      ({
        small: "2px",
        medium: "2px",
        large: "2px",
      }[size])};
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
    transform: ${({ checked, size }) =>
      checked
        ? `translateX(${
            {
              small: "16px",
              medium: "20px",
              large: "24px",
            }[size]
          })`
        : "translateX(0)"};
  }
`;

export const CleeckyKitSwitch: React.FC<SwitchProps> = ({
  checked,
  onChange,
  disabled = false,
  size = "medium",
  label,
  labelPosition = "right",
}) => {
  const { currentGradient } = useCleeckyKitCurrentTheme();

  return (
    <SwitchContainer disabled={disabled}>
      {label && labelPosition === "left" && <SwitchLabel>{label}</SwitchLabel>}
      <SwitchInput
        type="checkbox"
        checked={checked}
        onChange={(e) => !disabled && onChange(e.target.checked)}
        disabled={disabled}
      />
      <SwitchSlider
        size={size}
        checked={checked}
        background={currentGradient?.highlightColor || "#007bff"}
      />
      {label && labelPosition === "right" && <SwitchLabel>{label}</SwitchLabel>}
    </SwitchContainer>
  );
};
