import { DefaultTheme } from "styled-components";
import { CleeckyKitGradientTheme } from "./CleeckyKitGradientTheme";
import {
  ResponsiveConfig,
  defaultResponsiveConfig,
} from "../configs/CleeckyKitResponsiveConfig";

export interface CleeckyKitTheme extends DefaultTheme {
  name: string;
  type: "interface";
  responsive: ResponsiveConfig;
  colors: {
    background: {
      base: string;
      content: string;
      card: string;
      navbar: {
        background: string;
        text: string;
        hover: string;
        active: string;
        border: string;
      };
      searchBar: string;
      appBox: string;
      input: string;
      modal: string;
      overlay: string;
      dropdown: string;
    };
    text: {
      primary: string;
      secondary: string;
      onGradient: string;
      placeholder: string;
      error: string;
      success: string;
      onPrimary: string;
    };
    icon: {
      primary: string;
      secondary: string;
      onGradient: string;
    };
    border: {
      input: string;
      error: string;
      success: string;
    };
    scroll: {
      track: string;
      thumb: string;
      line: string;
    };
    interactive: {
      primary: string;
      primaryHover: string;
      hover: string;
      clickable: string;
      switcherOn: string;
      switcherOff: string;
      buttonOn: string;
      buttonOff: string;
      buttonDisabled: string;
      active: string;
      selected: string;
    };
    status: {
      error: string;
      success: string;
      warning: string;
      info: string;
    };
    selection: {
      background: string;
      text: string;
    };
    charts: {
      background: string;
      text: string;
      grid: {
        line: string;
        text: string;
      };
      tooltip: {
        background: string;
        text: string;
        border: string;
      };
      bar: {
        default: string;
        hover: string;
        active: string;
        average: string;
      };
      pie: {
        default: string;
        hover: string;
        active: string;
        background: string;
      };
      line: {
        default: string;
        hover: string;
        active: string;
      };
    };
  };
  gradient?: CleeckyKitGradientTheme;
  shadows: {
    navbar: string;
    card: {
      default: string;
      hover: string;
      elevated: string;
    };
  };
}

export const createCleeckyKitTheme = (
  theme: Partial<CleeckyKitTheme>
): CleeckyKitTheme => {
  return {
    ...theme,
    responsive: {
      ...defaultResponsiveConfig,
      ...theme.responsive,
    },
  } as CleeckyKitTheme;
};
