import React from "react";
import styled from "styled-components";
import * as CleeckyKit from "CleeckyKit";
import IconMinimalist from "../../../../assets/images/icon-minimalist-black-1.svg";
import { useNavigate } from "react-router-dom";
import {
  AggregatorPageData,
  TextContent,
  ImageContent,
  LinkContent,
  SocialButtonsContent,
  GroupContent,
  SocialPlatform,
  SocialButton,
} from "cleecky@types";
import { MainLayout } from "components";
import mikePerfil from "../../../../assets/images/mike-perfil.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faGithub,
  faYoutube,
  faTiktok,
  faPinterest,
  faReddit,
  faSnapchat,
  faSpotify,
  faTumblr,
  faVimeo,
  faWhatsapp,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface AggregatorPageProps {
  data: AggregatorPageData;
}

const CenteredContainer = styled(CleeckyKit.Container)`
  min-height: 95dvh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const ContentItem = styled.div<{
  $background?: string;
  $border?: string;
  $borderRadius?: string;
}>`
  width: 100%;
  background: ${({ $background }) => $background || "transparent"};
  border: ${({ $border }) => $border || "none"};
  border-radius: ${({ $borderRadius }) => $borderRadius || "0"};
  padding: ${({ $border }) => ($border ? "1rem" : "0")};
`;

const SocialGrid = styled.div<{ $orientation?: "HORIZONTAL" | "VERTICAL" }>`
  display: grid;
  grid-template-columns: ${({ $orientation }) =>
    $orientation === "VERTICAL"
      ? "1fr"
      : "repeat(auto-fit, minmax(120px, 1fr))"};
  gap: 1rem;
  margin-top: 1rem;
`;

const AggregatorPage: React.FC<AggregatorPageProps> = ({ data }) => {
  const { contents, user } = data;
  const navigate = useNavigate();

  const getSocialIcon = (platform: SocialPlatform): IconDefinition => {
    switch (platform) {
      case "FACEBOOK":
        return faFacebook;
      case "INSTAGRAM":
        return faInstagram;
      case "LINKEDIN":
        return faLinkedin;
      case "GITHUB":
        return faGithub;
      case "YOUTUBE":
        return faYoutube;
      case "TIKTOK":
        return faTiktok;
      case "X":
        return faXTwitter;
      case "PINTEREST":
        return faPinterest;
      case "REDDIT":
        return faReddit;
      case "SNAPCHAT":
        return faSnapchat;
      case "SPOTIFY":
        return faSpotify;
      case "TUMBLR":
        return faTumblr;
      case "VIMEO":
        return faVimeo;
      case "WHATSAPP":
        return faWhatsapp;
      case "GMAIL":
        return faEnvelope;
      default:
        return faEnvelope;
    }
  };

  const renderContent = (content: AggregatorPageData["contents"][0]) => {
    if (!content.show) return null;

    switch (content.type) {
      case "TITLE":
      case "TEXT":
      case "NAME":
      case "USERNAME": {
        const textContent = content.data as TextContent;
        return (
          <ContentItem key={content.uuid}>
            <CleeckyKit.Text
              customStyle={{
                fontSize: textContent.size,
                color: textContent.color,
                textDecoration: textContent.decoration,
                textAlign: textContent.alignment?.toLowerCase() as any,
              }}>
              {textContent.text}
            </CleeckyKit.Text>
          </ContentItem>
        );
      }

      case "IMAGE":
      case "AVATAR": {
        const imageContent = content.data as ImageContent;
        return (
          <ContentItem
            key={content.uuid}
            $border={imageContent.border}
            $borderRadius={imageContent.borderRadius}
            $background="transparent">
            <CleeckyKit.Image
              src={imageContent.file.path}
              alt={imageContent.title || ""}
              width="100%"
              height="auto"
              borderRadius={imageContent.borderRadius}
              sourceType={
                imageContent.file.type.toLowerCase() as "external" | "file"
              }
            />
            {imageContent.title && (
              <CleeckyKit.Title level={4} align="center">
                {imageContent.title}
              </CleeckyKit.Title>
            )}
            {imageContent.description && (
              <CleeckyKit.Text align="center">
                {imageContent.description}
              </CleeckyKit.Text>
            )}
          </ContentItem>
        );
      }

      case "LINK": {
        const linkContent = content.data as LinkContent;
        return (
          <ContentItem
            key={content.uuid}
            $background={linkContent.background}
            $border={linkContent.border}
            $borderRadius={linkContent.borderRadius}>
            <CleeckyKit.Button
              text={linkContent.label}
              variant={linkContent.background ? "solid" : "outline"}
              onClick={() => window.open(linkContent.url, "_blank")}
              size="medium"
              textPosition="inside-right"
              borderRadius={linkContent.borderRadius}
              customStyle={{
                width: "100%",
                background: linkContent.background,
                borderColor: linkContent.border,
                color: linkContent.highlightColor,
              }}
            />
          </ContentItem>
        );
      }

      case "SOCIAL_BUTTONS": {
        const socialContent = content.data as SocialButtonsContent;
        return (
          <ContentItem
            key={content.uuid}
            $background={socialContent.background}
            $border={socialContent.border}
            $borderRadius={socialContent.borderRadius}>
            {socialContent.buttons.length > 0 && (
              <>
                <SocialGrid $orientation={socialContent.orientation}>
                  {socialContent.buttons.map((button: SocialButton) => (
                    <CleeckyKit.Button
                      key={`${content.uuid}-${button.platform}`}
                      text={button.title || button.platform}
                      variant={socialContent.background ? "solid" : "outline"}
                      onClick={() => window.open(button.url, "_blank")}
                      size="medium"
                      textPosition="inside-right"
                      borderRadius={socialContent.borderRadius}
                      icon={
                        <FontAwesomeIcon
                          icon={getSocialIcon(button.platform)}
                        />
                      }
                      customStyle={{
                        background: socialContent.background,
                        borderColor: socialContent.border,
                        color: socialContent.highlightColor,
                      }}
                    />
                  ))}
                </SocialGrid>
              </>
            )}
          </ContentItem>
        );
      }

      default:
        return null;
    }
  };

  return (
    <MainLayout hideNavigation>
      <CleeckyKit.Background
        color={data.pageBackgroundColor || "black"}
        size="fullscreen">
        <CenteredContainer>
          <CleeckyKit.Column>
            <CleeckyKit.Section margin="4rem 0 0 0">
              <CleeckyKit.Row justify="center" align="center">
                <CleeckyKit.Column>
                  <CleeckyKit.Row justify="center" align="center">
                    <CleeckyKit.Image
                      src={mikePerfil}
                      alt={user.firstName + " " + user.lastName}
                      width={200}
                      height={200}
                      borderRadius="50%"
                      format="jpeg"
                      aspectRatio={1}
                      sourceType="file"
                    />
                  </CleeckyKit.Row>
                  <CleeckyKit.Row justify="center" align="center">
                    <CleeckyKit.Title level={2}>
                      {user.firstName} {user.lastName}
                    </CleeckyKit.Title>
                  </CleeckyKit.Row>
                  <CleeckyKit.Row justify="center" align="center">
                    <CleeckyKit.SubTitle>@{user.username}</CleeckyKit.SubTitle>
                  </CleeckyKit.Row>
                </CleeckyKit.Column>
              </CleeckyKit.Row>
            </CleeckyKit.Section>

            <CleeckyKit.Section>
              <CleeckyKit.Row justify="center" align="center">
                <ContentList>
                  {contents
                    .filter((content) => content.show)
                    .sort((a, b) => a.order - b.order)
                    .map(renderContent)}
                </ContentList>
              </CleeckyKit.Row>
            </CleeckyKit.Section>

            <CleeckyKit.Section margin="4rem 0">
              <CleeckyKit.Row justify="center" align="center" noBreak>
                <CleeckyKit.Text>Powered by</CleeckyKit.Text>
                <CleeckyKit.Button
                  icon={
                    <CleeckyKit.Image
                      src={IconMinimalist}
                      alt="Cleecky Icon"
                      width={26}
                      height={26}
                      format="svg"
                      sourceType="file"
                    />
                  }
                  text="Cleecky.com"
                  variant="link"
                  onClick={() => navigate("/")}
                  size="small"
                  textPosition="inside-right"
                />
              </CleeckyKit.Row>
            </CleeckyKit.Section>
          </CleeckyKit.Column>
        </CenteredContainer>
      </CleeckyKit.Background>
    </MainLayout>
  );
};

export default AggregatorPage;
