import React, { useEffect } from "react";
import styled from "styled-components";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface FormProps {
  onSubmit: (data: any) => void;
  children: React.ReactNode;
  validationSchema?: yup.ObjectSchema<any>;
  defaultValues?: Record<string, any>;
  className?: string;
  resetOnSubmit?: boolean;
  gap?: string | number;
  style?: React.CSSProperties;
}

const StyledForm = styled.form<{ $gap?: string | number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ $gap }) =>
    typeof $gap === "number" ? `${$gap}px` : $gap || "1rem"};
  overflow: visible;
`;

export const CleeckyKitForm: React.FC<FormProps> = ({
  onSubmit,
  children,
  validationSchema,
  defaultValues = {},
  className,
  resetOnSubmit = false,
  gap,
  style,
}) => {
  const methods = useForm({
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    defaultValues,
    mode: "onChange",
  });

  useEffect(() => {
    const handleAutoFill = () => {
      const formElement = document.querySelector("form");
      if (formElement) {
        const inputs = formElement.querySelectorAll("input");
        inputs.forEach((input) => {
          if (input.value) {
            methods.setValue(input.name, input.value, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }
        });
      }
    };

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.target instanceof HTMLInputElement &&
          mutation.target.value
        ) {
          handleAutoFill();
        }
      });
    });

    const formElement = document.querySelector("form");
    if (formElement) {
      const inputs = formElement.querySelectorAll("input");
      inputs.forEach((input) => {
        observer.observe(input, {
          attributes: true,
          attributeFilter: ["value"],
        });
      });
    }

    return () => observer.disconnect();
  }, [methods]);

  const handleSubmit = methods.handleSubmit(async (data) => {
    try {
      await onSubmit(data);
      if (resetOnSubmit) {
        methods.reset();
      }
    } catch (error) {
      console.error("CleeckyKitForm - Erro ao processar submit:", error);
    }
  });

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formElement = e.currentTarget;
    const inputs = formElement.querySelectorAll("input");

    inputs.forEach((input) => {
      if (input.value) {
        methods.setValue(input.name, input.value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    });

    await new Promise((resolve) => setTimeout(resolve, 0));
    const isValid = await methods.trigger();

    if (isValid) {
      handleSubmit(e as any);
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      if (e.target instanceof HTMLTextAreaElement) {
        return;
      }

      e.preventDefault();
      handleFormSubmit(e as any);
    }
  };

  return (
    <FormProvider {...methods}>
      <StyledForm
        onSubmit={handleFormSubmit}
        onKeyDown={handleKeyDown}
        className={className}
        $gap={gap}
        style={style}
        autoComplete="on">
        {children}
      </StyledForm>
    </FormProvider>
  );
};
