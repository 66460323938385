import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  CleeckyKitBaseInput,
  BaseInputProps,
  InputValidations,
} from "./CleeckyKitBaseInput";
import { useTranslation } from "react-i18next";

type EmailInputProps = Omit<BaseInputProps, "type"> & {
  label?: string;
  placeholder?: string;
};

export const CleeckyKitEmailInput = React.forwardRef<
  HTMLInputElement,
  EmailInputProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">
>((props, ref) => {
  const { t } = useTranslation();
  const {
    label = t("cleeckykit:common.form.email.label"),
    placeholder = t("cleeckykit:common.form.email.placeholder"),
    validations,
    ...rest
  } = props;

  const defaultValidations: InputValidations = {
    required: true,
    requiredText: t("cleeckykit:common.form.email.required"),
    invalidText: t("cleeckykit:common.form.validations.invalidCredentials"),
    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  };

  return (
    <CleeckyKitBaseInput
      ref={ref}
      type="email"
      prefix={<FontAwesomeIcon icon={faEnvelope} />}
      label={label}
      placeholder={placeholder}
      validations={{ ...defaultValidations, ...validations }}
      {...rest}
    />
  );
});

CleeckyKitEmailInput.displayName = "CleeckyKitEmailInput";
