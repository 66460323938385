import React from "react";
import styled from "styled-components";
import { RowProps } from "../../../types";
import { css } from "styled-components";

type AlignType = "start" | "end" | "center" | "stretch" | "baseline";
type JustifyType = "start" | "end" | "center" | "between" | "around" | "evenly";

const StyledRow = styled.div<{
  $reverse?: boolean;
  $noGutters?: boolean;
  $align?: string;
  $justify?: string;
  $wrap?: boolean;
  $gap?: string | number;
  $padding?: string | number;
  $margin?: string | number;
  $width?: string;
  $height?: string;
  $noBreak?: boolean;
}>`
  display: flex;
  flex-direction: ${({ $reverse }) => ($reverse ? "row-reverse" : "row")};
  flex-wrap: ${({ $wrap }) => ($wrap ? "wrap" : "nowrap")};
  box-sizing: border-box;
  width: 100%;
  height: ${({ $height }) => $height || "fit-content"};
  min-height: fit-content;
  position: relative;

  ${({ $align }) => {
    const alignMap: Record<AlignType, string> = {
      start: "flex-start",
      end: "flex-end",
      center: "center",
      stretch: "stretch",
      baseline: "baseline",
    };
    return $align && `align-items: ${alignMap[$align as AlignType] || $align};`;
  }}

  ${({ $justify }) => {
    const justifyMap: Record<JustifyType, string> = {
      start: "flex-start",
      end: "flex-end",
      center: "center",
      between: "space-between",
      around: "space-around",
      evenly: "space-evenly",
    };
    return (
      $justify &&
      `justify-content: ${justifyMap[$justify as JustifyType] || $justify};`
    );
  }}

  ${({ $gap }) =>
    $gap && `gap: ${typeof $gap === "number" ? `${$gap}px` : $gap};`}
  ${({ $padding }) =>
    $padding &&
    `padding: ${typeof $padding === "number" ? `${$padding}px` : $padding};`}
  ${({ $margin }) =>
    $margin &&
    `margin: ${typeof $margin === "number" ? `${$margin}px` : $margin};`}
  ${({ $width }) => $width && `width: ${$width};`}
  ${({ $height }) => $height && `height: ${$height};`}
  
  /* Responsividade base */
  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    ${({ $noBreak }) =>
      !$noBreak &&
      css`
        flex-direction: column;
        align-items: stretch;
        text-align: center;

        & > * {
          width: 100% !important;
          margin-bottom: 1rem;
          display: flex;
          justify-content: center;
        }

        & > *:last-child {
          margin-bottom: 0;
        }
      `}
  }

  ${({ $noGutters }) =>
    !$noGutters &&
    `
    & > * {
      padding: 0.5rem;
    }
  `}
`;

export const CleeckyKitRow: React.FC<RowProps> = ({
  children,
  reverse,
  noGutters,
  align,
  justify,
  wrap,
  gap,
  padding = "0",
  margin = "0",
  width,
  height,
  className,
  noBreak,
}) => {
  return (
    <StyledRow
      $reverse={reverse}
      $noGutters={noGutters}
      $align={align}
      $justify={justify}
      $wrap={wrap}
      $gap={gap}
      $padding={padding}
      $margin={margin}
      $width={width}
      $height={height}
      $noBreak={noBreak}
      className={className}>
      {children}
    </StyledRow>
  );
};
