import { GradientTheme } from "CleeckyKit";

interface GradientThemes {
  [key: string]: GradientTheme;
}

export const greenNatureGradient: GradientTheme = {
  name: "greenNature",
  backgroundImage:
    "radial-gradient(at top left, #00FF7D, transparent), radial-gradient(at top right, #00F2FF, transparent), radial-gradient(at bottom right, #F2FF00, transparent), radial-gradient(at bottom left, #008FFF, transparent)",
  backgroundGradient:
    "linear-gradient(135deg, #00FF7D 0%, #00F2FF 50%, #F2FF00 100%)",
  highlightColor: "#00FF7D",
  secondaryHighlightColor: "#008FFF",
};

export const purpleMidnightGradient: GradientTheme = {
  name: "purpleMidnight",
  backgroundImage:
    "radial-gradient(at top left, #300F9A, transparent), radial-gradient(at top right, #B349C5, transparent), radial-gradient(at bottom right, #300F9A, transparent), radial-gradient(at bottom left, #590799, transparent)",
  backgroundGradient:
    "linear-gradient(135deg, #300F9A 0%, #B349C5 50%, #590799 100%)",
  highlightColor: "#B349C5",
  secondaryHighlightColor: "#590799",
};

export const deepOceanGradient: GradientTheme = {
  name: "deepOcean",
  backgroundImage:
    "radial-gradient(at top left, #0074FF, transparent), radial-gradient(at top right, #0E025A, transparent), radial-gradient(at bottom right, #300F9A, transparent), radial-gradient(at bottom left, #0D0242, transparent)",
  backgroundGradient:
    "linear-gradient(135deg, #0074FF 0%, #0E025A 50%, #0D0242 100%)",
  highlightColor: "#0074FF",
  secondaryHighlightColor: "#300F9A",
};

export const bloodWineGradient: GradientTheme = {
  name: "bloodWine",
  backgroundImage:
    "radial-gradient(at top left, #FC181C, transparent), radial-gradient(at top right, #74043B, transparent), radial-gradient(at bottom right, #130015, transparent), radial-gradient(at bottom left, #62001C, transparent)",
  backgroundGradient:
    "linear-gradient(135deg, #FC181C 0%, #74043B 50%, #130015 100%)",
  highlightColor: "#FC181C",
  secondaryHighlightColor: "#74043B",
};

export const sunnySundayGradient: GradientTheme = {
  name: "sunnySunday",
  backgroundImage:
    "radial-gradient(at top left, #0090FF, transparent), radial-gradient(at top right, #FEFBA4, transparent), radial-gradient(at bottom right, #00BCFF, transparent), radial-gradient(at bottom left, #81FDC7, transparent)",
  backgroundGradient:
    "linear-gradient(135deg, #0090FF 0%, #FEFBA4 50%, #00BCFF 100%)",
  highlightColor: "#FEFBA4",
  secondaryHighlightColor: "#00BCFF",
};

export const burningHorizonGradient: GradientTheme = {
  name: "burningHorizon",
  backgroundImage:
    "radial-gradient(at top left, #FB0686, transparent), radial-gradient(at top right, #FC6C04, transparent), radial-gradient(at bottom right, #FFCF7C, transparent), radial-gradient(at bottom left, #F3031C, transparent)",
  backgroundGradient:
    "linear-gradient(135deg, #FB0686 0%, #FC6C04 50%, #FFCF7C 100%)",
  highlightColor: "#FC6C04",
  secondaryHighlightColor: "#F3031C",
};

const gradients: GradientThemes = {
  greenNature: greenNatureGradient,
  purpleMidnight: purpleMidnightGradient,
  deepOcean: deepOceanGradient,
  bloodWine: bloodWineGradient,
  sunnySunday: sunnySundayGradient,
  burningHorizon: burningHorizonGradient,
};

export default gradients;
