import React, { useState, useRef } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence } from "framer-motion";
import { CleeckyKitBaseInput, BaseInputProps } from "../CleeckyKitBaseInput";
import { CleeckyKitCalendar } from "./CleeckyKitCalendar";
import { CleeckyKitButton } from "../../Button/CleeckyKitButton";
import { useCleeckyKitOutsideClick } from "CleeckyKit/hooks";
import dayjs from "dayjs";
import { Z_INDEX } from "CleeckyKit/themes/zindex";

interface DatePickerProps
  extends Omit<BaseInputProps, "type" | "value" | "onChange"> {
  value: Date | null;
  onChange?: (date: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  format?: string;
  placeholder?: string;
  clearable?: boolean;
}

const PopoverContainer = styled.div`
  position: relative;
`;

const Popover = styled.div`
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  z-index: ${Z_INDEX.buttons.pickers.popover};
`;

export const CleeckyKitDatePicker = React.forwardRef<
  HTMLInputElement,
  DatePickerProps
>(
  (
    {
      value = null,
      onChange,
      minDate,
      maxDate,
      format = "dd/MM/yyyy",
      placeholder = "Selecione uma data",
      clearable = true,
      ...rest
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useCleeckyKitOutsideClick(containerRef, () => setIsOpen(false));

    const formatDate = (date: Date | null): string => {
      if (!date) return "";
      return date.toLocaleDateString("pt-BR");
    };

    const handleInputClick = () => {
      setIsOpen(true);
    };

    const handleDateSelect = (date: dayjs.Dayjs) => {
      onChange?.(date.toDate());
      setIsOpen(false);
    };

    const handleClear = (e: React.MouseEvent) => {
      e.stopPropagation();
      onChange?.(null);
    };

    return (
      <PopoverContainer ref={containerRef}>
        <CleeckyKitBaseInput
          ref={ref}
          type="text"
          readOnly
          value={formatDate(value)}
          placeholder={placeholder}
          prefix={<FontAwesomeIcon icon={faCalendar} />}
          suffix={
            clearable && value ? (
              <CleeckyKitButton
                variant="icon"
                size="small"
                icon="×"
                onClick={handleClear}
                aria-label="Limpar data"
              />
            ) : undefined
          }
          onClick={handleInputClick}
          {...rest}
        />
        <AnimatePresence>
          {isOpen && (
            <Popover>
              <CleeckyKitCalendar
                selectedDate={value ? dayjs(value) : null}
                onChange={handleDateSelect}
                minDate={minDate ? dayjs(minDate) : undefined}
                maxDate={maxDate ? dayjs(maxDate) : undefined}
              />
            </Popover>
          )}
        </AnimatePresence>
      </PopoverContainer>
    );
  }
);

CleeckyKitDatePicker.displayName = "CleeckyKitDatePicker";
