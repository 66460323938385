import React, { useState } from "react";
import * as CleeckyKit from "../../CleeckyKit/components";
import { useAuth } from "../../contexts/AuthContext";
import { t } from "i18next";

interface SignInModalProps {
  onToggleMode: () => void;
  onClose?: () => void;
}

interface LoginFormData {
  identifier: string;
  password: string;
}

export const SignInModal: React.FC<SignInModalProps> = ({
  onToggleMode,
  onClose,
}) => {
  const { login } = useAuth();
  const [invalidCredentials, setInvalidCredentials] = useState(false);

  const handleSubmit = async (data: LoginFormData) => {
    try {
      setInvalidCredentials(false);
      await login(data.identifier, data.password);
      onClose?.();
    } catch (error) {
      console.error("SignInModal - Erro no login:", error);
      setInvalidCredentials(true);
    }
  };

  return (
    <CleeckyKit.Container>
      <CleeckyKit.Row>
        <CleeckyKit.Column justify="center">
          <CleeckyKit.Form
            onSubmit={(data) => {
              console.log("Form - Evento de submit capturado com dados:", data);
              handleSubmit(data);
            }}
            defaultValues={{
              identifier: "",
              password: "",
            }}
            resetOnSubmit={false}>
            <CleeckyKit.FormGroup>
              <CleeckyKit.FormItem span={12}>
                <CleeckyKit.IdentifierInput
                  name="identifier"
                  allowedTypes={["email", "username"]}
                  label={t("cleeckykit:common.form.identifier.label")}
                  placeholder={t(
                    "cleeckykit:common.form.identifier.placeholder"
                  )}
                  borderRadius="1rem"
                  fullWidth
                  validationVariant="tooltip"
                  validations={{
                    required: true,
                  }}
                />
              </CleeckyKit.FormItem>
            </CleeckyKit.FormGroup>

            <CleeckyKit.FormGroup>
              <CleeckyKit.FormItem span={12}>
                <CleeckyKit.PasswordInput
                  name="password"
                  label={t("cleeckykit:common.form.password.label")}
                  placeholder={t("cleeckykit:common.form.password.placeholder")}
                  borderRadius="1rem"
                  showToggleButton
                  fullWidth
                  validationVariant="tooltip"
                  error={
                    invalidCredentials
                      ? t(
                          "cleeckykit:common.form.validations.invalidCredentials"
                        )
                      : undefined
                  }
                  validations={{
                    required: true,
                    requiredText: t(
                      "cleeckykit:common.form.validations.required"
                    ),
                  }}
                />
              </CleeckyKit.FormItem>
            </CleeckyKit.FormGroup>

            <CleeckyKit.FormGroup>
              <CleeckyKit.FormItem span={12}>
                <CleeckyKit.Button
                  type="submit"
                  text={t("cleeckykit:common.actions.login")}
                  variant="solid"
                  borderRadius={true}
                  size="small"
                  fullWidth
                  highlighted
                />
              </CleeckyKit.FormItem>
            </CleeckyKit.FormGroup>

            <CleeckyKit.FormGroup>
              <CleeckyKit.FormItem span={12}>
                <CleeckyKit.Button
                  type="button"
                  text={t("cleeckykit:common.auth.modal.login.form.register")}
                  variant="link"
                  onClick={onToggleMode}
                  fullWidth
                />
              </CleeckyKit.FormItem>
            </CleeckyKit.FormGroup>
          </CleeckyKit.Form>
        </CleeckyKit.Column>
      </CleeckyKit.Row>
    </CleeckyKit.Container>
  );
};
