import React from "react";
import styled, { css } from "styled-components";
import { useCleeckyKitCurrentTheme } from "../../themes/themeUtilities/CleeckyKitThemeProvider";

interface CleeckyKitTextHighlightProps {
  children: React.ReactNode;
  color?: string;
  mode?: {
    highlight?: boolean;
    underline?: "below" | "behind-middle" | "behind-bottom";
    thick?: boolean;
    marker?: boolean;
    outline?: boolean;
    bold?: boolean;
    underlineConfig?: {
      thickness?: number;
      distance?: number;
    };
  };
}

interface StyledTextProps {
  $color: string;
  $mode: {
    highlight: boolean;
    underline: "below" | "behind-middle" | "behind-bottom" | undefined;
    thick: boolean;
    marker: boolean;
    outline: boolean;
    bold: boolean;
    underlineConfig: {
      thickness: number;
      distance: number;
    };
  };
}

const StyledText = styled.span<StyledTextProps>`
  position: relative;
  display: inline;
  font-size: inherit;
  font-weight: ${({ $mode }) => ($mode.bold ? "bold" : "inherit")};
  line-height: inherit;
  letter-spacing: inherit;

  /* Estilo para seleção de texto */
  &::selection {
    background-color: ${({ theme, $color }) =>
      $color ||
      theme.gradient?.highlightColor ||
      theme.colors.selection.background};
    color: ${({ theme }) => theme.colors.text.onGradient} !important;
    -webkit-text-fill-color: ${({ theme }) =>
      theme.colors.text.onGradient} !important;
  }

  /* Para navegadores Webkit (Chrome, Safari) */
  &::-webkit-selection {
    background-color: ${({ theme, $color }) =>
      $color ||
      theme.gradient?.highlightColor ||
      theme.colors.selection.background};
    color: ${({ theme }) => theme.colors.text.onGradient} !important;
    -webkit-text-fill-color: ${({ theme }) =>
      theme.colors.text.onGradient} !important;
  }

  /* Para Firefox */
  &::-moz-selection {
    background-color: ${({ theme, $color }) =>
      $color ||
      theme.gradient?.highlightColor ||
      theme.colors.selection.background};
    color: ${({ theme }) => theme.colors.text.onGradient} !important;
    -webkit-text-fill-color: ${({ theme }) =>
      theme.colors.text.onGradient} !important;
  }

  ${({ $color, $mode }) => css`
    /* Modo highlight - muda a cor do texto */
    ${$mode.highlight &&
    css`
      color: ${$color};
      -webkit-text-fill-color: ${$color}; /* Para funcionar com gradientes */
    `}

    /* Modo underline */
    ${$mode.underline &&
    css`
      ${(() => {
        switch ($mode.underline) {
          case "below":
            return css`
              box-decoration-break: clone;
              -webkit-box-decoration-break: clone;
              border-bottom: ${$mode.underlineConfig.thickness}px solid
                ${$color};
              padding-bottom: ${$mode.underlineConfig.distance}px;
              display: inline;
            `;

          case "behind-middle":
            return css`
              box-decoration-break: clone;
              -webkit-box-decoration-break: clone;
              background-image: linear-gradient(
                transparent 45%,
                ${$color}4D 45%,
                ${$color}4D 55%,
                transparent 55%
              );
              background-size: 100% 100%;
              padding: 0 2px;
              display: inline;
            `;

          case "behind-bottom":
            return css`
              box-decoration-break: clone;
              -webkit-box-decoration-break: clone;
              background-image: linear-gradient(
                transparent 60%,
                ${$color}4D 60%,
                ${$color}4D 85%,
                transparent 85%
              );
              background-size: 100% 100%;
              padding: 0 2px;
              display: inline;
            `;

          default:
            return css``;
        }
      })()}
    `}

    /* Modo marker - fundo marca-texto */
    ${$mode.marker &&
    css`
      background: ${$color}40;
      padding: 0 4px;
      border-radius: 3px;
    `}

    /* Modo outline - borda em volta dos caracteres */
    ${$mode.outline &&
    css`
      -webkit-text-stroke: ${$mode.thick ? "1.5px" : "1px"} ${$color};
      text-stroke: ${$mode.thick ? "1.5px" : "1px"} ${$color};
    `}

    /* Modo bold */
    ${$mode.bold &&
    css`
      font-weight: 700;
    `}
  `}
`;

export const CleeckyKitTextHighlight: React.FC<
  CleeckyKitTextHighlightProps
> = ({ children, color, mode = {} }) => {
  const { currentTheme, currentGradient } = useCleeckyKitCurrentTheme();

  const defaultMode = {
    highlight: mode.highlight ?? false,
    underline: mode.underline,
    thick: mode.thick ?? false,
    marker: mode.marker ?? false,
    outline: mode.outline ?? false,
    bold: mode.bold ?? false,
    underlineConfig: {
      thickness: mode.underlineConfig?.thickness ?? 2,
      distance: mode.underlineConfig?.distance ?? 2,
    },
  };

  const highlightColor =
    color ||
    currentGradient?.highlightColor ||
    currentTheme.colors.interactive.clickable;

  return (
    <StyledText $color={highlightColor} $mode={defaultMode}>
      {children}
    </StyledText>
  );
};
