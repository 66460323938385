import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import * as CleeckyKit from "CleeckyKit";
import { useCleeckyKitCurrentTheme } from "../../themes/themeUtilities/CleeckyKitThemeProvider";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignLeft,
  faDesktop,
  faUserCircle,
  faImage,
  faHeading,
  faFont,
  faMobileAlt,
  faLink,
  faUser,
  faEllipsisV,
  faChevronLeft,
  faChevronRight,
  faPen,
  faLock,
  faLayerGroup,
  faShareNodes,
  faTrash,
  faEnvelope,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebook,
  faYoutube,
  faTiktok,
  faLinkedin,
  faTwitter,
  faPinterest,
  faReddit,
  faSnapchat,
  faSpotify,
  faTumblr,
  faVimeo,
  faWhatsapp,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { ReactComponent as MinimalistIcon } from "../../assets/images/icon-minimalist-black-1.svg";
import { CleeckyKitContainer } from "../Layout/Container/CleeckyKitContainer";
import * as Types from "../../types";
import { motion } from "framer-motion";

export type CanvasElementType =
  | "TITLE"
  | "TEXT"
  | "IMAGE"
  | "AVATAR"
  | "LINK"
  | "SOCIAL_BUTTONS"
  | "NAME"
  | "USERNAME"
  | "GROUP";

export interface CanvasElement {
  id: string;
  type: CanvasElementType;
  content: Types.ElementContent;
  order: number;
  show: boolean;
  sectionId?: string;
}

interface CanvasSection {
  id: string;
  title: string;
  elements: string[];
}

interface GlobalColors {
  highlightColor: string;
  pageBackgroundColor: string;
  buttonsBackgroundColor: string;
  buttonsLabelColor: string;
  textColor: string;
  borderColor: string;
}

interface CleeckyKitCanvasProps {
  elements: CanvasElement[];
  sections?: CanvasSection[];
  onElementsChange: (elements: CanvasElement[]) => void;
  onSectionsChange?: (sections: CanvasSection[]) => void;
  className?: string;
  globalColors?: GlobalColors;
  onGlobalColorsChange?: (colors: GlobalColors) => void;
}

interface ToolbarContentProps {
  $hasScrollLeft: boolean;
  $hasScrollRight: boolean;
}

const CanvasOuterWrapper = styled.div`
  position: relative;
`;

const CanvasContainer = styled(CleeckyKitContainer)`
  min-height: 200px;
  padding: 16px 16px 0;
  background: ${({ theme }) => theme.colors.background.base};
  border-radius: 8px;
  border: 0.3rem solid ${({ theme }) => theme.colors.background.card};
  box-shadow: ${({ theme }) => theme.shadows.card.default};
  position: relative;
  height: 600px;
`;

const DropZone = styled.div<{ $isDragging?: boolean }>`
  width: 100%;
  height: calc(100% - 80px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
  touch-action: ${({ $isDragging }) => ($isDragging ? "none" : "auto")};
  -webkit-overflow-scrolling: ${({ $isDragging }) =>
    $isDragging ? "none" : "touch"};
  scroll-behavior: smooth;
  background-color: ${({ theme }) => theme.colors.background.navbar.background};

  /* Estilização da scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background.card};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.border.input};
    border-radius: 4px;

    &:hover {
      background: ${({ theme }) => theme.colors.text.secondary};
    }
  }
`;

const ContentWrapper = styled.div`
  padding: 16px 16px 26px 8px;
  min-height: 100%;
`;

const DragHandle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  padding: 4px;
  touch-action: none;

  &:active {
    cursor: grabbing;
  }
`;

const DraggableElement = styled.div<{
  $isDragging: boolean;
  $isSelected: boolean;
}>`
  padding: 12px 16px;
  margin: 8px 0;
  background: ${({ theme, $isDragging }) =>
    $isDragging
      ? theme.gradient?.highlightColor || theme.colors.interactive.primary
      : theme.colors.background.card};
  border-radius: 8px;
  cursor: default;
  opacity: ${({ $isDragging }) => ($isDragging ? 0.5 : 1)};
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.colors.text.primary};
  position: relative;
  border: 2px solid
    ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.gradient?.highlightColor || theme.colors.interactive.primary
        : "transparent"};

  &:hover {
    background: ${({ theme }) => theme.colors.background.hover};
  }

  .drag-indicator {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: 16px;
  }

  .element-icon {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.colors.text.secondary};
  }

  .element-actions {
    display: flex;
    gap: 8px;
    opacity: 0;
    visibility: hidden;
    margin-left: auto;
    transition: all 0.2s ease;

    ${({ $isSelected }) =>
      $isSelected &&
      `
      opacity: 1;
      visibility: visible;
    `}
  }

  .action-button {
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.text.secondary};
    transition: all 0.2s ease;

    &:hover {
      color: ${({ theme }) =>
        theme.gradient?.highlightColor || theme.colors.interactive.primary};
      background: ${({ theme }) => theme.colors.background.hover};
    }

    &.delete:hover {
      color: ${({ theme }) => theme.colors.status.error};
    }
  }
`;

const SectionWrapper = styled.div`
  margin: 16px 0;
  padding: 16px;
  background: ${({ theme }) => theme.colors.background.card};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
`;

const SectionTitle = styled.h3`
  margin: 0 0 16px 0;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1rem;
  font-weight: 500;
`;

const ElementContentWrapper = styled.div<{ $isSelected: boolean }>`
  width: ${({ $isSelected }) => ($isSelected ? "calc(100% - 80px)" : "100%")};
  transition: width 0.2s ease;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background.input};
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 0.875rem;

  &:focus {
    outline: none;
    background: ${({ theme }) => theme.colors.background.hover};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.text.placeholder};
  }
`;

const ToolbarWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.background.card};
  padding: 16px;
  border-radius: 1rem;
  margin: 0 16px 16px;
  width: calc(100% - 32px);
  box-shadow: ${({ theme }) => theme.shadows.card.elevated};
`;

const ToolbarContent = styled.div<ToolbarContentProps>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ElementsContainer = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: hidden;
  padding: 4px;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  flex: 1;
`;

const ToolbarNavigationButton = styled.button<{ $direction: "left" | "right" }>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background.input};
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;
  opacity: 0.8;
  transition: all 0.2s ease;

  &:hover {
    opacity: 1;
    background: ${({ theme }) => theme.colors.background.hover};
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  svg {
    width: 14px;
    height: 14px;
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const ToolbarElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  cursor: grab;
  background: ${({ theme }) => theme.colors.background.input};
  border-radius: 4px;
  user-select: none;
  touch-action: none;
  white-space: nowrap;
  flex-shrink: 0;

  &:hover {
    background: ${({ theme }) => theme.colors.background.hover};
  }

  &:active {
    background: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.interactive.primary};
    cursor: grabbing;
  }

  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.colors.text.primary};
  }

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const EditorArea = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  min-width: 0;
`;

//Preview Card
const PreviewWrapper = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const PreviewArea = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.background.card};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.card.default};
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const PreviewHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
  display: flex;
  gap: 8px;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background.card};
`;

const BrowserBar = styled.div`
  background: ${({ theme }) => theme.colors.background.card};
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BrowserIcon = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
    fill: ${({ theme }) => `${theme.colors.text.primary}40`};
  }
`;

const BrowserDots = styled.div`
  display: flex;
  gap: 6px;
  margin-right: 8px;

  span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.text.secondary};
    opacity: 0.5;

    &:nth-child(1) {
      background: #ff5f57;
    }
    &:nth-child(2) {
      background: #ffbd2e;
    }
    &:nth-child(3) {
      background: #28c940;
    }
  }
`;

const BrowserAddressBar = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.colors.background.input};
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.secondary};
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    width: 12px;
    height: 12px;
    opacity: 0.5;
  }
`;

//Preview Window
const PreviewContent = styled.div<{ $isMobile: boolean }>`
  position: relative;
  width: ${({ $isMobile }) =>
    $isMobile ? "min(375px, 90%)" : "min(960px, 90%)"};
  aspect-ratio: ${({ $isMobile }) => ($isMobile ? "9/16" : "16/9")};
  height: ${({ $isMobile }) => ($isMobile ? "70dvh" : "50dvh")};
  margin: auto;
  background: ${({ theme }) => theme.colors.background.base};
  transition: all 0.3s ease;
  border-radius: 1rem;
  font-size: clamp(0.75rem, 1.5dvw, 0.9rem);
  box-shadow: ${({ theme }) => theme.shadows.card.elevated};
  border: 2px solid ${({ theme }) => theme.colors.background.navbar.background};
  display: flex;
  flex-direction: column;

  .preview-content-inner {
    flex: 1;
    padding: 0.8rem;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
      padding: 0.6rem;
    }
  }

  // Preview content principal column
  .preview-content-wrapper {
    width: 300px;
    //max-width: ${({ $isMobile }) => ($isMobile ? "90%" : "70%")};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
      width: ${({ $isMobile }) => ($isMobile ? "95%" : "200px")};
    }
  }

  @media (max-width: 768px) {
    width: ${({ $isMobile }) =>
      $isMobile ? "min(375px, 90%)" : "min(90%, 90dvh * 16/9)"};
    height: ${({ $isMobile }) => ($isMobile ? "min(667px, 90dvh)" : "auto")};
    max-height: ${({ $isMobile }) =>
      $isMobile ? "90dvh" : "min(90dvw * 9/16, 90dvh)"};
  }

  /* Estilização da scrollbar */
  .preview-content-inner::-webkit-scrollbar {
    width: 0.4rem;
  }

  .preview-content-inner::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background.card};
    border-radius: 0.2rem;
  }

  .preview-content-inner::-webkit-scrollbar-thumb {
    background: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.border.input};
    border-radius: 0.2rem;

    &:hover {
      background: ${({ theme }) => theme.colors.text.secondary};
    }
  }
`;

const PreviewButton = styled.button<{ $isActive: boolean }>`
  padding: 8px;
  border: 1px solid
    ${({ theme, $isActive }) =>
      $isActive
        ? theme.gradient?.highlightColor || theme.colors.interactive.primary
        : theme.colors.border.input};
  background: ${({ theme, $isActive }) =>
    $isActive
      ? theme.gradient?.highlightColor || theme.colors.interactive.primary
      : "transparent"};
  color: ${({ theme }) => theme.colors.text.primary};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) =>
      `${
        theme.gradient?.highlightColor || theme.colors.interactive.primary
      }40`};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

//Elements
const PreviewElement = styled.div`
  padding: 0.5rem;
  margin: 0.3rem 0;
  background: ${({ theme }) => theme.colors.background.card};
  font-size: 0.85em;
  width: 100%;

  img {
    max-width: 2.5rem;
    max-height: 2.5rem;
  }

  /* Ajusta proporcionalmente o tamanho dos elementos dentro do preview usando classes */
  .preview-title {
    font-size: 0.85em;
  }

  .preview-text {
    font-size: 0.85em;
  }

  .preview-button {
    font-size: 0.85em;
    padding: 0.5rem 1rem;
  }

  .preview-image {
    max-width: 80%;
    height: auto;
  }

  .preview-grid {
    max-width: 80%;
    margin: 0 auto;
  }
`;

const PreviewSectionWrapper = styled.div`
  margin: 0.8rem 0;
  padding: 0.8rem;
  background: ${({ theme }) => theme.colors.background.card};
  border-radius: 0.4rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
`;

const PreviewSectionTitle = styled.h3`
  margin: 0 0 0.6rem 0;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 0.9rem;
  font-weight: 500;
`;

const DropRegion = styled.div<{ $isActive: boolean }>`
  height: 72px;
  margin: 8px 0;
  background: ${({ theme }) =>
    `${theme.gradient?.highlightColor || theme.colors.interactive.primary}40`};
  border: 2px dashed
    ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.border.input};
  border-radius: 8px;
  opacity: ${({ $isActive }) => ($isActive ? 0.5 : 0)};
  transform: scale(${({ $isActive }) => ($isActive ? 1 : 0.95)});
  transition: all 0.2s ease;
  pointer-events: ${({ $isActive }) => ($isActive ? "auto" : "none")};
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const DropRegionIcon = styled.div`
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.colors.background.hover};
  border-radius: 4px;
`;

const DropRegionContent = styled.div`
  flex: 1;
  height: 32px;
  background: ${({ theme }) => theme.colors.background.hover};
  border-radius: 4px;
`;

const ElementWrapper = styled.div`
  position: relative;
  width: 100%;
  touch-action: pan-y;
  user-select: none;

  &:hover {
    .element-actions {
      opacity: 1;
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 20px;
    pointer-events: none;
  }

  &::before {
    top: -10px;
  }

  &::after {
    bottom: -10px;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: ${({ theme }) => theme.colors.background.base};
  padding: 24px;
  border-radius: 8px;
  min-width: 400px;
  max-width: 90%;
  max-height: 90dvh;
  overflow-y: auto;
  box-shadow: ${({ theme }) => theme.shadows.card.default};
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const ModalTitle = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1.25rem;
`;

const ModalBody = styled.div`
  margin-bottom: 24px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background.input};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 16px;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.interactive.primary};
  }
`;

const ImageSkeleton = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  background: ${({ theme }) => theme.colors.background.card};
  border-radius: 0.5rem;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent 0%,
      ${({ theme }) => `${theme.colors.background.hover}40`} 50%,
      transparent 100%
    );
    animation: shimmer 1.5s infinite;
  }

  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

const defaultGlobalColors: GlobalColors = {
  highlightColor: "#3498db",
  pageBackgroundColor: "#ffffff",
  buttonsBackgroundColor: "#3498db",
  buttonsLabelColor: "#ffffff",
  textColor: "#000000",
  borderColor: "#e0e0e0",
};

const ColorPickerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
  width: 100%;
`;

const ColorPickerButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 4px;
  padding: 2px;
  background: transparent;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    border-color: ${({ theme }) => theme.colors.interactive.primary};
  }
`;

const ColorInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const ColorPreview = styled.div<{ $color: string }>`
  width: 100%;
  height: 100%;
  background: ${({ $color }) => $color};
  border-radius: 2px;
`;

const ColorPickerLabel = styled.label`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 0.875rem;
  flex: 1;
`;

const CollapseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  color: ${({ theme }) => theme.colors.text.primary};

  &:hover {
    color: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.interactive.primary};
  }

  svg {
    transition: transform 0.3s ease;
  }
`;

const GlobalSettingsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const GlobalSettingsContent = styled(motion.div)`
  overflow: hidden;
  width: 100%;
`;

export const CleeckyKitCanvas: React.FC<CleeckyKitCanvasProps> = ({
  elements,
  sections = [],
  onElementsChange,
  onSectionsChange,
  className,
  globalColors: initialGlobalColors,
  onGlobalColorsChange = () => {},
}) => {
  const { currentTheme } = useCleeckyKitCurrentTheme();
  const [internalGlobalColors, setInternalGlobalColors] =
    useState<GlobalColors>(initialGlobalColors || defaultGlobalColors);

  // Atualiza as cores internas quando as cores iniciais mudarem
  useEffect(() => {
    if (initialGlobalColors) {
      setInternalGlobalColors(initialGlobalColors);
    }
  }, [initialGlobalColors]);

  // Função para atualizar as cores globais
  const handleGlobalColorsChange = (newColors: GlobalColors) => {
    setInternalGlobalColors(newColors);
    onGlobalColorsChange(newColors);
  };

  // Função auxiliar para obter a cor efetiva de um elemento
  const getEffectiveColor = (
    elementColor: string | undefined,
    globalColorKey: keyof GlobalColors
  ) => {
    return elementColor || internalGlobalColors[globalColorKey];
  };

  const dropZoneRef = React.useRef<HTMLDivElement>(null);
  const autoScrollIntervalRef = React.useRef<number | null>(null);
  const [draggedId, setDraggedId] = useState<string | null>(null);
  const [dropTargetId, setDropTargetId] = useState<string | null>(null);
  const [dropPosition, setDropPosition] = useState<"before" | "after" | null>(
    null
  );
  const [previewMode, setPreviewMode] = useState<"desktop" | "mobile">(
    "desktop"
  );
  const { t } = useTranslation();
  const [isDragging, setIsDragging] = useState(false);
  const [touchStartY, setTouchStartY] = useState<number | null>(null);
  const [touchedElement, setTouchedElement] = useState<string | null>(null);
  const [touchedElementType, setTouchedElementType] =
    useState<CanvasElementType | null>(null);

  const toolbarRef = useRef<HTMLDivElement>(null);
  const [hasScrollLeft, setHasScrollLeft] = useState(false);
  const [hasScrollRight, setHasScrollRight] = useState(false);

  const [editingElement, setEditingElement] = useState<CanvasElement | null>(
    null
  );
  const [editingTitle, setEditingTitle] = useState<{
    text: string;
    size: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  }>({ text: "", size: "h1" });

  const [selectedElementId, setSelectedElementId] = useState<string | null>(
    null
  );

  const [isGlobalSettingsExpanded, setIsGlobalSettingsExpanded] =
    useState(true);

  const checkToolbarScroll = useCallback(() => {
    if (toolbarRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = toolbarRef.current;
      setHasScrollLeft(scrollLeft > 0);
      setHasScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  }, []);

  useEffect(() => {
    checkToolbarScroll();
    window.addEventListener("resize", checkToolbarScroll);
    return () => {
      window.removeEventListener("resize", checkToolbarScroll);
    };
  }, [checkToolbarScroll]);

  const clearAutoScroll = () => {
    if (autoScrollIntervalRef.current !== null) {
      window.clearInterval(autoScrollIntervalRef.current);
      autoScrollIntervalRef.current = null;
    }
  };

  const startAutoScroll = (speed: number) => {
    if (!dropZoneRef.current) return;

    clearAutoScroll();

    autoScrollIntervalRef.current = window.setInterval(() => {
      if (dropZoneRef.current) {
        dropZoneRef.current.scrollTop += speed;
      }
    }, 16);
  };

  const disableScroll = () => {
    document.body.style.touchAction = "none";
  };

  const enableScroll = () => {
    document.body.style.touchAction = "auto";
  };

  const handleTouchStart = (
    event: React.TouchEvent<HTMLDivElement>,
    elementId?: string,
    elementType?: CanvasElementType
  ) => {
    const isDragHandle = (event.target as HTMLElement).closest(
      ".drag-indicator"
    );

    if (!isDragHandle) {
      return;
    }

    document.body.style.overflow = "hidden";
    document.body.style.touchAction = "none";
    setIsDragging(true);
    setTouchStartY(event.touches[0].clientY);

    if (elementId) {
      setTouchedElement(elementId);
    }
    if (elementType) {
      setTouchedElementType(elementType);
    }
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!touchStartY || !dropZoneRef.current || !isDragging) return;

    const currentY = event.touches[0].clientY;
    const deltaY = currentY - touchStartY;
    const element = event.currentTarget as HTMLElement;

    if (element) {
      element.style.transform = `translateY(${deltaY}px)`;
      element.style.opacity = "0.5";
      element.style.visibility = "hidden";
    }

    const targetElement = document.elementFromPoint(
      event.touches[0].clientX,
      event.touches[0].clientY
    ) as HTMLElement | null;

    if (element) {
      element.style.visibility = "visible";
    }

    const updateDropTarget = (
      targetElement: HTMLElement,
      position: "before" | "after"
    ) => {
      const elementId = targetElement.getAttribute("data-element-id");
      if (elementId && elementId !== touchedElement) {
        setDropTargetId(elementId);
        setDropPosition(position);
      }
    };

    const findClosestElement = (): HTMLElement | null => {
      const elements = Array.from(
        document.querySelectorAll<HTMLElement>("[data-element-id]")
      );
      let closestElement: HTMLElement | null = null;
      let minDistance = Infinity;

      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        const distance = Math.abs(currentY - (rect.top + rect.height / 2));

        if (distance < minDistance) {
          minDistance = distance;
          closestElement = el;
        }
      });

      return closestElement;
    };

    if (targetElement) {
      const closestWrapper = targetElement.closest("[data-element-id]");
      const closestWrapperElement = closestWrapper as HTMLElement | null;

      if (closestWrapperElement) {
        const rect = closestWrapperElement.getBoundingClientRect();
        const midY = rect.top + rect.height / 2;
        const position = currentY < midY ? "before" : "after";
        updateDropTarget(closestWrapperElement, position);
      } else {
        const closestElement = findClosestElement();

        if (closestElement) {
          const rect = closestElement.getBoundingClientRect();
          const midY = rect.top + rect.height / 2;
          const position = currentY < midY ? "before" : "after";
          updateDropTarget(closestElement, position);
        } else {
          setDropTargetId(null);
          setDropPosition(null);
        }
      }
    }

    const dropZoneRect = dropZoneRef.current.getBoundingClientRect();
    const touchY = event.touches[0].clientY;
    const scrollThreshold = 50;

    if (touchY < dropZoneRect.top + scrollThreshold) {
      const speed = -Math.max(
        5,
        (scrollThreshold - (touchY - dropZoneRect.top)) / 2
      );
      startAutoScroll(speed);
    } else if (touchY > dropZoneRect.bottom - scrollThreshold) {
      const speed = Math.max(
        5,
        (touchY - (dropZoneRect.bottom - scrollThreshold)) / 2
      );
      startAutoScroll(speed);
    } else {
      clearAutoScroll();
    }
  };

  const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    clearAutoScroll();
    document.body.style.overflow = "auto";
    document.body.style.touchAction = "auto";
    setIsDragging(false);
    setTouchStartY(null);

    const element = event.currentTarget as HTMLElement;
    if (element) {
      element.style.transform = "";
      element.style.opacity = "1";
    }

    if (touchedElementType) {
      const newElement = createNewElement(touchedElementType, elements.length);

      const newElements = [...elements];

      if (dropTargetId && dropPosition) {
        const targetElement = newElements.find((el) => el.id === dropTargetId);
        if (targetElement) {
          const newOrder =
            dropPosition === "before"
              ? targetElement.content.order
              : targetElement.content.order + 1;

          newElements.forEach((el) => {
            if (el.content.order >= newOrder) {
              el.content.order += 1;
            }
          });

          newElement.content.order = newOrder;
        }
      } else {
        newElement.content.order = elements.length;
      }

      onElementsChange(
        [...newElements, newElement].sort(
          (a, b) => a.content.order - b.content.order
        )
      );
    } else if (touchedElement && dropTargetId && dropPosition) {
      const newElements = [...elements];
      const sourceElement = newElements.find((el) => el.id === touchedElement);
      const targetElement = newElements.find((el) => el.id === dropTargetId);

      if (sourceElement && targetElement) {
        const oldOrder = sourceElement.content.order;
        const newOrder =
          dropPosition === "before"
            ? targetElement.content.order
            : targetElement.content.order + 1;

        if (oldOrder > newOrder) {
          newElements.forEach((el) => {
            if (el.content.order >= newOrder && el.content.order < oldOrder) {
              el.content.order += 1;
            }
          });
          sourceElement.content.order = newOrder;
        } else if (oldOrder < newOrder) {
          newElements.forEach((el) => {
            if (el.content.order > oldOrder && el.content.order < newOrder) {
              el.content.order -= 1;
            }
          });
          sourceElement.content.order = newOrder - 1;
        }

        onElementsChange(
          newElements.sort((a, b) => a.content.order - b.content.order)
        );
      }
    }

    setDropTargetId(null);
    setDropPosition(null);
  };

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    elementId: string
  ) => {
    event.dataTransfer.setData("text/plain", elementId);
    setDraggedId(elementId);
    setIsDragging(true);
    disableScroll();
  };

  const handleDragEnd = () => {
    setDraggedId(null);
    setIsDragging(false);
    setDropTargetId(null);
    setDropPosition(null);
    enableScroll();
  };

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    targetId: string
  ) => {
    event.preventDefault();
    const sourceId = event.dataTransfer.getData("text/plain");
    const elementType = event.dataTransfer.getData(
      "elementType"
    ) as CanvasElementType;

    const dropRegions = document.querySelectorAll("[data-drop-region='true']");
    let isValidDrop = false;

    dropRegions.forEach((region) => {
      const rect = region.getBoundingClientRect();
      if (
        event.clientY >= rect.top &&
        event.clientY <= rect.bottom &&
        event.clientX >= rect.left &&
        event.clientX <= rect.right
      ) {
        isValidDrop = true;
      }
    });

    if (!isValidDrop) {
      setDropTargetId(null);
      setDropPosition(null);
      return;
    }

    if (elementType) {
      const newElement = createNewElement(elementType, elements.length);
      const newElements = [...elements];
      const targetElement = newElements.find((el) => el.id === targetId);

      if (targetElement) {
        if (dropPosition === "before") {
          newElement.content.order = targetElement.content.order;
          newElements.forEach((el) => {
            if (
              "order" in el.content &&
              el.content.order >= targetElement.content.order
            ) {
              el.content.order += 1;
            }
          });
        } else {
          newElement.content.order = targetElement.content.order + 1;
          newElements.forEach((el) => {
            if (
              "order" in el.content &&
              el.content.order > targetElement.content.order
            ) {
              el.content.order += 1;
            }
          });
        }
      } else {
        newElement.content.order = elements.length;
      }

      onElementsChange(
        [...newElements, newElement].sort((a, b) => {
          const orderA = "order" in a.content ? a.content.order : 0;
          const orderB = "order" in b.content ? b.content.order : 0;
          return orderA - orderB;
        })
      );
    } else if (sourceId) {
      const newElements = [...elements];
      const sourceElement = newElements.find((el) => el.id === sourceId);
      const targetElement = newElements.find((el) => el.id === targetId);

      if (sourceElement && targetElement) {
        const oldOrder =
          "order" in sourceElement.content ? sourceElement.content.order : 0;
        const newOrder =
          dropPosition === "before"
            ? "order" in targetElement.content
              ? targetElement.content.order
              : 0
            : "order" in targetElement.content
            ? targetElement.content.order + 1
            : 1;

        if (oldOrder > newOrder) {
          newElements.forEach((el) => {
            if (
              "order" in el.content &&
              el.content.order >= newOrder &&
              el.content.order < oldOrder
            ) {
              el.content.order += 1;
            }
          });
          if ("order" in sourceElement.content) {
            sourceElement.content.order = newOrder;
          }
        } else if (oldOrder < newOrder) {
          newElements.forEach((el) => {
            if (
              "order" in el.content &&
              el.content.order > oldOrder &&
              el.content.order < newOrder
            ) {
              el.content.order -= 1;
            }
          });
          if ("order" in sourceElement.content) {
            sourceElement.content.order = newOrder - 1;
          }
        }

        onElementsChange(
          newElements.sort((a, b) => {
            const orderA = "order" in a.content ? a.content.order : 0;
            const orderB = "order" in b.content ? b.content.order : 0;
            return orderA - orderB;
          })
        );
      }
    }

    setDropTargetId(null);
    setDropPosition(null);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const element = event.currentTarget;
    const rect = element.getBoundingClientRect();
    const midY = rect.top + rect.height / 2;
    const position = event.clientY < midY ? "before" : "after";
    const elementId = element.getAttribute("data-element-id");

    if (elementId && elementId !== draggedId) {
      setDropTargetId(elementId);
      setDropPosition(position);
    }
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    // Verifica se realmente saiu da área do elemento
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (!relatedTarget?.closest(`[data-element-id="${dropTargetId}"]`)) {
      setDropTargetId(null);
      setDropPosition(null);
    }
  };

  const handleInputChange = (elementId: string, value: string) => {
    const newElements = elements.map((el) => {
      if (el.id === elementId) {
        const content = el.content as Types.TextContent;
        return {
          ...el,
          content: {
            ...content,
            text: value,
          },
        };
      }
      return el;
    });
    onElementsChange(newElements);
  };

  const handleLinkChange = (
    elementId: string,
    field: keyof Types.LinkContent,
    value: string
  ) => {
    const newElements = elements.map((el) => {
      if (el.id === elementId && el.type === "LINK") {
        const content = el.content as Types.LinkContent;
        return {
          ...el,
          content: {
            ...content,
            [field]: value,
          },
        };
      }
      return el;
    });
    onElementsChange(newElements);
  };

  const handleSocialButtonsChange = (
    elementId: string,
    buttonIndex: number,
    field: string,
    value: string
  ) => {
    const newElements = elements.map((el) => {
      if (el.id === elementId && el.type === "SOCIAL_BUTTONS") {
        const content = el.content as Types.SocialButtonsContent;
        const newButtons = [...content.buttons];
        if (!newButtons[buttonIndex]) {
          newButtons[buttonIndex] = {
            platform: "INSTAGRAM",
            url: "",
            title: null,
          };
        }
        newButtons[buttonIndex] = {
          ...newButtons[buttonIndex],
          [field]: value,
        };
        return {
          ...el,
          content: {
            ...content,
            buttons: newButtons,
          },
        };
      }
      return el;
    });
    onElementsChange(newElements);
  };

  const createNewElement = (
    type: CanvasElementType,
    order: number
  ): CanvasElement => {
    const baseContent = {
      order,
      show: true,
    };

    let content: Types.ElementContent;

    switch (type) {
      case "TITLE":
      case "TEXT":
      case "NAME":
      case "USERNAME":
        content = {
          ...baseContent,
          text: "",
          size: type === "TITLE" ? "h1" : undefined,
          alignment: "START",
        };
        break;
      case "IMAGE":
        content = {
          ...baseContent,
          file: {
            path: "",
            type: "FILE",
          },
          textPosition: "BOTTOM",
        };
        break;
      case "AVATAR":
        content = {
          ...baseContent,
          file: {
            path: "",
            type: "FILE",
          },
        };
        break;
      case "LINK":
        content = {
          ...baseContent,
          url: "",
          label: "",
        };
        break;
      case "SOCIAL_BUTTONS":
        content = {
          ...baseContent,
          buttons: [],
          orientation: "HORIZONTAL",
        };
        break;
      case "GROUP":
        content = {
          ...baseContent,
          items: [],
        };
        break;
      default:
        content = {
          ...baseContent,
          text: "",
        } as Types.TextContent;
    }

    return {
      id: `${type.toLowerCase()}_${Date.now()}`,
      type,
      content,
      order,
      show: true,
    };
  };

  const handleToolbarDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    elementType: CanvasElementType
  ) => {
    const newElement = createNewElement(elementType, elements.length);
    event.dataTransfer.setData("elementType", elementType);
    event.dataTransfer.setData("newElement", JSON.stringify(newElement));
    setIsDragging(true);
    disableScroll();
  };

  const handleCanvasDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const elementType = event.dataTransfer.getData(
      "elementType"
    ) as CanvasElementType;
    const targetId = dropTargetId;
    const position = dropPosition;

    if (elementType) {
      const newElement = createNewElement(elementType, elements.length);
      const newElements = [...elements];

      if (targetId && position) {
        const targetElement = newElements.find((el) => el.id === targetId);
        if (targetElement) {
          if (position === "before") {
            newElement.content.order = targetElement.content.order;
            newElements.forEach((el) => {
              if (
                "order" in el.content &&
                el.content.order >= targetElement.content.order
              ) {
                el.content.order += 1;
              }
            });
          } else {
            newElement.content.order = targetElement.content.order + 1;
            newElements.forEach((el) => {
              if (
                "order" in el.content &&
                el.content.order > targetElement.content.order
              ) {
                el.content.order += 1;
              }
            });
          }
        }
      } else {
        newElement.content.order = elements.length;
      }

      onElementsChange(
        [...newElements, newElement].sort((a, b) => {
          const orderA = "order" in a.content ? a.content.order : 0;
          const orderB = "order" in b.content ? b.content.order : 0;
          return orderA - orderB;
        })
      );
    }

    setDropTargetId(null);
    setDropPosition(null);
    setIsDragging(false);
    enableScroll();
  };

  const handleCanvasDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const getElementIcon = (type: CanvasElementType): IconDefinition => {
    switch (type) {
      case "AVATAR":
        return faUserCircle;
      case "NAME":
        return faUser;
      case "USERNAME":
        return faUser;
      case "LINK":
        return faLink;
      case "SOCIAL_BUTTONS":
        return faShareNodes;
      case "TEXT":
        return faFont;
      case "TITLE":
        return faHeading;
      case "IMAGE":
        return faImage;
      case "GROUP":
        return faLayerGroup;
      default:
        return faLink;
    }
  };

  const getSocialIcon = (platform: string): IconDefinition => {
    switch (platform.toUpperCase() as Types.SocialPlatform) {
      case "INSTAGRAM":
        return faInstagram;
      case "FACEBOOK":
        return faFacebook;
      case "YOUTUBE":
        return faYoutube;
      case "TIKTOK":
        return faTiktok;
      case "LINKEDIN":
        return faLinkedin;
      case "X":
        return faTwitter;
      case "PINTEREST":
        return faPinterest;
      case "REDDIT":
        return faReddit;
      case "SNAPCHAT":
        return faSnapchat;
      case "SPOTIFY":
        return faSpotify;
      case "TUMBLR":
        return faTumblr;
      case "VIMEO":
        return faVimeo;
      case "WHATSAPP":
        return faWhatsapp;
      case "GITHUB":
        return faGithub;
      case "GMAIL":
        return faEnvelope;
      default:
        return faLink;
    }
  };

  const renderElementContent = (
    element: CanvasElement,
    isPreview: boolean = false,
    isSelected: boolean = false
  ) => {
    const content = (() => {
      switch (element.type) {
        case "AVATAR":
          const avatarContent = element.content as Types.AvatarContent;
          return isPreview ? (
            <CleeckyKit.Row justify="center">
              <CleeckyKit.Image
                src={avatarContent.file.path || "/placeholder-avatar.png"}
                alt="Avatar"
                width={120}
                height={120}
                borderRadius={avatarContent.borderRadius || "50%"}
                className={
                  internalGlobalColors.borderColor ? "with-border" : ""
                }
              />
            </CleeckyKit.Row>
          ) : (
            <div>
              {t(
                "cleeckykit:common.interface.elements.canvas.elements.avatar.label"
              )}
              {" ("}
              {t(
                "cleeckykit:common.interface.elements.canvas.elements.avatar.click_to_edit"
              )}
              {")"}
            </div>
          );
        case "IMAGE":
          const imageContent = element.content as Types.ImageContent;
          return isPreview ? (
            <CleeckyKit.Row>
              <CleeckyKit.Image
                src={imageContent.file.path || "/placeholder-image.png"}
                alt={imageContent.title || "Imagem"}
                width="100%"
                borderRadius={imageContent.borderRadius}
                className={
                  internalGlobalColors.borderColor ? "with-border" : ""
                }
              />
              {(imageContent.title || imageContent.description) && (
                <CleeckyKit.Column>
                  {imageContent.title && (
                    <CleeckyKit.Text
                      customStyle={{
                        color: getEffectiveColor(undefined, "textColor"),
                      }}>
                      {imageContent.title}
                    </CleeckyKit.Text>
                  )}
                  {imageContent.description && (
                    <CleeckyKit.Text
                      customStyle={{
                        color: getEffectiveColor(undefined, "textColor"),
                      }}>
                      {imageContent.description}
                    </CleeckyKit.Text>
                  )}
                </CleeckyKit.Column>
              )}
            </CleeckyKit.Row>
          ) : (
            <div>
              {t(
                "cleeckykit:common.interface.elements.canvas.elements.image.label"
              )}
              {" ("}
              {t(
                "cleeckykit:common.interface.elements.canvas.elements.image.click_to_edit"
              )}
              {")"}
            </div>
          );
        case "TITLE":
          const titleContent = element.content as Types.TextContent;
          return isPreview ? (
            <CleeckyKit.Row>
              <CleeckyKit.Title
                className="preview-title"
                level={
                  titleContent.size
                    ? (parseInt(titleContent.size.replace("h", "")) as
                        | 1
                        | 2
                        | 3
                        | 4
                        | 5
                        | 6)
                    : 1
                }
                align={
                  titleContent.alignment?.toLowerCase() as
                    | "left"
                    | "center"
                    | "right"
                }
                customStyle={{
                  color: getEffectiveColor(titleContent.color, "textColor"),
                  textDecoration: titleContent.decoration,
                }}>
                {titleContent.text || "[Título]"}
              </CleeckyKit.Title>
            </CleeckyKit.Row>
          ) : (
            <StyledInput
              type="text"
              value={titleContent.text}
              placeholder={t(
                "cleeckykit:common.interface.elements.canvas.elements.title.placeholder"
              )}
              onChange={(e) => handleInputChange(element.id, e.target.value)}
            />
          );
        case "NAME":
          const nameContent = element.content as Types.TextContent;
          return isPreview ? (
            <CleeckyKit.Row>
              <CleeckyKit.Title
                level={4}
                align={nameContent.alignment?.toLowerCase() as any}
                customStyle={{
                  color: getEffectiveColor(nameContent.color, "textColor"),
                  textDecoration: nameContent.decoration,
                }}>
                {nameContent.text || "[Nome]"}
              </CleeckyKit.Title>
            </CleeckyKit.Row>
          ) : (
            <StyledInput
              type="text"
              value={nameContent.text}
              placeholder={t(
                "cleeckykit:common.interface.elements.canvas.elements.name.placeholder"
              )}
              onChange={(e) => handleInputChange(element.id, e.target.value)}
            />
          );
        case "USERNAME":
          const usernameContent = element.content as Types.TextContent;
          return isPreview ? (
            <CleeckyKit.Row>
              <CleeckyKit.Text
                align={usernameContent.alignment?.toLowerCase() as any}
                customStyle={{
                  color: getEffectiveColor(usernameContent.color, "textColor"),
                  textDecoration: usernameContent.decoration,
                }}>
                @{usernameContent.text || "username"}
              </CleeckyKit.Text>
            </CleeckyKit.Row>
          ) : (
            <StyledInput
              type="text"
              value={usernameContent.text}
              placeholder={t(
                "cleeckykit:common.interface.elements.canvas.elements.username.placeholder"
              )}
              onChange={(e) => handleInputChange(element.id, e.target.value)}
            />
          );
        case "TEXT":
          const textContent = element.content as Types.TextContent;
          return isPreview ? (
            <CleeckyKit.Row>
              <CleeckyKit.Text
                className="preview-text"
                align={textContent.alignment?.toLowerCase() as any}
                customStyle={{
                  color: getEffectiveColor(textContent.color, "textColor"),
                  textDecoration: textContent.decoration,
                }}>
                {textContent.text || "[Texto]"}
              </CleeckyKit.Text>
            </CleeckyKit.Row>
          ) : (
            <StyledInput
              type="text"
              value={textContent.text}
              placeholder={t(
                "cleeckykit:common.interface.elements.canvas.elements.text.placeholder"
              )}
              onChange={(e) => handleInputChange(element.id, e.target.value)}
            />
          );
        case "LINK":
          const linkContent = element.content as Types.LinkContent;
          return isPreview ? (
            <CleeckyKit.Row justify="center">
              <CleeckyKit.Button
                text={linkContent.label}
                variant="solid"
                href={linkContent.url}
                target="_blank"
                size="large"
                customStyle={{
                  backgroundColor: getEffectiveColor(
                    linkContent.background,
                    "buttonsBackgroundColor"
                  ),
                  color: getEffectiveColor(
                    linkContent.color,
                    "buttonsLabelColor"
                  ),
                  border: `${
                    linkContent.borderSize || "1px"
                  } solid ${getEffectiveColor(
                    linkContent.border,
                    "borderColor"
                  )}`,
                  borderRadius: linkContent.borderRadius || "8px",
                  width: "100%",
                  maxWidth: "300px",
                }}
              />
            </CleeckyKit.Row>
          ) : (
            <div>
              {t(
                "cleeckykit:common.interface.elements.canvas.elements.link.label"
              )}
              {" ("}
              {t(
                "cleeckykit:common.interface.elements.canvas.elements.link.click_to_edit"
              )}
              {")"}
            </div>
          );
        case "SOCIAL_BUTTONS":
          const socialContent = element.content as Types.SocialButtonsContent;
          return isPreview ? (
            <CleeckyKit.Row justify="center">
              <CleeckyKit.Grid
                className="preview-grid"
                columns={{
                  xs:
                    socialContent.orientation === "HORIZONTAL"
                      ? socialContent.buttons.length
                      : 1,
                }}
                gap={16}>
                {socialContent.buttons.map((button, index) => (
                  <CleeckyKit.Button
                    key={index}
                    className="preview-button"
                    variant="solid"
                    href={button.url}
                    target="_blank"
                    size="medium"
                    icon={
                      <FontAwesomeIcon icon={getSocialIcon(button.platform)} />
                    }
                    customStyle={{
                      backgroundColor: getEffectiveColor(
                        socialContent.background,
                        "buttonsBackgroundColor"
                      ),
                      color: getEffectiveColor(undefined, "buttonsLabelColor"),
                      border: `${
                        socialContent.borderSize || "1px"
                      } solid ${getEffectiveColor(
                        socialContent.border,
                        "borderColor"
                      )}`,
                      borderRadius: socialContent.borderRadius || "8px",
                    }}
                  />
                ))}
              </CleeckyKit.Grid>
            </CleeckyKit.Row>
          ) : (
            <>
              <StyledInput
                type="text"
                value={socialContent.buttons[0]?.platform || ""}
                placeholder="Plataforma (ex: instagram)"
                onChange={(e) =>
                  handleSocialButtonsChange(
                    element.id,
                    0,
                    "platform",
                    e.target.value
                  )
                }
              />
              <StyledInput
                type="text"
                value={socialContent.buttons[0]?.url || ""}
                placeholder="URL"
                onChange={(e) =>
                  handleSocialButtonsChange(
                    element.id,
                    0,
                    "url",
                    e.target.value
                  )
                }
              />
            </>
          );
        case "GROUP":
          const groupContent = element.content as Types.GroupContent;
          return isPreview ? (
            <CleeckyKit.Section
              padding="1rem"
              background={getEffectiveColor(
                groupContent.background,
                "buttonsBackgroundColor"
              )}
              borderRadius={groupContent.borderRadius || "8px"}
              width="100%">
              {groupContent.items.map((item, index) => (
                <React.Fragment key={index}>
                  {renderElementContent(item, true)}
                </React.Fragment>
              ))}
            </CleeckyKit.Section>
          ) : (
            <div>
              {t(
                "cleeckykit:common.interface.elements.canvas.elements.group.label"
              )}
              {" ("}
              {t(
                "cleeckykit:common.interface.elements.canvas.elements.group.click_to_edit"
              )}
              {")"}
            </div>
          );
        default:
          return null;
      }
    })();

    return (
      <ElementContentWrapper $isSelected={isSelected}>
        {content}
      </ElementContentWrapper>
    );
  };

  const renderSection = (section: CanvasSection) => {
    const sectionElements = elements
      .filter((el) => section.elements.includes(el.id))
      .sort((a, b) => a.order - b.order);

    return (
      <SectionWrapper key={section.id}>
        <SectionTitle>{section.title}</SectionTitle>
        {sectionElements.map(renderElement)}
      </SectionWrapper>
    );
  };

  const standaloneElements: CanvasElement[] = elements
    .filter(
      (el: CanvasElement) =>
        !sections.some((section) => section.elements.includes(el.id))
    )
    .sort((a: CanvasElement, b: CanvasElement) => {
      const orderA = "order" in a.content ? a.content.order : 0;
      const orderB = "order" in b.content ? b.content.order : 0;
      return orderA - orderB;
    });

  const renderToolbar = () => {
    const availableElements: CanvasElementType[] = [
      "AVATAR",
      "NAME",
      "USERNAME",
      "LINK",
      "SOCIAL_BUTTONS",
      "TEXT",
      "TITLE",
      "IMAGE",
      "GROUP",
    ];

    const handleScroll = (direction: "left" | "right") => {
      if (toolbarRef.current) {
        const scrollAmount = 200;
        const newScrollLeft =
          toolbarRef.current.scrollLeft +
          (direction === "left" ? -scrollAmount : scrollAmount);
        toolbarRef.current.scrollTo({
          left: newScrollLeft,
          behavior: "smooth",
        });
      }
    };

    return (
      <ToolbarWrapper>
        <ToolbarContent
          $hasScrollLeft={hasScrollLeft}
          $hasScrollRight={hasScrollRight}>
          <ToolbarNavigationButton
            $direction="left"
            onClick={() => handleScroll("left")}
            disabled={!hasScrollLeft}
            title={t(
              "cleeckykit:common.interface.elements.canvas.toolbar.previous"
            )}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </ToolbarNavigationButton>
          <ElementsContainer ref={toolbarRef} onScroll={checkToolbarScroll}>
            {availableElements.map((type) => (
              <ToolbarElement
                key={type}
                onTouchStart={(e) => handleTouchStart(e, undefined, type)}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                draggable
                onDragStart={(e) => handleToolbarDragStart(e, type)}>
                <FontAwesomeIcon icon={getElementIcon(type)} />
                <span>
                  {t(
                    `cleeckykit:common.interface.elements.canvas.elements.${type.toLowerCase()}.label`
                  )}
                </span>
              </ToolbarElement>
            ))}
          </ElementsContainer>
          <ToolbarNavigationButton
            $direction="right"
            onClick={() => handleScroll("right")}
            disabled={!hasScrollRight}
            title={t(
              "cleeckykit:common.interface.elements.canvas.toolbar.next"
            )}>
            <FontAwesomeIcon icon={faChevronRight} />
          </ToolbarNavigationButton>
        </ToolbarContent>
      </ToolbarWrapper>
    );
  };

  const renderPreviewElement = (element: CanvasElement) => {
    return (
      <PreviewElement key={element.id}>
        <div className="preview-content-wrapper">
          {renderElementContent(element, true)}
        </div>
      </PreviewElement>
    );
  };

  const renderPreviewSection = (section: CanvasSection) => {
    const sectionElements = elements
      .filter((el) => section.elements.includes(el.id))
      .sort((a, b) => {
        const orderA = "order" in a.content ? a.content.order : 0;
        const orderB = "order" in b.content ? b.content.order : 0;
        return orderA - orderB;
      });

    return (
      <PreviewSectionWrapper key={section.id}>
        <PreviewSectionTitle>{section.title}</PreviewSectionTitle>
        {sectionElements.map(renderPreviewElement)}
      </PreviewSectionWrapper>
    );
  };

  const handleEditClick = (event: React.MouseEvent, element: CanvasElement) => {
    event.stopPropagation();
    if (element.type === "TITLE") {
      const content = element.content as Types.TextContent;
      setEditingTitle({ text: content.text, size: "h1" });
    }
    setEditingElement(element);
  };

  const handleCloseModal = () => {
    setEditingElement(null);
    setEditingTitle({ text: "", size: "h1" });
  };

  const handleSaveEdit = () => {
    if (editingElement) {
      const newElements = elements.map((el) => {
        if (el.id === editingElement.id) {
          // Retorna o elemento editado com todas as suas propriedades
          return editingElement;
        }
        return el;
      });
      onElementsChange(newElements);
      handleCloseModal();
    }
  };

  const renderEditFields = () => {
    if (!editingElement) return null;

    switch (editingElement.type) {
      case "TITLE":
      case "TEXT":
      case "NAME":
      case "USERNAME":
        const textContent = editingElement.content as Types.TextContent;
        return (
          <CleeckyKit.Column gap={16}>
            <StyledInput
              type="text"
              value={textContent.text}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...textContent,
                    text: e.target.value,
                  },
                })
              }
              placeholder={t(
                `cleeckykit:common.interface.elements.canvas.elements.${editingElement.type.toLowerCase()}.modal.placeholder`
              )}
            />
            {editingElement.type === "TITLE" && (
              <Select
                value={textContent.size || "h1"}
                onChange={(e) =>
                  setEditingElement({
                    ...editingElement,
                    content: {
                      ...textContent,
                      size: e.target.value,
                    },
                  })
                }>
                <option value="h1">H1</option>
                <option value="h2">H2</option>
                <option value="h3">H3</option>
                <option value="h4">H4</option>
                <option value="h5">H5</option>
                <option value="h6">H6</option>
              </Select>
            )}
            <Select
              value={textContent.alignment || "START"}
              onChange={(e) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...textContent,
                    alignment: e.target.value as Types.TextAlignment,
                  },
                })
              }>
              <option value="START">
                {t("cleeckykit:common.alignment.start")}
              </option>
              <option value="CENTER">
                {t("cleeckykit:common.alignment.center")}
              </option>
              <option value="END">
                {t("cleeckykit:common.alignment.end")}
              </option>
            </Select>
            <StyledInput
              type="color"
              value={textContent.color || "#000000"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...textContent,
                    color: e.target.value,
                  },
                })
              }
            />
          </CleeckyKit.Column>
        );

      case "LINK":
        const linkContent = editingElement.content as Types.LinkContent;
        return (
          <CleeckyKit.Column gap={16}>
            <StyledInput
              type="text"
              value={linkContent.label}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...linkContent,
                    label: e.target.value,
                  },
                })
              }
              placeholder={t(
                "cleeckykit:common.interface.elements.canvas.elements.link.modal.label_placeholder"
              )}
            />
            <StyledInput
              type="text"
              value={linkContent.url}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...linkContent,
                    url: e.target.value,
                  },
                })
              }
              placeholder={t(
                "cleeckykit:common.interface.elements.canvas.elements.link.modal.url_placeholder"
              )}
            />

            <StyledInput
              type="color"
              value={linkContent.background || "transparent"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...linkContent,
                    background: e.target.value,
                  },
                })
              }
            />
            <StyledInput
              type="text"
              value={linkContent.borderRadius || "8px"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...linkContent,
                    borderRadius: e.target.value,
                  },
                })
              }
              placeholder={t(
                "cleeckykit:common.interface.elements.canvas.modal.edit.border_radius"
              )}
            />
          </CleeckyKit.Column>
        );

      case "SOCIAL_BUTTONS":
        const socialContent =
          editingElement.content as Types.SocialButtonsContent;
        return (
          <CleeckyKit.Column gap={16}>
            {socialContent.buttons.map((button, index) => (
              <CleeckyKit.Column key={index} gap={8}>
                <Select
                  value={button.platform}
                  onChange={(e) => {
                    const newButtons = [...socialContent.buttons];
                    newButtons[index] = {
                      ...button,
                      platform: e.target.value as Types.SocialPlatform,
                    };
                    setEditingElement({
                      ...editingElement,
                      content: {
                        ...socialContent,
                        buttons: newButtons,
                      },
                    });
                  }}>
                  {Object.values(Types.SOCIAL_PLATFORMS).map((platform) => (
                    <option key={platform} value={platform}>
                      {platform}
                    </option>
                  ))}
                </Select>
                <StyledInput
                  type="text"
                  value={button.url}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newButtons = [...socialContent.buttons];
                    newButtons[index] = {
                      ...button,
                      url: e.target.value,
                    };
                    setEditingElement({
                      ...editingElement,
                      content: {
                        ...socialContent,
                        buttons: newButtons,
                      },
                    });
                  }}
                  placeholder={t(
                    "cleeckykit:common.interface.elements.canvas.elements.social_buttons.modal.url_placeholder"
                  )}
                />
              </CleeckyKit.Column>
            ))}
            <CleeckyKit.Button
              variant="outline"
              text={t("cleeckykit:common.actions.add")}
              onClick={() => {
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...socialContent,
                    buttons: [
                      ...socialContent.buttons,
                      { platform: "INSTAGRAM", url: "", title: null },
                    ],
                  },
                });
              }}
            />
            <Select
              value={socialContent.orientation}
              onChange={(e) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...socialContent,
                    orientation: e.target.value as Types.Orientation,
                  },
                })
              }>
              <option value="HORIZONTAL">
                {t("cleeckykit:common.orientation.horizontal")}
              </option>
              <option value="VERTICAL">
                {t("cleeckykit:common.orientation.vertical")}
              </option>
            </Select>
          </CleeckyKit.Column>
        );

      case "IMAGE":
      case "AVATAR":
        const imageContent = editingElement.content as
          | Types.ImageContent
          | Types.AvatarContent;
        return (
          <CleeckyKit.Column gap={16}>
            <StyledInput
              type="text"
              value={imageContent.file.path}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...imageContent,
                    file: {
                      ...imageContent.file,
                      path: e.target.value,
                    },
                  },
                })
              }
              placeholder={t(
                `cleeckykit:common.interface.elements.canvas.elements.${editingElement.type.toLowerCase()}.modal.url_placeholder`
              )}
            />
            <StyledInput
              type="text"
              value={imageContent.borderRadius || "0px"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...imageContent,
                    borderRadius: e.target.value,
                  },
                })
              }
              placeholder={t(
                `cleeckykit:common.interface.elements.canvas.elements.${editingElement.type.toLowerCase()}.modal.border_radius`
              )}
            />
            {editingElement.type === "IMAGE" && (
              <>
                <StyledInput
                  type="text"
                  value={(imageContent as Types.ImageContent).title || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEditingElement({
                      ...editingElement,
                      content: {
                        ...imageContent,
                        title: e.target.value,
                      } as Types.ImageContent,
                    })
                  }
                  placeholder={t(
                    "cleeckykit:common.interface.elements.canvas.elements.image.modal.text_placeholder"
                  )}
                />
                <StyledInput
                  type="text"
                  value={(imageContent as Types.ImageContent).description || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEditingElement({
                      ...editingElement,
                      content: {
                        ...imageContent,
                        description: e.target.value,
                      } as Types.ImageContent,
                    })
                  }
                  placeholder={t(
                    "cleeckykit:common.interface.elements.canvas.elements.image.modal.description_placeholder"
                  )}
                />
                <Select
                  value={
                    (imageContent as Types.ImageContent).textPosition ||
                    "BOTTOM"
                  }
                  onChange={(e) =>
                    setEditingElement({
                      ...editingElement,
                      content: {
                        ...imageContent,
                        textPosition: e.target.value as Types.TextPosition,
                      } as Types.ImageContent,
                    })
                  }>
                  <option value="TOP">
                    {t("cleeckykit:common.position.top")}
                  </option>
                  <option value="BOTTOM">
                    {t("cleeckykit:common.position.bottom")}
                  </option>
                  <option value="OVERLAY">
                    {t("cleeckykit:common.position.overlay")}
                  </option>
                </Select>
              </>
            )}
          </CleeckyKit.Column>
        );

      case "GROUP":
        const groupContent = editingElement.content as Types.GroupContent;
        return (
          <CleeckyKit.Column gap={16}>
            <StyledInput
              type="color"
              value={groupContent.background || "transparent"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...groupContent,
                    background: e.target.value,
                  },
                })
              }
            />
            <StyledInput
              type="text"
              value={groupContent.borderRadius || "8px"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...groupContent,
                    borderRadius: e.target.value,
                  },
                })
              }
              placeholder={t(
                "cleeckykit:common.interface.elements.canvas.modal.edit.border_radius"
              )}
            />
            <StyledInput
              type="text"
              value={groupContent.borderSize || "1px"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...groupContent,
                    borderSize: e.target.value,
                  },
                })
              }
              placeholder={t(
                "cleeckykit:common.interface.elements.canvas.modal.edit.border_size"
              )}
            />
            <StyledInput
              type="color"
              value={groupContent.border || "#000000"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...groupContent,
                    border: e.target.value,
                  },
                })
              }
            />
          </CleeckyKit.Column>
        );

      default:
        return null;
    }
  };

  const renderEditModal = () => {
    if (!editingElement) return null;

    return (
      <Modal onClick={handleCloseModal}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <ModalHeader>
            <ModalTitle>
              {t(
                `cleeckykit:common.interface.elements.canvas.elements.${editingElement.type.toLowerCase()}.modal.title`
              )}
            </ModalTitle>
          </ModalHeader>
          <ModalBody>{renderEditFields()}</ModalBody>
          <ModalFooter>
            <CleeckyKit.Button
              variant="outline"
              onClick={handleCloseModal}
              text={t("cleeckykit:common.actions.cancel")}
            />
            <CleeckyKit.Button
              variant="solid"
              onClick={handleSaveEdit}
              text={t("cleeckykit:common.actions.save")}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const handleElementClick = (elementId: string) => {
    setSelectedElementId(elementId === selectedElementId ? null : elementId);
  };

  const handleDeleteElement = (elementId: string) => {
    const newElements = elements.filter((el) => el.id !== elementId);
    // Reordena os elementos restantes
    newElements.forEach((el, index) => {
      if ("order" in el.content) {
        el.content.order = index;
      }
    });
    onElementsChange(newElements);
    setSelectedElementId(null);
  };

  // Limpa o intervalo quando o componente é desmontado
  useEffect(() => {
    return () => {
      clearAutoScroll();
    };
  }, []);

  useEffect(() => {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.documentElement.style.setProperty(
      "--scrollbar-width",
      `${scrollbarWidth}px`
    );
  }, []);

  const renderElement = (element: CanvasElement) => {
    const isDragging = element.id === draggedId;
    const isDropTarget = element.id === dropTargetId;
    const isSelected = element.id === selectedElementId;

    return (
      <ElementWrapper
        key={element.id}
        data-element-id={element.id}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onClick={() => handleElementClick(element.id)}>
        {isDropTarget && dropPosition === "before" && (
          <DropRegion
            data-drop-region="true"
            $isActive={true}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrop={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDrop(e, element.id);
            }}>
            <CleeckyKit.Row justify="center" align="center">
              <CleeckyKit.Text>
                {t("cleeckykit:common.interface.elements.canvas.drop_hint")}
              </CleeckyKit.Text>
            </CleeckyKit.Row>

            <DropRegionIcon />
            <DropRegionContent />
          </DropRegion>
        )}
        <DraggableElement
          $isDragging={isDragging}
          $isSelected={isSelected}
          onDrop={(e) => e.preventDefault()}
          onDragOver={(e) => e.preventDefault()}>
          <DragHandle
            draggable
            onDragStart={(e) => handleDragStart(e, element.id)}
            onDragEnd={handleDragEnd}
            onTouchStart={(e) => handleTouchStart(e, element.id)}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}>
            <FontAwesomeIcon icon={faEllipsisV} className="drag-indicator" />
          </DragHandle>
          <FontAwesomeIcon
            icon={getElementIcon(element.type)}
            className="element-icon"
          />
          {renderElementContent(element, false, isSelected)}
          <div className="element-actions">
            <FontAwesomeIcon
              icon={faPen}
              className="action-button edit"
              onClick={(e) => handleEditClick(e, element)}
              title={t("cleeckykit:common.actions.edit")}
            />
            <FontAwesomeIcon
              icon={faTrash}
              className="action-button delete"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteElement(element.id);
              }}
              title={t("cleeckykit:common.actions.delete")}
            />
          </div>
        </DraggableElement>
        {isDropTarget && dropPosition === "after" && (
          <DropRegion
            data-drop-region="true"
            $isActive={true}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrop={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDrop(e, element.id);
            }}>
            <DropRegionIcon />
            <DropRegionContent />
          </DropRegion>
        )}
      </ElementWrapper>
    );
  };

  return (
    <>
      <CleeckyKit.Row>
        <CleeckyKit.Section
          padding="24px"
          width="100%"
          background={currentTheme.colors.background.card}
          borderRadius="8px">
          <CleeckyKit.Column>
            <GlobalSettingsHeader>
              <CleeckyKit.Row justify="between" noBreak>
                <CleeckyKit.Title level={4}>
                  Configurações Globais
                </CleeckyKit.Title>
                <CollapseButton
                  onClick={() =>
                    setIsGlobalSettingsExpanded(!isGlobalSettingsExpanded)
                  }
                  title={isGlobalSettingsExpanded ? "Recolher" : "Expandir"}>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{
                      transform: isGlobalSettingsExpanded
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                  />
                </CollapseButton>
              </CleeckyKit.Row>
            </GlobalSettingsHeader>

            <GlobalSettingsContent
              initial={false}
              animate={{
                height: isGlobalSettingsExpanded ? "auto" : 0,
                opacity: isGlobalSettingsExpanded ? 1 : 0,
              }}
              transition={{ duration: 0.3 }}>
              <CleeckyKit.Row>
                <CleeckyKit.Text>
                  Defina as configurações globais da página, como cores e
                  tamanhos. Elas serão usadas em todos os elementos da página de
                  forma padrão, porém para cada elemento você também poderá
                  alterar as cores e tamanhos de forma específica editando suas
                  propriedades, clique em um elemento para selecionar e depois
                  clique no ícone de "caneta".
                </CleeckyKit.Text>
              </CleeckyKit.Row>
              <CleeckyKit.Row gap={2}>
                <CleeckyKit.Column gap={2}>
                  <CleeckyKit.Title level={5}>
                    {t(
                      "cleeckykit:common.interface.elements.canvas.global_colors.title"
                    )}
                  </CleeckyKit.Title>
                  <ColorPickerWrapper>
                    <ColorPickerButton>
                      <ColorPreview
                        $color={internalGlobalColors.highlightColor}
                      />
                      <ColorInput
                        type="color"
                        value={internalGlobalColors.highlightColor}
                        onChange={(e) =>
                          handleGlobalColorsChange({
                            ...internalGlobalColors,
                            highlightColor: e.target.value,
                          })
                        }
                      />
                    </ColorPickerButton>
                    <ColorPickerLabel>
                      {t(
                        "cleeckykit:common.interface.elements.canvas.global_colors.highlight_color"
                      )}
                    </ColorPickerLabel>
                  </ColorPickerWrapper>

                  <ColorPickerWrapper>
                    <ColorPickerButton>
                      <ColorPreview
                        $color={internalGlobalColors.pageBackgroundColor}
                      />
                      <ColorInput
                        type="color"
                        value={internalGlobalColors.pageBackgroundColor}
                        onChange={(e) =>
                          handleGlobalColorsChange({
                            ...internalGlobalColors,
                            pageBackgroundColor: e.target.value,
                          })
                        }
                      />
                    </ColorPickerButton>
                    <ColorPickerLabel>
                      {t(
                        "cleeckykit:common.interface.elements.canvas.global_colors.page_background"
                      )}
                    </ColorPickerLabel>
                  </ColorPickerWrapper>

                  <ColorPickerWrapper>
                    <ColorPickerButton>
                      <ColorPreview
                        $color={internalGlobalColors.buttonsBackgroundColor}
                      />
                      <ColorInput
                        type="color"
                        value={internalGlobalColors.buttonsBackgroundColor}
                        onChange={(e) =>
                          handleGlobalColorsChange({
                            ...internalGlobalColors,
                            buttonsBackgroundColor: e.target.value,
                          })
                        }
                      />
                    </ColorPickerButton>
                    <ColorPickerLabel>
                      {t(
                        "cleeckykit:common.interface.elements.canvas.global_colors.buttons_background"
                      )}
                    </ColorPickerLabel>
                  </ColorPickerWrapper>

                  <ColorPickerWrapper>
                    <ColorPickerButton>
                      <ColorPreview
                        $color={internalGlobalColors.buttonsLabelColor}
                      />
                      <ColorInput
                        type="color"
                        value={internalGlobalColors.buttonsLabelColor}
                        onChange={(e) =>
                          handleGlobalColorsChange({
                            ...internalGlobalColors,
                            buttonsLabelColor: e.target.value,
                          })
                        }
                      />
                    </ColorPickerButton>
                    <ColorPickerLabel>
                      {t(
                        "cleeckykit:common.interface.elements.canvas.global_colors.buttons_label"
                      )}
                    </ColorPickerLabel>
                  </ColorPickerWrapper>

                  <ColorPickerWrapper>
                    <ColorPickerButton>
                      <ColorPreview $color={internalGlobalColors.textColor} />
                      <ColorInput
                        type="color"
                        value={internalGlobalColors.textColor}
                        onChange={(e) =>
                          handleGlobalColorsChange({
                            ...internalGlobalColors,
                            textColor: e.target.value,
                          })
                        }
                      />
                    </ColorPickerButton>
                    <ColorPickerLabel>
                      {t(
                        "cleeckykit:common.interface.elements.canvas.global_colors.text_color"
                      )}
                    </ColorPickerLabel>
                  </ColorPickerWrapper>

                  <ColorPickerWrapper>
                    <ColorPickerButton>
                      <ColorPreview $color={internalGlobalColors.borderColor} />
                      <ColorInput
                        type="color"
                        value={internalGlobalColors.borderColor}
                        onChange={(e) =>
                          handleGlobalColorsChange({
                            ...internalGlobalColors,
                            borderColor: e.target.value,
                          })
                        }
                      />
                    </ColorPickerButton>
                    <ColorPickerLabel>
                      {t(
                        "cleeckykit:common.interface.elements.canvas.global_colors.border_color"
                      )}
                    </ColorPickerLabel>
                  </ColorPickerWrapper>
                </CleeckyKit.Column>
                <CleeckyKit.Column gap={2}>
                  <CleeckyKit.Title level={5}>
                    Tamanhos Globais
                  </CleeckyKit.Title>
                </CleeckyKit.Column>
              </CleeckyKit.Row>
            </GlobalSettingsContent>
          </CleeckyKit.Column>
        </CleeckyKit.Section>
      </CleeckyKit.Row>
      <CleeckyKit.Row>
        <EditorArea>
          <CanvasOuterWrapper>
            <CanvasContainer className={className}>
              <CleeckyKit.Row justify="center" margin="0 0 8px 0">
                <CleeckyKit.Text align="center">
                  {t(
                    "project:apps.toolbox.tools.link_aggregator.create_page.drag_hint"
                  )}
                </CleeckyKit.Text>
              </CleeckyKit.Row>
              <DropZone
                ref={dropZoneRef}
                $isDragging={isDragging}
                onDrop={handleCanvasDrop}
                onDragOver={handleCanvasDragOver}>
                <ContentWrapper>
                  <CleeckyKit.Row>
                    <CleeckyKit.Column>
                      {sections.map(renderSection)}
                      {standaloneElements.map(renderElement)}
                    </CleeckyKit.Column>
                  </CleeckyKit.Row>
                </ContentWrapper>
              </DropZone>
              <br />
              {renderToolbar()}
            </CanvasContainer>
          </CanvasOuterWrapper>
        </EditorArea>

        <PreviewWrapper>
          <PreviewArea>
            <PreviewHeader>
              <PreviewButton
                $isActive={previewMode === "desktop"}
                onClick={() => setPreviewMode("desktop")}
                title={t(
                  "cleeckykit:common.interface.elements.canvas.preview.desktop"
                )}>
                <FontAwesomeIcon icon={faDesktop} />
              </PreviewButton>
              <PreviewButton
                $isActive={previewMode === "mobile"}
                onClick={() => setPreviewMode("mobile")}
                title={t(
                  "cleeckykit:common.interface.elements.canvas.preview.mobile"
                )}>
                <FontAwesomeIcon icon={faMobileAlt} />
              </PreviewButton>
            </PreviewHeader>
            <PreviewContent $isMobile={previewMode === "mobile"}>
              <BrowserBar>
                <BrowserIcon>
                  <MinimalistIcon />
                </BrowserIcon>
                <BrowserAddressBar>
                  <FontAwesomeIcon icon={faLock} />
                  preview.cleecky.com
                </BrowserAddressBar>
                <BrowserDots>
                  <span />
                  <span />
                  <span />
                </BrowserDots>
              </BrowserBar>
              <div className="preview-content-inner">
                <div className="preview-content-wrapper">
                  <CleeckyKit.Column>
                    {sections.map(renderPreviewSection)}
                    {standaloneElements.map(renderPreviewElement)}
                  </CleeckyKit.Column>
                </div>
              </div>
            </PreviewContent>
          </PreviewArea>
        </PreviewWrapper>
        {renderEditModal()}
      </CleeckyKit.Row>
    </>
  );
};

CleeckyKitCanvas.displayName = "CleeckyKitCanvas";
