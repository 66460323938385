import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { routes } from "./routes";
import DynamicPageResolver from "./DynamicPageResolver";
import { useAuthRedirect } from "../hooks/useAuthRedirect";
import { MainLayout } from "components/Layouts/MainLayout";
import * as CleeckyKit from "CleeckyKit";
import { NotFoundScreen } from "components";

const DynamicRoutes: React.FC = () => {
  const { user } = useAuth();
  useAuthRedirect();

  const renderRoutes = (routes: any[]) => {
    return routes.map((route) => {
      if (!route.isPublic && !user) {
        return null;
      }

      return (
        <React.Fragment key={route.id}>
          {route.component && (
            <Route path={route.path} element={<route.component />} />
          )}

          {route.children?.map((child: any) => {
            return (
              <React.Fragment key={child.id}>
                <Route
                  path={child.path}
                  element={child.component ? <child.component /> : null}
                />
                {child.children && renderRoutes(child.children)}
              </React.Fragment>
            );
          })}
        </React.Fragment>
      );
    });
  };

  return (
    <Routes>
      <Route
        path="/:username/:slug"
        element={
          <MainLayout hideNavigation={true}>
            <DynamicPageResolver routeId="link_aggregator" />
          </MainLayout>
        }
      />

      <Route
        element={
          <MainLayout>
            <Outlet />
          </MainLayout>
        }>
        {renderRoutes(routes)}
      </Route>

      <Route
        path="*"
        element={
          <MainLayout hideNavigation={true}>
            <NotFoundScreen />
          </MainLayout>
        }
      />
    </Routes>
  );
};

export default DynamicRoutes;
