import api from "../axiosConfig";
import { AggregatorPageData, AggregatorContentType } from "cleecky@types";

interface CreatePageContentDto {
  type: AggregatorContentType;
  data: any;
  order: number;
  show?: boolean;
}

export const aggregatorService = {
  async getAll(): Promise<AggregatorPageData[]> {
    const response = await api.get("/toolbox/aggregator-pages");
    return response.data;
  },

  async delete(uuid: string): Promise<void> {
    await api.delete(`/toolbox/aggregator-pages/${uuid}`);
  },

  async toggleVisibility(
    uuid: string,
    updateDto: { visibility: string }
  ): Promise<AggregatorPageData> {
    const response = await api.put(
      `/toolbox/aggregator-pages/${uuid}`,
      updateDto
    );
    return response.data;
  },

  async addApprovedUser(
    pageUuid: string,
    userId: string
  ): Promise<AggregatorPageData> {
    const response = await api.post(
      `/toolbox/aggregator-pages/${pageUuid}/approved-users/${userId}`
    );
    return response.data;
  },

  async removeApprovedUser(
    pageUuid: string,
    userId: string
  ): Promise<AggregatorPageData> {
    const response = await api.delete(
      `/toolbox/aggregator-pages/${pageUuid}/approved-users/${userId}`
    );
    return response.data;
  },

  async getPagePreview(url: string): Promise<string> {
    const response = await api.get(
      `/toolbox/aggregator-pages/preview?url=${encodeURIComponent(url)}`
    );
    return response.data;
  },

  async addContent(
    pageUuid: string,
    content: CreatePageContentDto
  ): Promise<AggregatorPageData> {
    const response = await api.post(
      `/toolbox/aggregator-pages/${pageUuid}/contents`,
      content
    );
    return response.data;
  },

  async updateContent(
    contentUuid: string,
    updateDto: Partial<CreatePageContentDto>
  ): Promise<AggregatorPageData> {
    const response = await api.put(
      `/toolbox/aggregator-pages/contents/${contentUuid}`,
      updateDto
    );
    return response.data;
  },

  async deleteContent(contentUuid: string): Promise<void> {
    await api.delete(`/toolbox/aggregator-pages/contents/${contentUuid}`);
  },

  async reorderContents(
    pageUuid: string,
    contentUuids: string[]
  ): Promise<AggregatorPageData> {
    const response = await api.put(
      `/toolbox/aggregator-pages/contents/${pageUuid}/reorder`,
      { contentUuids }
    );
    return response.data;
  },
};
