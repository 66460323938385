import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import * as echarts from "echarts";
import { useCleeckyKitCurrentTheme } from "CleeckyKit";

interface BarChartProps {
  data: {
    categories: string[];
    values: number[];
    highlightIndex?: number;
  };
  title?: string;
  height?: string;
  width?: string;
  showAverage?: boolean;
  averageLineColor?: string;
  barColor?: string;
  highlightColor?: string;
  onBarClick?: (index: number) => void;
}

const ChartContainer = styled.div<{ $height?: string; $width?: string }>`
  height: ${({ $height }) => $height || "350px"};
  width: ${({ $width }) => $width || "600px"};
  min-height: ${({ $width }) => $width || "175px"};
  min-width: ${({ $width }) => $width || "300px"};
  padding: 1rem;
  overflow: visible;
`;

export const CleeckyKitBarChart: React.FC<BarChartProps> = ({
  data,
  title,
  height,
  width,
  showAverage = false,
  averageLineColor,
  barColor,
  highlightColor,
  onBarClick,
}) => {
  const { t } = useTranslation();
  const { currentGradient, currentTheme } = useCleeckyKitCurrentTheme();
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<echarts.ECharts | null>(null);
  const [activeBarIndex, setActiveBarIndex] = useState<number | undefined>(
    data.highlightIndex
  );

  useEffect(() => {
    if (chartRef.current) {
      if (!chartInstance.current) {
        chartInstance.current = echarts.init(chartRef.current);

        // Adiciona o evento de clique
        chartInstance.current.on("click", (params) => {
          if (params.componentType === "series") {
            const index = params.dataIndex as number;
            setActiveBarIndex(index);
            onBarClick?.(index);
          }
        });
      }

      const average = showAverage
        ? data.values.reduce((acc, val) => acc + val, 0) / data.values.length
        : undefined;

      const series: any[] = [
        {
          type: "bar",
          data: data.values.map((value, index) => ({
            value,
            itemStyle: {
              borderRadius: 5,
              color:
                index === activeBarIndex
                  ? highlightColor ||
                    currentGradient?.highlightColor ||
                    currentTheme.colors.charts.bar.active
                  : barColor || currentTheme.colors.charts.bar.default,
            },
            emphasis: {
              itemStyle: {
                color:
                  index === activeBarIndex
                    ? highlightColor ||
                      currentGradient?.highlightColor ||
                      currentTheme.colors.charts.bar.active
                    : currentTheme.colors.charts.bar.hover,
              },
            },
          })),
        },
      ];

      if (showAverage && average !== undefined) {
        series.push({
          type: "line",
          markLine: {
            data: [{ yAxis: average }],
            symbol: ["none", "none"],
            lineStyle: {
              color: averageLineColor || currentTheme.colors.charts.bar.average,
              type: "dashed",
            },
            label: {
              formatter:
                t("cleeckykit:common.charts.average") +
                "\n" +
                average.toFixed(2),
              position: "insideEndTop",
              color: "white",
              backgroundColor: currentTheme.colors.charts.bar.average,
              borderRadius: 4,
              padding: 4,
              fontSize: 12,
              fontWeight: "bold",
            },
          },
        });
      }

      const options: any = {
        title: title
          ? {
              text: title,
              left: "center",
              textStyle: {
                color: currentTheme.colors.charts.text,
              },
            }
          : undefined,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          backgroundColor: currentTheme.colors.charts.tooltip.background,
          borderColor: currentTheme.colors.charts.tooltip.border,
          textStyle: {
            color: currentTheme.colors.charts.tooltip.text,
          },
          confine: false,
          position: function (
            point: any,
            params: any,
            dom: any,
            rect: any,
            size: any
          ) {
            // Retorna uma posição que garante que o tooltip fique sempre visível
            const [x, y] = point;
            const obj = { top: y, left: x };

            // Ajusta a posição se estiver muito próximo das bordas
            if (x + size.contentSize[0] > size.viewSize[0]) {
              obj.left = x - size.contentSize[0];
            }
            if (y + size.contentSize[1] > size.viewSize[1]) {
              obj.top = y - size.contentSize[1];
            }

            return obj;
          },
          z: 9999,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data.categories,
          axisTick: {
            alignWithLabel: true,
            lineStyle: {
              color: currentTheme.colors.charts.grid.line,
            },
          },
          axisLine: {
            lineStyle: {
              color: currentTheme.colors.charts.grid.line,
            },
          },
          axisLabel: {
            color: currentTheme.colors.charts.grid.text,
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            lineStyle: {
              color: currentTheme.colors.charts.grid.line,
            },
          },
          axisLine: {
            lineStyle: {
              color: currentTheme.colors.charts.grid.line,
            },
          },
          axisLabel: {
            color: currentTheme.colors.charts.grid.text,
          },
          splitLine: {
            lineStyle: {
              color: currentTheme.colors.charts.grid.line,
              opacity: 0.3,
            },
          },
        },
        series,
      };

      chartInstance.current.setOption(options);
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.dispose();
        chartInstance.current = null;
      }
    };
  }, [
    data,
    title,
    showAverage,
    averageLineColor,
    barColor,
    highlightColor,
    t,
    currentTheme,
    currentGradient,
    activeBarIndex,
    onBarClick,
  ]);

  useEffect(() => {
    const handleResize = () => {
      if (chartInstance.current) {
        chartInstance.current.resize();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <ChartContainer ref={chartRef} $height={height} $width={width} />;
};

CleeckyKitBarChart.displayName = "CleeckyKitBarChart";
