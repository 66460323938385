import React from "react";
import styled from "styled-components";

interface CardGroupProps {
  children: React.ReactNode;
  columns?: {
    sm?: number;
    md?: number;
    lg?: number;
  };
  gap?: string;
  className?: string;
}

const GroupContainer = styled.div<{
  $columns: CardGroupProps["columns"];
  $gap?: string;
}>`
  display: grid;
  gap: ${({ $gap }) => $gap || "1rem"};
  width: 100%;

  @media (min-width: ${({ theme }) => theme.responsive.breakpoints.sm}) {
    grid-template-columns: ${({ $columns }) =>
      $columns?.sm ? `repeat(${$columns.sm}, 1fr)` : "1fr"};
  }

  @media (min-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    grid-template-columns: ${({ $columns }) =>
      $columns?.md ? `repeat(${$columns.md}, 1fr)` : "repeat(2, 1fr)"};
  }

  @media (min-width: ${({ theme }) => theme.responsive.breakpoints.lg}) {
    grid-template-columns: ${({ $columns }) =>
      $columns?.lg ? `repeat(${$columns.lg}, 1fr)` : "repeat(3, 1fr)"};
  }
`;

export const CleeckyKitCardGroup: React.FC<CardGroupProps> = ({
  children,
  columns,
  gap,
  className,
}) => {
  return (
    <GroupContainer $columns={columns} $gap={gap} className={className}>
      {children}
    </GroupContainer>
  );
};
