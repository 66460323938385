import axios from "axios";

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://api.cleecky.com"
      : "http://localhost:31231",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "https://cleecky.com",
  },
});

// Adicionar interceptor para requisições
api.interceptors.request.use(
  (config) => {
    console.log("Enviando requisição:", {
      url: config.url,
      method: config.method,
      baseURL: config.baseURL,
      headers: config.headers,
      data: config.data,
    });
    return config;
  },
  (error) => {
    console.error("Erro na requisição:", error);
    return Promise.reject(error);
  }
);

// Melhorar o interceptor de resposta
api.interceptors.response.use(
  (response) => {
    console.log("Resposta recebida:", {
      status: response.status,
      data: response.data,
      headers: response.headers,
    });
    return response;
  },
  (error) => {
    console.error("Erro na resposta:", {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
      config: error.config,
    });
    if (error.response?.status === 401) {
      localStorage.removeItem("user");
      document.cookie =
        "auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
    return Promise.reject(error);
  }
);

export default api;
