import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import * as echarts from "echarts";
import { useCleeckyKitCurrentTheme } from "CleeckyKit";

interface PieChartProps {
  data: {
    value: number;
    valueLabel: string;
    reaming?: number;
    reamingLabel: string;
    total: number;
    label?: string;
  };
  title?: string;
  height?: string;
  width?: string;
  color?: string;
  backgroundColor?: string;
  showPercentage?: boolean;
  showValue?: boolean;
  showTotal?: boolean;
}

const ChartContainer = styled.div<{ $height?: string; $width?: string }>`
  height: ${({ $height }) => $height || "200px"};
  min-height: ${({ $width }) => $width || "200px"};
  width: ${({ $width }) => $width || "200px"};
  min-width: ${({ $width }) => $width || "200px"};
  padding: 1rem;
  overflow: visible;
`;

export const CleeckyKitPieChart: React.FC<PieChartProps> = ({
  data,
  title,
  height,
  width,
  color,
  backgroundColor,
  showPercentage = true,
  showValue = false,
  showTotal = false,
}) => {
  const { t } = useTranslation();
  const { currentGradient, currentTheme } = useCleeckyKitCurrentTheme();
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<echarts.ECharts | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      if (!chartInstance.current) {
        chartInstance.current = echarts.init(chartRef.current);
      }

      const percentage = (data.value / data.total) * 100;
      const remaining = data.total - data.value;

      let formatterText = data.label;
      if (showPercentage)
        formatterText = `{percentage|${percentage.toFixed(
          0
        )}%}\n ${formatterText}`;
      if (showValue) formatterText += `{value|${data.value}}\n`;
      if (showTotal) formatterText += `{total|${data.total}}`;

      const options: any = {
        title: title
          ? {
              text: title,
              left: "center",
              textStyle: {
                color: currentTheme.colors.charts.text,
              },
            }
          : undefined,
        tooltip: {
          trigger: "item",
          formatter: (params: any) => {
            const value = params.value;
            const percentage = (value / data.total) * 100;
            return `${params.name}: ${value} (${percentage.toFixed(1)}%)`;
          },
          backgroundColor: currentTheme.colors.charts.tooltip.background,
          borderColor: currentTheme.colors.charts.tooltip.border,
          textStyle: {
            color: currentTheme.colors.charts.tooltip.text,
          },
          confine: false,
          position: function (
            point: any,
            params: any,
            dom: any,
            rect: any,
            size: any
          ) {
            const [x, y] = point;
            const obj = { top: y, left: x };

            if (x + size.contentSize[0] > size.viewSize[0]) {
              obj.left = x - size.contentSize[0];
            }
            if (y + size.contentSize[1] > size.viewSize[1]) {
              obj.top = y - size.contentSize[1];
            }

            return obj;
          },
          z: 9999,
        },
        series: [
          {
            name: data.label || t("cleeckykit:common.charts.data"),
            type: "pie",
            radius: ["75%", "90%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "center",
              formatter: formatterText,
              color: currentTheme.colors.charts.text,
              rich: {
                percentage: {
                  fontSize: 28,
                  fontWeight: "bold",
                  color:
                    color ||
                    currentGradient?.highlightColor ||
                    currentTheme.colors.charts.pie.default,
                },
                value: {
                  fontSize: 14,
                  color:
                    color ||
                    currentGradient?.highlightColor ||
                    currentTheme.colors.charts.pie.default,
                },
                total: {
                  fontSize: 12,
                  color:
                    color ||
                    currentGradient?.highlightColor ||
                    currentTheme.colors.charts.pie.default,
                },
              },
            },
            emphasis: {
              itemStyle: {
                borderRadius: 10,
                color:
                  color ||
                  `${currentGradient?.highlightColor}94` ||
                  currentTheme.colors.charts.pie.default,
              },
            },
            data: [
              {
                value: data.value,
                name: data.valueLabel || t("cleeckykit:common.charts.value"),
                itemStyle: {
                  borderRadius: 10,
                  color:
                    color ||
                    currentGradient?.highlightColor ||
                    currentTheme.colors.charts.pie.default,
                },
              },
              {
                value: remaining,
                name:
                  data.reamingLabel || t("cleeckykit:common.charts.remaining"),
                itemStyle: {
                  borderRadius: 10,
                  color:
                    backgroundColor ||
                    currentTheme.colors.charts.pie.background,
                },
              },
            ],
          },
        ],
      };

      chartInstance.current.setOption(options);
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.dispose();
        chartInstance.current = null;
      }
    };
  }, [
    data,
    title,
    color,
    backgroundColor,
    showPercentage,
    showValue,
    showTotal,
    t,
    currentTheme,
    currentGradient,
  ]);

  useEffect(() => {
    const handleResize = () => {
      if (chartInstance.current) {
        chartInstance.current.resize();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <ChartContainer ref={chartRef} $height={height} $width={width} />;
};

CleeckyKitPieChart.displayName = "CleeckyKitPieChart";
