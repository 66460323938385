import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const LoadingContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.background.base};
  z-index: 9999;
`;

const LoadingSpinner = styled(motion.div)`
  width: 50px;
  height: 50px;
  border: 4px solid ${({ theme }) => theme.colors.background.card};
  border-top-color: ${({ theme }) =>
    theme.gradient?.highlightColor || theme.colors.interactive.primary};
  border-radius: 50%;
`;

export const CleeckyKitLoadingScreen: React.FC = () => {
  return (
    <LoadingContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
      <LoadingSpinner
        animate={{ rotate: 360 }}
        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
      />
    </LoadingContainer>
  );
};
