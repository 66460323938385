import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styled, { useTheme } from "styled-components";
import * as CleeckyKit from "CleeckyKit";

interface EmptyStateProps {
  icon: IconProp;
  title: string;
  description: string;
  color?: string;
}

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  background: ${({ theme }) => theme.colors.background.card};
  border-radius: 25px;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
`;

const IconContainer = styled.div<{ color?: string }>`
  background: ${({ color, theme }) =>
    (color || theme.colors.interactive.primary) + "20"};
  color: ${({ color, theme }) => color || theme.colors.interactive.primary};
  width: 64px;
  height: 64px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  margin-bottom: 1.5rem;
`;

export const CleeckyKitEmptyState: React.FC<EmptyStateProps> = ({
  icon,
  title,
  description,
  color,
}) => {
  const theme = useTheme();

  return (
    <EmptyStateContainer>
      <IconContainer color={color}>
        <FontAwesomeIcon icon={icon} />
      </IconContainer>
      <CleeckyKit.Column gap="0.5rem" align="center">
        <CleeckyKit.Title level={4} align="center">
          {title}
        </CleeckyKit.Title>
        <CleeckyKit.Text
          align="center"
          customStyle={{ color: theme.colors.text.secondary }}>
          {description}
        </CleeckyKit.Text>
      </CleeckyKit.Column>
    </EmptyStateContainer>
  );
};
