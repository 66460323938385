export interface CleeckyKitGradientAnimation {
  name: string;
  keyframes: string;
  duration: string;
  timingFunction: string;
  iterationCount: string | number;
}

export const defaultGradientAnimation: CleeckyKitGradientAnimation = {
  name: "wave",
  keyframes: `
      0% {
        background-position: 0% 0%;
      }
      25% {
        background-position: 100% 0%;
      }
      50% {
        background-position: 100% 100%;
      }
      75% {
        background-position: 0% 100%;
      }
      100% {
        background-position: 0% 0%;
      }
    `,
  duration: "10s",
  timingFunction: "linear",
  iterationCount: "infinite",
};

export const createGradientAnimation = (
  animation: Partial<CleeckyKitGradientAnimation>
): CleeckyKitGradientAnimation => {
  return {
    ...defaultGradientAnimation,
    ...animation,
  };
};

export const predefinedAnimations: Record<string, CleeckyKitGradientAnimation> =
  {
    wave: {
      name: "wave",
      keyframes: `
      0% {
        background-position: 0% 0%;
      }
      25% {
        background-position: 100% 0%;
      }
      50% {
        background-position: 100% 100%;
      }
      75% {
        background-position: 0% 100%;
      }
      100% {
        background-position: 0% 0%;
      }
    `,
      duration: "10s",
      timingFunction: "linear",
      iterationCount: "infinite",
    },
    morph: {
      name: "morph",
      keyframes: `
      0% {
        background-size: 400% 400%;
        background-position: 0% 0%;
      }
      50% {
        background-size: 200% 200%;
        background-position: 100% 100%;
      }
      100% {
        background-size: 400% 400%;
        background-position: 0% 0%;
      }
    `,
      duration: "10s",
      timingFunction: "ease-in-out",
      iterationCount: "infinite",
    },
  };
