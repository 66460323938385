import React from "react";
import styled, { css, keyframes } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useCleeckyKitCurrentTheme } from "CleeckyKit";
import { formatText } from "CleeckyKit/utilities/textFormatting";

type TitleLevel = 1 | 2 | 3 | 4 | 5 | 6;
type TitleAlign = "left" | "center" | "right";
type TitleAnimation =
  | "fade"
  | "scale"
  | "slide"
  | "gradient"
  | "typewriter"
  | "none";
type TitleWeight = "light" | "normal" | "medium" | "semibold" | "bold";

interface TitleProps {
  children: React.ReactNode;
  level?: TitleLevel;
  align?: TitleAlign;
  weight?: TitleWeight;
  animation?: TitleAnimation;
  animationDelay?: number;
  gradient?: boolean;
  interactive?: boolean;
  underline?: boolean;
  uppercase?: boolean;
  spacing?: string;
  customStyle?: React.CSSProperties;
  className?: string;
  id?: string;
  href?: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
}

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const getFontSize = (level: TitleLevel) => {
  const sizes = {
    1: "2.5rem",
    2: "2rem",
    3: "1.75rem",
    4: "1.5rem",
    5: "1.25rem",
    6: "1rem",
  };
  return sizes[level];
};

const getFontWeight = (weight: TitleWeight) => {
  const weights = {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  };
  return weights[weight];
};

const StyledTitle = styled(motion.h1)<{
  $level: TitleLevel;
  $align: TitleAlign;
  $weight: TitleWeight;
  $gradient?: boolean;
  $interactive?: boolean;
  $underline?: boolean;
  $uppercase?: boolean;
  $spacing?: string;
  $isLink?: boolean;
}>`
  margin: 0;
  padding: 0;
  font-size: ${({ $level }) => getFontSize($level)};
  font-weight: ${({ $weight }) => getFontWeight($weight)};
  text-align: ${({ $align }) => $align};
  letter-spacing: ${({ $spacing }) => $spacing || "normal"};
  color: ${({ theme }) => theme.colors.text.primary};
  transition: all 0.2s ease;
  white-space: pre-wrap;
  word-wrap: break-word;

  /* Garante que elementos aninhados herdem as propriedades corretas */
  * {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
  }

  ${({ $uppercase }) =>
    $uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${({ $underline, theme }) =>
    $underline &&
    css`
      border-bottom: 2px solid ${theme.colors.interactive.primary};
      padding-bottom: 0.5rem;
    `}

  ${({ $gradient, theme }) =>
    $gradient &&
    css`
      background: linear-gradient(
        90deg,
        ${theme.gradient?.startColor || theme.colors.interactive.primary},
        ${theme.gradient?.endColor || theme.colors.interactive.secondary}
      );
      background-size: 200% auto;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: ${gradientAnimation} 3s ease infinite;
    `}

  ${({ $interactive, $isLink, theme }) =>
    $interactive &&
    css`
      cursor: pointer;
      &:hover {
        color: ${$isLink
          ? theme.gradient?.highlightColor || theme.colors.interactive.primary
          : theme.colors.interactive.primary};
      }
    `}

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    font-size: ${({ $level }) => `calc(${getFontSize($level)} * 0.9)`};
  }

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.sm}) {
    font-size: ${({ $level }) => `calc(${getFontSize($level)} * 0.8)`};
  }
`;

export const CleeckyKitTitle: React.FC<TitleProps> = ({
  children,
  level = 1,
  align = "left",
  weight = "bold",
  animation = "none",
  animationDelay = 0,
  gradient = false,
  interactive = false,
  underline = false,
  uppercase = false,
  spacing,
  customStyle,
  className,
  id,
  href,
  target = "_blank",
}) => {
  const { currentTheme } = useCleeckyKitCurrentTheme();

  const formattedChildren = React.useMemo(() => {
    return formatText(children);
  }, [children]);

  const getAnimationProps = () => {
    switch (animation) {
      case "fade":
        return {
          initial: { opacity: 0 },
          animate: { opacity: 1 },
          transition: { delay: animationDelay },
        };
      case "scale":
        return {
          initial: { scale: 0.8, opacity: 0 },
          animate: { scale: 1, opacity: 1 },
          transition: { delay: animationDelay },
        };
      case "slide":
        return {
          initial: { x: -20, opacity: 0 },
          animate: { x: 0, opacity: 1 },
          transition: { delay: animationDelay },
        };
      case "typewriter":
        return {
          initial: { width: 0 },
          animate: { width: "100%" },
          transition: { duration: 1, delay: animationDelay },
        };
      default:
        return {};
    }
  };

  const content = (
    <StyledTitle
      as={`h${level}`}
      $level={level}
      $align={align}
      $weight={weight}
      $gradient={gradient}
      $interactive={interactive || !!href}
      $underline={underline}
      $uppercase={uppercase}
      $spacing={spacing}
      $isLink={!!href}
      className={className}
      style={customStyle}
      id={id}
      {...getAnimationProps()}>
      {formattedChildren}
    </StyledTitle>
  );

  if (href) {
    return (
      <a
        href={href}
        target={target}
        rel={target === "_blank" ? "noopener noreferrer" : undefined}
        style={{ textDecoration: "none" }}>
        {content}
      </a>
    );
  }

  return content;
};

CleeckyKitTitle.displayName = "CleeckyKitTitle";
