import React from "react";
import styled from "styled-components";

interface FormGroupProps {
  children: React.ReactNode;
  columns?: number;
  gap?: string | number;
}

const StyledFormGroup = styled.div<{
  $columns?: number;
  $gap?: string | number;
}>`
  display: grid;
  grid-template-columns: repeat(${({ $columns }) => $columns || 12}, 1fr);
  gap: ${({ $gap }) =>
    typeof $gap === "number" ? `${$gap}px` : $gap || "1rem"};
  width: 100%;
`;

export const CleeckyKitFormGroup: React.FC<FormGroupProps> = ({
  children,
  columns = 12,
  gap,
}) => {
  return (
    <StyledFormGroup $columns={columns} $gap={gap}>
      {children}
    </StyledFormGroup>
  );
};
