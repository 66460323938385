import React from "react";
import styled from "styled-components";
import { useCleeckyKitResponsive } from "../../../hooks";

interface ContainerProps {
  children: React.ReactNode;
  fluid?: boolean;
  className?: string;
}

const StyledContainer = styled.div<{ $maxWidth: string }>`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    max-width: 100% !important;
  }
`;

export const CleeckyKitContainer: React.FC<ContainerProps> = ({
  children,
  fluid = false,
  className,
}) => {
  const { currentBreakpoint, containerMaxWidths } = useCleeckyKitResponsive();

  const maxWidth = fluid ? "100%" : containerMaxWidths[currentBreakpoint];

  return (
    <StyledContainer $maxWidth={maxWidth} className={className}>
      {children}
    </StyledContainer>
  );
};
