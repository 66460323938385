import React, { useState, useRef } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import { CleeckyKitBaseInput, BaseInputProps } from "../CleeckyKitBaseInput";
import { CleeckyKitButton } from "../../Button/CleeckyKitButton";
import { CleeckyKitCalendar } from "./CleeckyKitCalendar";
import { useCleeckyKitOutsideClick } from "CleeckyKit/hooks";
import dayjs, { Dayjs } from "dayjs";
import { Z_INDEX } from "CleeckyKit/themes/zindex";

interface DateTimePickerProps
  extends Omit<BaseInputProps, "type" | "value" | "onChange"> {
  value: Date | null;
  onChange?: (date: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  minTime?: string;
  maxTime?: string;
  timeStep?: number;
  timeFormat?: "12h" | "24h";
  clearable?: boolean;
}

const PopoverContainer = styled.div`
  position: relative;
`;

const Popover = styled(motion.div)`
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  background: ${({ theme }) => theme.colors.background.card};
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: ${({ theme }) => theme.borderRadius.card};
  box-shadow: ${({ theme }) => theme.shadows.dropdown};
  z-index: ${Z_INDEX.buttons.pickers.popover};
  display: flex;
  gap: 1rem;
  padding: 1rem;
`;

const TimeList = styled.div`
  max-height: 300px;
  overflow-y: auto;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background.disabled};
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.interactive.primary};
    border-radius: 3px;
  }
`;

const TimeOption = styled.button<{ $isSelected?: boolean }>`
  padding: 0.5rem;
  border: none;
  background: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.interactive.primary : "transparent"};
  color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.text.onPrimary : theme.colors.text.primary};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.button};

  &:hover {
    background: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.colors.interactive.primaryHover
        : theme.colors.interactive.hover};
  }
`;

export const CleeckyKitDateTimePicker = React.forwardRef<
  HTMLInputElement,
  DateTimePickerProps
>(
  (
    {
      value = null,
      onChange,
      minDate,
      maxDate,
      minTime,
      maxTime,
      timeStep = 30,
      timeFormat = "24h",
      clearable = true,
      ...rest
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [selectedDate, setSelectedDate] = useState<Date | null>(
      value || null
    );
    const [selectedTime, setSelectedTime] = useState<string>("");

    useCleeckyKitOutsideClick(containerRef, () => setIsOpen(false));

    const formatDateTime = (date: Date | null): string => {
      if (!date) return "";
      return date.toLocaleString("pt-BR");
    };

    const generateTimeOptions = () => {
      const options: string[] = [];
      const minutes = 24 * 60;
      for (let i = 0; i < minutes; i += timeStep) {
        const hours = Math.floor(i / 60);
        const mins = i % 60;
        options.push(
          `${hours.toString().padStart(2, "0")}:${mins
            .toString()
            .padStart(2, "0")}`
        );
      }
      return options;
    };

    const handleDateSelect = (date: Dayjs) => {
      const newDate = date.toDate();
      setSelectedDate(newDate);
      if (selectedTime) {
        const [hours, minutes] = selectedTime.split(":").map(Number);
        newDate.setHours(hours, minutes);
        onChange?.(newDate);
      }
    };

    const handleTimeSelect = (time: string) => {
      setSelectedTime(time);
      if (selectedDate) {
        const [hours, minutes] = time.split(":").map(Number);
        const newDate = new Date(selectedDate);
        newDate.setHours(hours, minutes);
        onChange?.(newDate);
      }
    };

    const handleClear = (e: React.MouseEvent) => {
      e.stopPropagation();
      setSelectedDate(null);
      setSelectedTime("");
      onChange?.(null);
    };

    return (
      <PopoverContainer ref={containerRef}>
        <CleeckyKitBaseInput
          ref={ref}
          type="text"
          readOnly
          value={formatDateTime(value)}
          prefix={<FontAwesomeIcon icon={faCalendar} />}
          suffix={
            clearable && value ? (
              <CleeckyKitButton
                variant="icon"
                size="small"
                icon="×"
                onClick={handleClear}
                aria-label="Limpar data e hora"
              />
            ) : undefined
          }
          onClick={() => setIsOpen(true)}
          {...rest}
        />
        <AnimatePresence>
          {isOpen && (
            <Popover
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}>
              <CleeckyKitCalendar
                selectedDate={selectedDate ? dayjs(selectedDate) : null}
                onChange={handleDateSelect}
                minDate={minDate ? dayjs(minDate) : undefined}
                maxDate={maxDate ? dayjs(maxDate) : undefined}
              />
              <TimeList>
                {generateTimeOptions().map((time) => (
                  <TimeOption
                    key={time}
                    $isSelected={selectedTime === time}
                    onClick={() => handleTimeSelect(time)}>
                    {time}
                  </TimeOption>
                ))}
              </TimeList>
            </Popover>
          )}
        </AnimatePresence>
      </PopoverContainer>
    );
  }
);

CleeckyKitDateTimePicker.displayName = "CleeckyKitDateTimePicker";
