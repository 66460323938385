import React from "react";
import styled, { keyframes, css } from "styled-components";
import * as CleeckyKit from "CleeckyKit";

interface CleeckyKitSkeletonProps {
  width?: string;
  height?: string;
  borderRadius?: string;
  margin?: string;
  backgroundColor?: string;
  highlightColor?: string;
  animation?: "pulse" | "wave" | "none";
  className?: string;
}

const pulseKeyframes = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

const waveKeyframes = keyframes`
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const pulseAnimation = css`
  animation: ${pulseKeyframes} 1.5s ease-in-out infinite;
`;

const getWaveAnimation = (highlightColor: string) => css`
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      90deg,
      transparent,
      ${highlightColor},
      transparent
    );
    animation: ${waveKeyframes} 1.5s linear infinite;
  }
`;

const StyledDiv = styled.div<{ $animation?: string; $highlightColor?: string }>`
  width: var(--skeleton-width);
  height: var(--skeleton-height);
  border-radius: var(--skeleton-border-radius);
  margin: var(--skeleton-margin);
  background-color: var(--skeleton-background);
  position: relative;
  overflow: hidden;

  ${(props) => props.$animation === "pulse" && pulseAnimation}
  ${(props) =>
    props.$animation === "wave" &&
    getWaveAnimation(props.$highlightColor || "rgba(255, 255, 255, 0.3)")}
`;

const SkeletonBase: React.FC<{
  className?: string;
  style?: React.CSSProperties;
  animation?: "pulse" | "wave" | "none";
  highlightColor?: string;
}> = ({ className, style, animation, highlightColor }) => (
  <StyledDiv
    className={className}
    style={style}
    $animation={animation}
    $highlightColor={highlightColor}
  />
);

export const CleeckyKitSkeleton: React.FC<CleeckyKitSkeletonProps> = ({
  width,
  height,
  borderRadius,
  margin,
  backgroundColor,
  highlightColor,
  animation = "wave",
  className,
}) => {
  const { currentTheme } = CleeckyKit.useCleeckyKitCurrentTheme();

  const defaultBackgroundColor =
    currentTheme?.colors.background.content || "#e0e0e0";
  const defaultHighlightColor = "rgba(255, 255, 255, 0.3)";

  const style = {
    "--skeleton-width": width || "100%",
    "--skeleton-height": height || "1.2em",
    "--skeleton-border-radius": borderRadius || "4px",
    "--skeleton-margin": margin || "0",
    "--skeleton-background": backgroundColor || defaultBackgroundColor,
    "--skeleton-highlight": highlightColor || defaultHighlightColor,
  } as React.CSSProperties;

  return (
    <SkeletonBase
      className={className}
      style={style}
      animation={animation}
      highlightColor={highlightColor || defaultHighlightColor}
    />
  );
};
