import React, { useState } from "react";
import * as CleeckyKit from "../../CleeckyKit/components";
import { useTranslation } from "react-i18next";

interface NotificationSettings {
  notifyAppUpdates: boolean;
  notifyNewApps: boolean;
  closedTestInvitation: boolean;
  interestedApps: string[];
}

interface NotificationSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (settings: {
    notifyAppUpdates: boolean;
    notifyNewApps: boolean;
    closedTestInvitation: boolean;
    interestedApps: string[] | null;
  }) => void;
  email?: string;
}

export const NotificationSettingsModal: React.FC<
  NotificationSettingsModalProps
> = ({ isOpen, onClose, onSubmit, email }) => {
  const { t } = useTranslation();
  const [settings, setSettings] = useState<NotificationSettings>({
    notifyAppUpdates: true,
    notifyNewApps: true,
    closedTestInvitation: true,
    interestedApps: [],
  });

  const handleSubmit = () => {
    onSubmit({
      ...settings,
      interestedApps:
        settings.interestedApps.length > 0 ? settings.interestedApps : null,
    });
    onClose();
  };

  return (
    <CleeckyKit.Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("project:common.cleeckys_insiders.notification_settings")}>
      <CleeckyKit.Column gap="1rem" padding="1rem">
        {email && (
          <CleeckyKit.Text>
            {t("project:common.cleeckys_insiders.notification_email")}: {email}
          </CleeckyKit.Text>
        )}

        <CleeckyKit.Column gap="1rem">
          <CleeckyKit.Switch
            checked={settings.notifyAppUpdates}
            onChange={(checked) =>
              setSettings((prev) => ({ ...prev, notifyAppUpdates: checked }))
            }
            label={t("project:common.cleeckys_insiders.notify_app_updates")}
          />

          <CleeckyKit.Switch
            checked={settings.notifyNewApps}
            onChange={(checked) =>
              setSettings((prev) => ({ ...prev, notifyNewApps: checked }))
            }
            label={t("project:common.cleeckys_insiders.notify_new_apps")}
          />

          <CleeckyKit.Switch
            checked={settings.closedTestInvitation}
            onChange={(checked) =>
              setSettings((prev) => ({
                ...prev,
                closedTestInvitation: checked,
              }))
            }
            label={t("project:common.cleeckys_insiders.closed_test_invitation")}
          />
        </CleeckyKit.Column>

        <CleeckyKit.Column gap="0.5rem">
          <CleeckyKit.Text>
            {t("project:common.cleeckys_insiders.interested_apps")}:
          </CleeckyKit.Text>
          <CleeckyKit.Row gap="1rem">
            <CleeckyKit.Checkbox
              checked={settings.interestedApps.includes("Toolbox")}
              onChange={(checked) =>
                setSettings((prev) => ({
                  ...prev,
                  interestedApps: checked
                    ? [...prev.interestedApps, "Toolbox"]
                    : prev.interestedApps.filter((app) => app !== "Toolbox"),
                }))
              }
              label="Toolbox"
            />
            <CleeckyKit.Checkbox
              checked={settings.interestedApps.includes("Services")}
              onChange={(checked) =>
                setSettings((prev) => ({
                  ...prev,
                  interestedApps: checked
                    ? [...prev.interestedApps, "Services"]
                    : prev.interestedApps.filter((app) => app !== "Services"),
                }))
              }
              label="Services"
            />
          </CleeckyKit.Row>
        </CleeckyKit.Column>

        <CleeckyKit.Row justify="end" gap="1rem">
          <CleeckyKit.Button
            text={t("cleeckykit:common.actions.cancel")}
            variant="outline"
            onClick={onClose}
          />
          <CleeckyKit.Button
            text={t("cleeckykit:common.actions.save")}
            variant="solid"
            onClick={handleSubmit}
          />
        </CleeckyKit.Row>
      </CleeckyKit.Column>
    </CleeckyKit.Modal>
  );
};
