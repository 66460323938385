// Importando as dependências
import React, { useState, ReactNode } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { Z_INDEX } from "CleeckyKit/themes/zindex";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ReactDOM from "react-dom";

interface SelectButtonProps {
  $isOpen: boolean;
}

interface Option {
  label: string;
  value: string;
  icon?: ReactNode;
}

interface CleeckyKitSelectProps {
  options: Option[];
  placeholder?: string;
  icon?: ReactNode;
  multiple?: boolean;
  value?: string | string[];
  onChange?: (value: string | string[]) => void;
}

const SelectButton = styled.button<SelectButtonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.background.dropdown};
  cursor: pointer;
  font-size: 16px;
  position: relative;
  gap: 5px;

  &:hover {
    background: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.border.primary};
  }

  .icon {
    margin-right: 8px;
  }

  .arrow {
    transition: transform 0.3s ease;
    transform: rotate(${(props) => (props.$isOpen ? "0deg" : "-90deg")});
  }
`;

const OptionsMenu = styled(motion.ul)`
  position: fixed;
  background: ${({ theme }) => theme.colors.background.dropdown};
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: ${Z_INDEX.buttons.dropdown.menu};
  overflow: hidden;
`;

const Option = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.primary};

  &:hover {
    background: ${({ theme }) =>
      `${theme.gradient?.highlightColor || theme.colors.border.primary}50`};
  }

  .icon {
    margin-right: 8px;
  }

  &.selected {
    background: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.border.primary};
  }
`;

export const CleeckyKitSelect: React.FC<CleeckyKitSelectProps> = ({
  options = [],
  placeholder = t(
    "cleeckykit:common.interface.elements.select.default.placeholder"
  ),
  icon: ButtonIcon,
  multiple = false,
  value,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<
    string[] | string | null
  >(value ?? (multiple ? [] : null));
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [menuPosition, setMenuPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });

  // Atualiza o estado interno quando o valor da prop muda
  React.useEffect(() => {
    if (value !== undefined) {
      setSelectedValues(value);
    }
  }, [value]);

  const updateMenuPosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setMenuPosition({
        top: rect.bottom + 5,
        left: rect.left,
        width: rect.width,
      });
    }
  };

  const toggleMenu = () => {
    if (!isOpen) {
      updateMenuPosition();
    }
    setIsOpen((prev) => !prev);
  };

  // Atualiza a posição do menu quando a janela é redimensionada
  React.useEffect(() => {
    if (isOpen) {
      const handleResize = () => updateMenuPosition();
      window.addEventListener("resize", handleResize);
      window.addEventListener("scroll", handleResize, true);
      return () => {
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("scroll", handleResize, true);
      };
    }
  }, [isOpen]);

  const handleOptionClick = (value: string) => {
    if (multiple) {
      setSelectedValues((prev) => {
        const prevArray = prev as string[];
        const isSelected = prevArray.includes(value);
        const newValues = isSelected
          ? prevArray.filter((v) => v !== value)
          : [...prevArray, value];
        onChange?.(newValues);
        return newValues;
      });
    } else {
      setSelectedValues(value);
      onChange?.(value);
      setIsOpen(false);
    }
  };

  const isSelected = (value: string) => {
    return multiple
      ? (selectedValues as string[])?.includes(value)
      : selectedValues === value;
  };

  return (
    <div style={{ position: "relative" }}>
      <SelectButton onClick={toggleMenu} $isOpen={isOpen} ref={buttonRef}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {ButtonIcon && <span className="icon">{ButtonIcon}</span>}
          <span>
            {multiple
              ? (selectedValues as string[])?.length > 0
                ? `${(selectedValues as string[]).length} selected`
                : placeholder
              : options.find((o) => o.value === selectedValues)?.label ||
                placeholder}
          </span>
        </div>
        <span className="arrow">
          <FontAwesomeIcon icon={faChevronDown} />
        </span>
      </SelectButton>

      {isOpen &&
        ReactDOM.createPortal(
          <AnimatePresence>
            <OptionsMenu
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 0.2 }}
              style={{
                top: menuPosition.top,
                left: menuPosition.left,
                width: menuPosition.width,
              }}>
              {options.map(({ label, value, icon: OptionIcon }) => (
                <Option
                  key={value}
                  className={isSelected(value) ? "selected" : ""}
                  onClick={() => handleOptionClick(value)}>
                  {OptionIcon && <span className="icon">{OptionIcon}</span>}
                  {label}
                </Option>
              ))}
            </OptionsMenu>
          </AnimatePresence>,
          document.body
        )}
    </div>
  );
};
