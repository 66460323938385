import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  CleeckyKitBaseInput,
  BaseInputProps,
  InputValidations,
} from "./CleeckyKitBaseInput";
import { useTranslation } from "react-i18next";

interface ConfirmPasswordInputProps extends Omit<BaseInputProps, "type"> {
  showToggleButton?: boolean;
  compareWith?: string;
  value?: string;
}

const ToggleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.secondary};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.interactive.primary};
  }
`;

export const CleeckyKitConfirmPasswordInput = React.forwardRef<
  HTMLInputElement,
  ConfirmPasswordInputProps
>((props, ref) => {
  const { t } = useTranslation();
  const {
    showToggleButton = true,
    compareWith,
    onChange,
    error,
    value,
    label = t("cleeckykit:common.form.confirmPassword.label"),
    placeholder = t("cleeckykit:common.form.confirmPassword.placeholder"),
    validations,
    ...rest
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const defaultValidations: InputValidations = {
    required: true,
    requiredText: t("cleeckykit:common.form.confirmPassword.required"),
    invalidText: t("cleeckykit:common.form.confirmPassword.invalid"),
    validate: (value: string) => {
      if (compareWith && value !== compareWith) {
        return t("cleeckykit:common.form.confirmPassword.invalid");
      }
      return true;
    },
  };

  return (
    <CleeckyKitBaseInput
      ref={ref}
      type={showPassword ? "text" : "password"}
      prefix={<FontAwesomeIcon icon={faLock} />}
      suffix={
        showToggleButton && (
          <ToggleButton
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            aria-label={
              showPassword
                ? t("cleeckykit:common.form.password.hide")
                : t("cleeckykit:common.form.password.show")
            }>
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </ToggleButton>
        )
      }
      error={error}
      onChange={onChange}
      value={value}
      label={label}
      placeholder={placeholder}
      validations={{ ...defaultValidations, ...validations }}
      {...rest}
    />
  );
});

CleeckyKitConfirmPasswordInput.displayName = "CleeckyKitConfirmPasswordInput";
