import React, { useState, useEffect } from "react";
import * as CleeckyKit from "CleeckyKit";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowPointer,
  faBan,
  faFileLines,
} from "@fortawesome/free-solid-svg-icons";
import { aggregatorService } from "services";
import { AggregatorPageData } from "cleecky@types";
import { useNavigate } from "react-router-dom";

export const RecentPages: React.FC = () => {
  const { currentTheme } = CleeckyKit.useCleeckyKitCurrentTheme();
  const navigate = useNavigate();
  const [pages, setPages] = useState<AggregatorPageData[]>([]);
  const [previews, setPreviews] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await aggregatorService.getAll();
        // Pega apenas as 4 páginas mais recentes
        const recentPages = response.slice(0, 4);
        setPages(recentPages);

        // Busca os previews para cada página
        const previewPromises = recentPages.map(async (page) => {
          try {
            // Usa a URL da própria página do agregador para gerar o preview
            const pageUrl = `${window.location.origin}/@${page.user.username}/${page.slug}`;
            const preview = await aggregatorService.getPagePreview(pageUrl);
            return { uuid: page.uuid, preview };
          } catch (error) {
            console.error(
              `Erro ao buscar preview para página ${page.uuid}:`,
              error
            );
            return null;
          }
        });

        const previewResults = await Promise.all(previewPromises);
        const previewMap = previewResults.reduce((acc, result) => {
          if (result) {
            acc[result.uuid] = result.preview;
          }
          return acc;
        }, {} as { [key: string]: string });

        setPreviews(previewMap);
      } catch (error) {
        console.error("Erro ao buscar páginas:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPages();
  }, []);

  const handleCreatePage = () => {
    navigate("/toolbox/link-aggregator/pages/create");
  };

  const handleViewAllPages = () => {
    navigate("/toolbox/link-aggregator/pages");
  };

  const getPageStats = (page: AggregatorPageData) => {
    // Aqui você pode implementar a lógica para contar os cliques
    // Por enquanto, retornamos 0
    return 0;
  };

  if (!currentTheme) {
    return null;
  }

  return (
    <CleeckyKit.Section>
      <CleeckyKit.Column gap="1rem">
        <CleeckyKit.Row>
          <CleeckyKit.Column>
            <CleeckyKit.Title level={3}>
              {t(
                "project:apps.toolbox.tools.link_aggregator.dashboard.recent_pages.title"
              )}
            </CleeckyKit.Title>
          </CleeckyKit.Column>
        </CleeckyKit.Row>

        <CleeckyKit.Carousel
          itemsPerView={{
            default: 4,
            sm: 2,
          }}
          navigation={{
            show: true,
            position: "outside-top-right",
            navigationBackground: "none",
          }}
          pagination={{ show: false }}>
          {/* Recent Pages */}
          {pages.map((page) => (
            <CleeckyKit.Card
              key={page.uuid}
              border={`2px solid ${currentTheme?.colors.background.card}`}
              backgroundColor={currentTheme?.colors.background.base}
              padding="0"
              hoverable
              onClick={() => navigate(`/@${page.user.username}/${page.slug}`)}>
              <div style={{ minHeight: "215px" }}>
                <div
                  style={{
                    backgroundColor: currentTheme?.colors.background.content,
                    height: "10rem",
                    color: currentTheme?.colors.text.primary,
                    overflow: "hidden",
                  }}>
                  {previews[page.uuid] ? (
                    <CleeckyKit.Image
                      alt={`Preview of ${page.slug}`}
                      width="100%"
                      height="100%"
                      fit="cover"
                      src={previews[page.uuid]}
                      sourceType="external"
                      href={`/@${page.user.username}/${page.slug}`}
                      target="_blank"
                    />
                  ) : (
                    <CleeckyKit.Skeleton
                      width="100%"
                      height="100%"
                      animation="wave"
                      backgroundColor={currentTheme?.colors.background.content}
                      highlightColor={currentTheme?.colors.interactive.hover}
                    />
                  )}
                </div>
                <CleeckyKit.Column padding="1rem" gap="0.5rem">
                  <CleeckyKit.Title level={5}>{page.slug}</CleeckyKit.Title>
                  <CleeckyKit.Text>
                    <FontAwesomeIcon icon={faArrowPointer} />
                    {"  "} {getPageStats(page)} (
                    {t(
                      "project:apps.toolbox.tools.link_aggregator.dashboard.recent_pages.current_month"
                    )}
                    )
                  </CleeckyKit.Text>
                </CleeckyKit.Column>
              </div>
            </CleeckyKit.Card>
          ))}

          {pages.length === 0 && (
            <CleeckyKit.Card
              backgroundColor="transparent"
              border={`2px solid ${currentTheme?.colors.background.card}`}>
              <div style={{ minHeight: "215px" }}>
                <CleeckyKit.Column>
                  <CleeckyKit.Row justify="center" align="center">
                    <CleeckyKit.Title align="center" level={4}>
                      <FontAwesomeIcon icon={faBan} />
                    </CleeckyKit.Title>
                  </CleeckyKit.Row>
                  <CleeckyKit.Row justify="center" align="center">
                    <CleeckyKit.Title align="center" level={5}>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.recent_pages.empty_state"
                      )}
                    </CleeckyKit.Title>
                  </CleeckyKit.Row>
                </CleeckyKit.Column>
              </div>
            </CleeckyKit.Card>
          )}

          {/* Create Page */}
          <CleeckyKit.Card
            backgroundColor={currentTheme?.colors.background.card}
            onClick={handleCreatePage}>
            <div style={{ minHeight: "215px" }}>
              <CleeckyKit.Row justify="center" align="center">
                <CleeckyKit.Column justify="center" align="center" gap="1rem">
                  <CleeckyKit.Title align="right" level={3}>
                    <FontAwesomeIcon icon={faFileLines} />
                  </CleeckyKit.Title>

                  <CleeckyKit.Title align="center" level={5}>
                    {t(
                      "project:apps.toolbox.tools.link_aggregator.dashboard.recent_pages.create_a_new_page"
                    )}
                  </CleeckyKit.Title>
                  <CleeckyKit.Button
                    size="medium"
                    text={t("cleeckykit:common.actions.create")}
                    textPosition="inside-below"
                    borderRadius={15}
                    type="button"
                    onClick={handleCreatePage}
                    variant="solid"
                    highlighted
                  />
                </CleeckyKit.Column>
              </CleeckyKit.Row>
            </div>
          </CleeckyKit.Card>
        </CleeckyKit.Carousel>
        <CleeckyKit.Row justify="center" align="center">
          <CleeckyKit.Button
            size="medium"
            variant="outline"
            text={t(
              "project:apps.toolbox.tools.link_aggregator.dashboard.recent_pages.see_all_pages"
            )}
            textPosition="inside-below"
            borderRadius={15}
            type="button"
            onClick={handleViewAllPages}
            highlighted
          />
        </CleeckyKit.Row>
      </CleeckyKit.Column>
    </CleeckyKit.Section>
  );
};
