import React from "react";
import * as CleeckyKit from "CleeckyKit";
import { QuickSummaryCards } from "./components/QuickSummaryCards";
import { RecentPages } from "./components/RecentPages";
import { DetailedMetrics } from "./components/DetailedMetrics";

const AggregatorDashboard: React.FC = () => {
  return (
    <>
      <CleeckyKit.Column gap="2rem">
        {/* Quick Summary */}
        <QuickSummaryCards />

        {/* Recent Pages */}
        <RecentPages />

        {/* Detailed Metrics */}
        <DetailedMetrics />
      </CleeckyKit.Column>
    </>
  );
};

export default AggregatorDashboard;
