import React, { useState, useEffect } from "react";
import * as CleeckyKit from "CleeckyKit";
import { CanvasElement } from "CleeckyKit/components/Canvas/CleeckyKitCanvas";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { authService } from "services/authService";
import type { ContentData, AggregatorPageData } from "cleecky@types";

const AggregatorCreatePage: React.FC = () => {
  const [elements, setElements] = useState<CanvasElement[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const userData = await authService.getCurrentUser();
        if (userData) {
          // Criar elementos iniciais com os dados do usuário
          const initialElements: CanvasElement[] = [
            {
              id: `avatar_${Date.now()}`,
              type: "AVATAR",
              order: 0,
              show: true,
              content: {
                file: {
                  path: userData.avatar,
                  type: "EXTERNAL",
                },
                order: 0,
                show: true,
              },
            },
            {
              id: `name_${Date.now()}`,
              type: "NAME",
              order: 0,
              show: true,
              content: {
                text: `${userData.firstName} ${userData.lastName}`,
                order: 0,
                show: true,
                alignment: "CENTER",
              },
            },
            {
              id: `username_${Date.now()}`,
              type: "USERNAME",
              order: 1,
              show: true,
              content: {
                text: userData.username,
                order: 1,
                show: true,
                alignment: "CENTER",
              },
            },
          ];
          setElements(initialElements);
        }
      } catch (error) {
        console.error("Erro ao carregar dados do usuário:", error);
      }
    };

    loadUserData();
  }, []);

  const handleElementsChange = (newElements: CanvasElement[]) => {
    setElements(newElements);
  };

  const handleSave = () => {
    const pageData: Partial<AggregatorPageData> = {
      contents: elements.map((element) => ({
        uuid: element.id,
        type: element.type,
        data: element.content as ContentData,
        order: element.content.order,
        show: element.content.show,
      })),
      visibility: "UNLISTED",
      borderRadius: "8px",
      borderSize: "1px",
    };

    console.log("Salvando página do agregador:", pageData);
  };

  return (
    <CleeckyKit.Column>
      <CleeckyKit.Row justify="between" noBreak>
        <CleeckyKit.Button
          text={t("cleeckykit:common.actions.back")}
          textPosition="inside-right"
          variant="solid"
          size="small"
          onClick={() => navigate(-1)}
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
        />
        <CleeckyKit.Button
          text={t("cleeckykit:common.actions.save")}
          textPosition="inside-right"
          variant="solid"
          size="small"
          onClick={handleSave}
          icon={<FontAwesomeIcon icon={faSave} />}
          highlighted
        />
      </CleeckyKit.Row>
      <CleeckyKit.Row>
        <CleeckyKit.Title align="left" level={3}>
          {t("project:apps.toolbox.tools.link_aggregator.create_page.title")}
        </CleeckyKit.Title>
      </CleeckyKit.Row>
      <CleeckyKit.Canvas
        elements={elements}
        onElementsChange={handleElementsChange}
      />
    </CleeckyKit.Column>
  );
};

export default AggregatorCreatePage;
