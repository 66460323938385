import React from "react";
import styled from "styled-components";
import * as CleeckyKit from "CleeckyKit";
interface BaseLayoutProps {
  children: React.ReactNode;
  hasNavbar?: boolean;
  hasSidebar?: boolean;
  sidebarPosition?: "left" | "right";
  sidebarWidth?: string;
  navbarHeight?: string;
  className?: string;
}

const LayoutContainer = styled.main<{
  $hasNavbar: boolean;
  $hasSidebar: boolean;
  $sidebarPosition: "left" | "right";
  $sidebarWidth: string;
  $navbarHeight: string;
}>`
  position: relative;
  min-height: ${({ $hasNavbar, $navbarHeight }) =>
    $hasNavbar ? `calc(100dvh - ${$navbarHeight})` : "100dvh"};
  margin-top: ${({ $hasNavbar, $navbarHeight }) =>
    $hasNavbar ? $navbarHeight : "0"};
  padding-left: ${({ $hasSidebar, $sidebarPosition }) =>
    $hasSidebar && $sidebarPosition === "left" ? "80px" : "0"};
  padding-right: ${({ $hasSidebar, $sidebarPosition }) =>
    $hasSidebar && $sidebarPosition === "right" ? "80px" : "0"};
  width: 100%;
  transition: all 0.3s ease;

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const CleeckyKitBaseLayout: React.FC<BaseLayoutProps> = ({
  children,
  hasNavbar = false,
  hasSidebar = false,
  sidebarPosition = "left",
  sidebarWidth = "240px",
  navbarHeight = "4rem",
  className,
}) => {
  CleeckyKit.useCleeckyKitScrollToTop();

  return (
    <LayoutContainer
      $hasNavbar={hasNavbar}
      $hasSidebar={hasSidebar}
      $sidebarPosition={sidebarPosition}
      $sidebarWidth={sidebarWidth}
      $navbarHeight={navbarHeight}
      className={className}>
      {children}
    </LayoutContainer>
  );
};
