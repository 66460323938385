import React from "react";
import styled from "styled-components";
import { CleeckyKitListItem, ListItemProps } from "./CleeckyKitListItem";

type OrderType = "number" | "letter" | "none";

interface ListProps {
  children: React.ReactNode;
  gap?: string;
  orderType?: OrderType;
  startOrder?: number | string;
  background?: string;
  border?: string;
  borderRadius?: string;
  showDivider?: boolean;
  dividerColor?: string;
  className?: string;
  hoverable?: boolean;
}

interface ListContainerProps {
  $gap?: string;
  $background?: string;
  $border?: string;
  $borderRadius?: string;
  $showDivider?: boolean;
  $dividerColor?: string;
}

const ListContainer = styled.div<ListContainerProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ $gap }) => $gap || "0.5rem"};
  width: 100%;
  background: ${({ $background, theme }) => $background || "transparent"};
  border: ${({ $border }) => $border || "none"};
  border-radius: ${({ $borderRadius }) => $borderRadius || "0"};
  overflow: hidden;

  ${({ $showDivider, $dividerColor, theme }) =>
    $showDivider &&
    `
    > *:not(:last-child) {
      border-bottom: 1px solid ${$dividerColor || theme.colors.border.default};
    }
  `}
`;

const getOrderLabel = (
  type: OrderType,
  index: number,
  startOrder: number | string
) => {
  if (type === "none") return "";
  if (type === "number") return String(Number(startOrder) + index);
  if (type === "letter") {
    const startChar =
      typeof startOrder === "string" ? startOrder.charCodeAt(0) : 97;
    return String.fromCharCode(startChar + index);
  }
  return "";
};

export const CleeckyKitList: React.FC<ListProps> = ({
  children,
  gap,
  orderType = "none",
  startOrder = orderType === "number" ? 1 : "a",
  background,
  border,
  borderRadius,
  showDivider = false,
  dividerColor,
  className,
  hoverable = true,
}) => {
  const childrenWithOrder = React.Children.map(children, (child, index) => {
    if (!React.isValidElement(child)) return child;

    // Verifica se o componente é um CleeckyKitListItem
    if (child.type === CleeckyKitListItem) {
      const props: Partial<ListItemProps> = {
        orderLabel: getOrderLabel(orderType, index, startOrder),
        borderRadius,
        hoverable,
      };
      return React.cloneElement(child, props);
    }

    return child;
  });

  return (
    <ListContainer
      className={className}
      $gap={gap}
      $background={background}
      $border={border}
      $borderRadius={borderRadius}
      $showDivider={showDivider}
      $dividerColor={dividerColor}>
      {childrenWithOrder}
    </ListContainer>
  );
};
