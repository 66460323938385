import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faLink,
  faCog,
  faArrowLeft,
  faArrowPointer,
  faBan,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import * as CleeckyKit from "CleeckyKit";
import styled from "styled-components";
import { t } from "i18next";
import { aggregatorService } from "services";
import { AggregatorPageData } from "cleecky@types";

const PageStats = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.875rem;
`;

const StatusTag = styled.span<{ $status: "PUBLIC" | "PRIVATE" | "UNLISTED" }>`
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  background: ${({ theme, $status }) =>
    $status === "PUBLIC"
      ? theme.colors.status.success
      : $status === "PRIVATE"
      ? theme.colors.status.error
      : theme.colors.status.warning};
  color: ${({ theme }) => theme.colors.text.onPrimary};
`;

const AggregatorPageList: React.FC = () => {
  const navigate = useNavigate();
  const [pages, setPages] = useState<AggregatorPageData[]>([]);
  const [previews, setPreviews] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(true);
  const { currentTheme } = CleeckyKit.useCleeckyKitCurrentTheme();

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await aggregatorService.getAll();
        setPages(response);

        // Busca os previews para cada página
        const previewPromises = response.map(async (page) => {
          try {
            const pageUrl = `${window.location.origin}/@${page.user.username}/${page.slug}`;
            const preview = await aggregatorService.getPagePreview(pageUrl);
            return { uuid: page.uuid, preview };
          } catch (error) {
            console.error(
              `Erro ao buscar preview para página ${page.uuid}:`,
              error
            );
            return null;
          }
        });

        const previewResults = await Promise.all(previewPromises);
        const previewMap = previewResults.reduce((acc, result) => {
          if (result) {
            acc[result.uuid] = result.preview;
          }
          return acc;
        }, {} as { [key: string]: string });

        setPreviews(previewMap);
      } catch (error) {
        console.error("Erro ao buscar páginas:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPages();
  }, []);

  const getPageStats = (page: AggregatorPageData) => {
    // Aqui você pode implementar a lógica para contar os cliques
    // Por enquanto, retornamos 0
    return 0;
  };

  if (!currentTheme) {
    return null;
  }

  return (
    <CleeckyKit.Column gap="1rem">
      <CleeckyKit.Row justify="between" noBreak>
        <CleeckyKit.Button
          text={t("cleeckykit:common.actions.back")}
          textPosition="inside-right"
          variant="solid"
          size="small"
          onClick={() => navigate(-1)}
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
        />
        <CleeckyKit.Button
          icon={<FontAwesomeIcon icon={faPlus} />}
          type="button"
          text={t(
            "project:apps.toolbox.tools.link_aggregator.pageList.create_new_page"
          )}
          textPosition="inside-right"
          variant="solid"
          size="small"
          onClick={() => navigate("/toolbox/link-aggregator/pages/create")}
          highlighted
        />
      </CleeckyKit.Row>

      <CleeckyKit.Row justify="between">
        <CleeckyKit.Title level={2}>
          {t("project:apps.toolbox.tools.link_aggregator.pageList.title")}
        </CleeckyKit.Title>
      </CleeckyKit.Row>

      <CleeckyKit.Row>
        <CleeckyKit.List hoverable={false}>
          {loading ? (
            <CleeckyKit.Skeleton
              width="100%"
              height="10rem"
              animation="wave"
              backgroundColor={currentTheme?.colors.background.content}
              highlightColor={currentTheme?.colors.interactive.hover}
            />
          ) : pages.length === 0 ? (
            <CleeckyKit.Card
              backgroundColor="transparent"
              border={`2px solid ${currentTheme?.colors.background.card}`}>
              <CleeckyKit.Column>
                <CleeckyKit.Row justify="center" align="center">
                  <CleeckyKit.Title align="center" level={4}>
                    <FontAwesomeIcon icon={faBan} />
                  </CleeckyKit.Title>
                </CleeckyKit.Row>
                <CleeckyKit.Row justify="center" align="center">
                  <CleeckyKit.Title align="center" level={5}>
                    {t(
                      "project:apps.toolbox.tools.link_aggregator.pageList.empty_state"
                    )}
                  </CleeckyKit.Title>
                </CleeckyKit.Row>
              </CleeckyKit.Column>
            </CleeckyKit.Card>
          ) : (
            pages.map((page) => (
              <CleeckyKit.ListItem key={page.uuid}>
                <CleeckyKit.Row align="center" gap="1rem" height="100%">
                  {previews[page.uuid] ? (
                    <div style={{ borderRadius: "2rem", overflow: "hidden" }}>
                      <CleeckyKit.Image
                        src={previews[page.uuid]}
                        alt={`Preview of ${page.slug}`}
                        width="100%"
                        height="100%"
                        fit="contain"
                        sourceType="external"
                        href={`${window.location.origin}/@${page.user.username}/${page.slug}`}
                        target="_blank"
                      />
                    </div>
                  ) : (
                    <CleeckyKit.Skeleton
                      width="100%"
                      height="100%"
                      animation="wave"
                      backgroundColor={currentTheme?.colors.background.content}
                      highlightColor={currentTheme?.colors.interactive.hover}
                    />
                  )}

                  <CleeckyKit.Column justify="between" gap="1rem">
                    <CleeckyKit.Section>
                      <CleeckyKit.Title level={4}>{page.slug}</CleeckyKit.Title>
                      <CleeckyKit.Text
                        href={`${window.location.origin}/@${page.user.username}/${page.slug}`}
                        target="_blank"
                        customStyle={{
                          fontSize: "0.875rem",
                        }}>
                        {`@${page.user.username}/${page.slug}`}
                      </CleeckyKit.Text>
                    </CleeckyKit.Section>
                    <CleeckyKit.Section>
                      <CleeckyKit.Row justify="between" noBreak>
                        <StatusTag $status={page.visibility}>
                          {t(
                            `project:apps.toolbox.tools.link_aggregator.pageList.status.${page.visibility.toLowerCase()}`
                          )}
                        </StatusTag>
                        <PageStats>
                          <FontAwesomeIcon icon={faArrowPointer} />{" "}
                          {getPageStats(page)}{" "}
                          {t(
                            "project:apps.toolbox.tools.link_aggregator.pageList.current_month"
                          )}
                        </PageStats>
                      </CleeckyKit.Row>
                    </CleeckyKit.Section>
                  </CleeckyKit.Column>

                  <CleeckyKit.Column justify="center">
                    <CleeckyKit.Row justify="center">
                      <CleeckyKit.Title level={6}>
                        {t("cleeckykit:common.actions.actions")}
                      </CleeckyKit.Title>
                    </CleeckyKit.Row>
                    <CleeckyKit.Row justify="center" noBreak>
                      <div
                        style={{
                          display: "flex",
                          gap: "0.5rem",
                          minWidth: "fit-content",
                          padding: "0.5rem",
                        }}>
                        <CleeckyKit.Button
                          size="small"
                          text={t("cleeckykit:common.actions.edit")}
                          textPosition="inside-right"
                          variant="solid"
                          type="button"
                          icon={<FontAwesomeIcon icon={faEdit} />}
                          onClick={() =>
                            navigate(
                              `/toolbox/aggregator/pages/${page.uuid}/edit`
                            )
                          }
                        />
                        <CleeckyKit.Button
                          size="small"
                          text={t("cleeckykit:common.actions.share")}
                          textPosition="inside-right"
                          variant="solid"
                          type="button"
                          icon={<FontAwesomeIcon icon={faLink} />}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${window.location.origin}/@${page.user.username}/${page.slug}`
                            );
                          }}
                        />
                        <CleeckyKit.Button
                          size="small"
                          text={t("cleeckykit:common.actions.manage")}
                          textPosition="inside-right"
                          variant="solid"
                          type="button"
                          icon={<FontAwesomeIcon icon={faCog} />}
                          onClick={() =>
                            navigate(
                              `/toolbox/aggregator/pages/${page.uuid}/settings`
                            )
                          }
                        />
                      </div>
                    </CleeckyKit.Row>
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
              </CleeckyKit.ListItem>
            ))
          )}
        </CleeckyKit.List>
      </CleeckyKit.Row>
    </CleeckyKit.Column>
  );
};

export default AggregatorPageList;
