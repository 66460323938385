import React, { useState, useEffect } from "react";
import { SignInModal } from "./SignInModal";
import { SignUpModal } from "./SignUpModal";
import * as CleeckyKit from "../../CleeckyKit/components";
import { useTranslation } from "react-i18next";
import mikeFrente from "../../assets/images/mike-frente.jpeg";
import mikePerfil from "../../assets/images/mike-perfil.jpeg";

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialMode?: "login" | "register";
}

export const AuthModal: React.FC<AuthModalProps> = ({
  isOpen,
  onClose,
  initialMode = "login",
}) => {
  const [isLogin, setIsLogin] = useState(initialMode === "login");
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setIsLogin(initialMode === "login");
    }
  }, [isOpen, initialMode]);

  const modalType = isLogin ? "login" : "register";

  return (
    <CleeckyKit.Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(`project:common.auth.modal.${modalType}.title`)}
      size="medium"
      animation="scale"
      sideImage={{
        src: isLogin ? mikeFrente : mikePerfil,
        alt: isLogin ? "Mike olhando para frente" : "Mike de perfil",
        position: "left",
        fit: "cover",
        animation: {
          initial: { scale: 0.95, opacity: 0 },
          animate: { scale: 1, opacity: 1 },
          transition: { duration: 0.3, delay: 0.2 },
        },
      }}>
      {isLogin ? (
        <SignInModal onToggleMode={() => setIsLogin(false)} onClose={onClose} />
      ) : (
        <SignUpModal onToggleMode={() => setIsLogin(true)} onClose={onClose} />
      )}
    </CleeckyKit.Modal>
  );
};
