import React, { forwardRef, ReactNode, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import {
  useFormContext,
  RegisterOptions,
  ValidationRule,
  ValidationValueMessage,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

export type InputSize = "small" | "medium" | "large";
export type InputVariant = "solid" | "outline" | "transparent";
export type ValidationVariant = "below" | "tooltip";

export interface InputValidations {
  required?: boolean;
  requiredText?: string;
  invalidText?: string;
  pattern?: RegExp;
  minLength?: number;
  maxLength?: number;
  validate?: (value: string) => true | string;
}

type BaseInputAttributes = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "size" | "prefix" | "suffix"
>;

export interface BaseInputProps extends BaseInputAttributes {
  id?: string;
  name: string;
  label?: string | "none";
  error?: string;
  disabled?: boolean;
  className?: string;
  size?: InputSize;
  variant?: InputVariant;
  validationVariant?: ValidationVariant;
  fullWidth?: boolean;
  helperText?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  animation?: "none" | "fade" | "slide";
  borderRadius?: string | number | boolean;
  validations?: InputValidations;
}

const InputWrapper = styled.div<{ $fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
  text-align: left;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 0.875rem;
  font-weight: 500;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    text-align: left;
  }
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const StyledInput = styled.input<{
  $hasError?: boolean;
  $size?: InputSize;
  $variant?: InputVariant;
  $borderRadius?: string | number | boolean;
  $hasPrefix?: boolean;
  $hasSuffix?: boolean;
}>`
  width: 100%;
  border: 1px solid
    ${({ theme, $hasError }) =>
      $hasError ? theme.colors.status.error : theme.colors.border.input};
  border-radius: ${({ $borderRadius }) => {
    if (typeof $borderRadius === "boolean") {
      return $borderRadius ? "8px" : "0";
    }
    return typeof $borderRadius === "number"
      ? `${$borderRadius}px`
      : $borderRadius || "8px";
  }};
  transition: all 0.2s ease;
  outline: none;
  font-family: inherit;

  /* Padding base considerando prefixo e sufixo */
  padding-left: ${({ $hasPrefix }) => ($hasPrefix ? "2.5rem" : "0.75rem")};
  padding-right: ${({ $hasSuffix }) => ($hasSuffix ? "2.5rem" : "0.75rem")};

  &:focus {
    border-color: ${({ theme }) => theme.colors.interactive.primary};
    box-shadow: 0 0 0 2px
      ${({ theme }) => theme.colors.interactive.primaryAlpha};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.background.disabled};
    cursor: not-allowed;
  }

  ${({ $size }) => {
    switch ($size) {
      case "small":
        return css`
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          font-size: 0.875rem;
        `;
      case "large":
        return css`
          padding-top: 1rem;
          padding-bottom: 1rem;
          font-size: 1.125rem;
        `;
      default:
        return css`
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          font-size: 1rem;
        `;
    }
  }}

  ${({ $variant, theme }) => {
    switch ($variant) {
      case "outline":
        return css`
          background: transparent;
          border: 1px solid ${theme.colors.border.input};
        `;
      case "transparent":
        return css`
          background: transparent;
          border: none;
        `;
      default:
        return css`
          background: ${theme.colors.background.input};
        `;
    }
  }}
`;

const Affix = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.secondary};
  transition: color 0.2s ease-in-out;
`;

const PrefixContainer = styled(Affix)`
  left: 0.75rem;
  pointer-events: none;

  ${InputContainer}:focus-within & {
    color: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.interactive.primary};
  }
`;

const SuffixContainer = styled(Affix)`
  right: 0.75rem;
  pointer-events: all;

  ${InputContainer}:focus-within & {
    color: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.interactive.primary};
  }
`;

const ErrorText = styled(motion.span)`
  color: ${({ theme }) => theme.colors.status.error};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

const HelperText = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

const Tooltip = styled(motion.div)`
  position: absolute;
  bottom: -2rem;
  left: 0;
  background: ${({ theme }) => theme.colors.status.error};
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  z-index: 1;
  white-space: nowrap;
  pointer-events: none;

  &:before {
    content: "";
    position: absolute;
    top: -4px;
    left: 10px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid ${({ theme }) => theme.colors.status.error};
  }
`;

export const CleeckyKitBaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
  (
    {
      id,
      name,
      label,
      error,
      disabled,
      className,
      size = "medium",
      variant = "solid",
      validationVariant = "below",
      fullWidth = true,
      helperText,
      prefix,
      suffix,
      animation = "fade",
      borderRadius,
      validations,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const formContext = useFormContext();
    const { register, formState } = formContext || {};
    const fieldError = formState?.errors?.[name]?.message as string;
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipTimer, setTooltipTimer] = useState<NodeJS.Timeout | null>(
      null
    );

    const tooltipVariants = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: -5 },
    };

    useEffect(() => {
      if (fieldError && validationVariant === "tooltip") {
        setShowTooltip(true);
        if (tooltipTimer) clearTimeout(tooltipTimer);

        const timer = setTimeout(() => {
          setShowTooltip(false);
        }, 5000);

        setTooltipTimer(timer);
      }

      return () => {
        if (tooltipTimer) clearTimeout(tooltipTimer);
      };
    }, [fieldError]);

    const handleInputInteraction = () => {
      if (validationVariant === "tooltip") {
        setShowTooltip(false);
        if (tooltipTimer) {
          clearTimeout(tooltipTimer);
          setTooltipTimer(null);
        }
      }
    };

    const getRegisterOptions = (): RegisterOptions => {
      const options: RegisterOptions = {};

      if (validations?.required) {
        const message =
          validations.requiredText ||
          t("cleeckykit:common.form.validations.required");
        options.required = {
          value: true,
          message,
        } as ValidationValueMessage<boolean>;
      }

      if (validations?.pattern) {
        const message =
          validations.invalidText ||
          t("cleeckykit:common.form.validations.invalidFormat");
        options.pattern = {
          value: validations.pattern,
          message,
        } as ValidationValueMessage<RegExp>;
      }

      if (validations?.minLength) {
        const message =
          validations.invalidText ||
          t("cleeckykit:common.form.validations.minLength", {
            min: validations.minLength,
          });
        options.minLength = {
          value: validations.minLength,
          message,
        } as ValidationValueMessage<number>;
      }

      if (validations?.maxLength) {
        const message =
          validations.invalidText ||
          t("cleeckykit:common.form.validations.maxLength", {
            max: validations.maxLength,
          });
        options.maxLength = {
          value: validations.maxLength,
          message,
        } as ValidationValueMessage<number>;
      }

      if (validations?.validate) {
        options.validate = validations.validate;
      }

      return options;
    };

    const renderError = () => {
      if (!fieldError) return null;

      if (validationVariant === "tooltip") {
        return (
          <Tooltip
            variants={tooltipVariants}
            initial="hidden"
            animate={showTooltip ? "visible" : "hidden"}
            transition={{ duration: 0.3 }}>
            {fieldError}
          </Tooltip>
        );
      }

      return (
        <ErrorText
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}>
          {fieldError}
        </ErrorText>
      );
    };

    return (
      <InputWrapper className={className} $fullWidth={fullWidth}>
        {label && label !== "none" && (
          <Label htmlFor={id || name}>
            {label}{" "}
            {validations?.required && (
              <span style={{ color: "#ff3030", fontSize: "1.1rem" }}>*</span>
            )}
          </Label>
        )}
        <InputContainer>
          {prefix && <PrefixContainer>{prefix}</PrefixContainer>}
          <StyledInput
            id={id || name}
            disabled={disabled}
            $hasError={!!fieldError}
            $size={size}
            $variant={variant}
            $borderRadius={borderRadius}
            $hasPrefix={!!prefix}
            $hasSuffix={!!suffix}
            {...register(name, getRegisterOptions())}
            {...props}
            onFocus={(e) => {
              handleInputInteraction();
              props.onFocus?.(e);
            }}
            onChange={(e) => {
              handleInputInteraction();
              props.onChange?.(e);
            }}
            ref={ref}
          />
          {suffix && <SuffixContainer>{suffix}</SuffixContainer>}
          {renderError()}
        </InputContainer>
        {helperText && !fieldError && <HelperText>{helperText}</HelperText>}
      </InputWrapper>
    );
  }
);

CleeckyKitBaseInput.displayName = "CleeckyKitBaseInput";
