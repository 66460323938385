import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  CleeckyKitBaseInput,
  BaseInputProps,
  InputValidations,
} from "./CleeckyKitBaseInput";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

interface PasswordStrength {
  score: number;
  color: string;
  label: string;
}

interface PasswordInputProps extends Omit<BaseInputProps, "type"> {
  showToggleButton?: boolean;
  showStrengthMeter?: boolean;
  value?: string;
}

const StrengthMeterContainer = styled(motion.div)`
  margin-top: 0.5rem;
`;

const StrengthBar = styled.div<{ $score: number; $color: string }>`
  height: 4px;
  background: ${({ theme }) => theme.colors.background.disabled};
  border-radius: 2px;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${({ $score }) => $score}%;
    background: ${({ $color }) => $color};
    transition: all 0.3s ease;
  }
`;

const StrengthLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.secondary};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.interactive.primary};
  }
`;

export const CleeckyKitPasswordInput = React.forwardRef<
  HTMLInputElement,
  PasswordInputProps
>((props, ref) => {
  const { t } = useTranslation();
  const {
    showToggleButton = true,
    showStrengthMeter = false,
    onChange,
    error,
    value,
    label = t("cleeckykit:common.form.password.label"),
    placeholder = t("cleeckykit:common.form.password.placeholder"),
    validations,
    ...rest
  } = props;

  const defaultValidations: InputValidations = {
    required: true,
    requiredText: t("cleeckykit:common.form.password.required"),
    invalidText: t("cleeckykit:common.form.validations.invalidCredentials"),
    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
    minLength: 8,
  };

  const [showPassword, setShowPassword] = useState(false);
  const [strength, setStrength] = useState<PasswordStrength>({
    score: 1,
    color: "#ddd",
    label: t("cleeckykit:common.form.password.strength.empty"),
  });

  const calculatePasswordStrength = (password: string): PasswordStrength => {
    if (!password)
      return {
        score: 1,
        color: "#ddd",
        label: t("cleeckykit:common.form.password.strength.empty"),
      };

    let score = 0;
    const checks = {
      length: password.length >= 8,
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasNumbers: /\d/.test(password),
      hasSpecialChars: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };

    score += checks.length ? 20 : 0;
    score += checks.hasUpperCase ? 20 : 0;
    score += checks.hasLowerCase ? 20 : 0;
    score += checks.hasNumbers ? 20 : 0;
    score += checks.hasSpecialChars ? 20 : 0;

    const strengthLevels = [
      {
        threshold: 20,
        color: "#ff4d4d",
        label: t("cleeckykit:common.form.password.strength.very_weak"),
      },
      {
        threshold: 40,
        color: "#ffa64d",
        label: t("cleeckykit:common.form.password.strength.weak"),
      },
      {
        threshold: 60,
        color: "#ffff4d",
        label: t("cleeckykit:common.form.password.strength.medium"),
      },
      {
        threshold: 80,
        color: "#73f073",
        label: t("cleeckykit:common.form.password.strength.strong"),
      },
      {
        threshold: 100,
        color: "#00ab00",
        label: t("cleeckykit:common.form.password.strength.very_strong"),
      },
    ];

    const level =
      strengthLevels.find((level) => score <= level.threshold) ||
      strengthLevels[strengthLevels.length - 1];

    return { score, color: level.color, label: level.label };
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (showStrengthMeter) {
      setStrength(calculatePasswordStrength(newValue));
    }

    onChange?.(e);
  };

  return (
    <>
      <CleeckyKitBaseInput
        ref={ref}
        type={showPassword ? "text" : "password"}
        prefix={<FontAwesomeIcon icon={faLock} />}
        suffix={
          showToggleButton && (
            <ToggleButton
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              aria-label={
                showPassword
                  ? t("cleeckykit:common.form.password.hide")
                  : t("cleeckykit:common.form.password.show")
              }>
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </ToggleButton>
          )
        }
        error={error}
        onChange={handleChange}
        value={value}
        label={label}
        placeholder={placeholder}
        validations={{ ...defaultValidations, ...validations }}
        {...rest}
      />

      {showStrengthMeter && (
        <StrengthMeterContainer
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}>
          <StrengthBar $score={strength.score} $color={strength.color} />
          <StrengthLabel>
            <span>{t("cleeckykit:common.form.password.strength.label")}:</span>
            <span>{strength.label}</span>
          </StrengthLabel>
        </StrengthMeterContainer>
      )}
    </>
  );
});

CleeckyKitPasswordInput.displayName = "CleeckyKitPasswordInput";
