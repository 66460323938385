import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import {
  CleeckyKitBaseInput,
  BaseInputProps,
  InputValidations,
} from "./CleeckyKitBaseInput";
import { useTranslation } from "react-i18next";
import { formatPhoneNumber } from "../../../utilities/phoneUtils";

type IdentifierType = "username" | "email" | "phone";

interface IdentifierInputProps extends Omit<BaseInputProps, "type"> {
  allowedTypes?: IdentifierType[];
  label?: string;
  placeholder?: string;
}

export const CleeckyKitIdentifierInput = React.forwardRef<
  HTMLInputElement,
  IdentifierInputProps &
    Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">
>((props, ref) => {
  const { t } = useTranslation();
  const {
    allowedTypes = ["username", "email", "phone"],
    label = t("cleeckykit:common.form.identifier.label"),
    placeholder = t("cleeckykit:common.form.identifier.placeholder"),
    validations,
    onChange,
    ...rest
  } = props;

  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const phonePattern = /^\(\d{2}\) \d{4,5}-\d{4}$/;
  const usernamePattern = /^[a-zA-Z0-9_]{4,30}$/;

  const getValidationPattern = () => {
    const patterns = allowedTypes.map((type) => {
      switch (type) {
        case "email":
          return emailPattern.source;
        case "phone":
          return phonePattern.source;
        case "username":
          return usernamePattern.source;
        default:
          return "";
      }
    });

    return new RegExp(patterns.join("|"));
  };

  const defaultValidations: InputValidations = {
    required: true,
    requiredText: t("cleeckykit:common.form.identifier.required"),
    invalidText: t("cleeckykit:common.form.identifier.invalid"),
    pattern: getValidationPattern(),
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // Se o valor parece ser um número de telefone, formata-o
    if (
      allowedTypes.includes("phone") &&
      /^\d+$/.test(value.replace(/\D/g, ""))
    ) {
      value = formatPhoneNumber(value, "BR");
    }

    const syntheticEvent = {
      ...e,
      target: {
        ...e.target,
        value,
      },
    };

    onChange?.(syntheticEvent);
  };

  return (
    <CleeckyKitBaseInput
      ref={ref}
      type="text"
      prefix={<FontAwesomeIcon icon={faUser} />}
      label={label}
      placeholder={placeholder}
      validations={{ ...defaultValidations, ...validations }}
      onChange={handleChange}
      {...rest}
    />
  );
});

CleeckyKitIdentifierInput.displayName = "CleeckyKitIdentifierInput";
