import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

interface SidebarItemProps {
  icon: React.ReactNode;
  label: string;
  isActive?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  customStyle?: React.CSSProperties;
  isExpanded?: boolean;
}

const ItemContainer = styled(motion.button)<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 0.5rem;
  background: ${({ theme, $isActive }) =>
    $isActive
      ? theme.gradient.highlightColor || theme.colors.interactive.primary
      : "transparent"};
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.text.onPrimary : theme.colors.text.primary};
  cursor: pointer;
  width: 100%;
  transition: all 0.2s ease;

  &:hover:not(:disabled) {
    background: ${({ theme, $isActive }) =>
      $isActive
        ? theme.gradient.highlightColor || theme.colors.interactive.primaryHover
        : theme.colors.interactive.hover};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Label = styled(motion.span)<{ $isExpanded?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: ${({ $isExpanded }) => ($isExpanded ? 1 : 0)};
  width: ${({ $isExpanded }) => ($isExpanded ? "130px" : "0")};
  text-align: left;
`;

export const CleeckyKitSidebarItem: React.FC<SidebarItemProps> = ({
  icon,
  label,
  isActive = false,
  onClick,
  disabled = false,
  customStyle,
  isExpanded = true,
}) => {
  return (
    <ItemContainer
      $isActive={isActive}
      onClick={onClick}
      disabled={disabled}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      style={customStyle}>
      {icon}
      <AnimatePresence>
        {isExpanded && (
          <Label
            $isExpanded={isExpanded}
            initial={{ opacity: 0, width: 0 }}
            animate={{ opacity: 1, width: "130px" }}
            exit={{ opacity: 0, width: 0 }}
            transition={{ duration: 0.3 }}>
            {label}
          </Label>
        )}
      </AnimatePresence>
    </ItemContainer>
  );
};
