import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LogoSvg from "../../assets/images/cleecky-slogan-logo-black-minimalist-1.svg";
import * as CleeckyKit from "../../CleeckyKit/components";

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background.base};
  z-index: 9999;
  gap: 2rem;
  padding: 1rem;
`;

const Logo = styled(CleeckyKit.Image)`
  width: min(200px, 80dvw);
  height: auto;
  filter: ${({ theme }) => (theme.name === "dark" ? "invert(1)" : "invert(0)")};
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const LoadingScreen: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LoadingContainer>
      <Logo src={LogoSvg} alt="Cleecky Logo" sourceType="file" format="svg" />
      <CleeckyKit.Text>{t("cleeckykit:common.status.loading")}</CleeckyKit.Text>
    </LoadingContainer>
  );
};
