import React, { useState, useEffect, useRef } from "react";
import styled, { css, keyframes } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { formatText } from "CleeckyKit/utilities";

type TextAlign = "left" | "right" | "center" | "justify";
type TextTransform = "none" | "capitalize" | "uppercase" | "lowercase";
type TextAnimation =
  | "fade"
  | "scale"
  | "slide"
  | "gradient"
  | "typewriter"
  | "none";
type TextDirection = "ltr" | "rtl";

interface TextProps {
  children: React.ReactNode;
  align?: TextAlign;
  transform?: TextTransform;
  preserveWhitespace?: boolean;
  hyphenate?: boolean;
  truncate?: boolean | number;
  maxLines?: number;
  animation?: TextAnimation;
  animationDelay?: number;
  gradient?: boolean;
  interactive?: boolean;
  customStyle?: React.CSSProperties;
  className?: string;
  lazy?: boolean;
  direction?: TextDirection;
  href?: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
}

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const StyledText = styled(motion.p)<{
  $align?: TextAlign;
  $transform?: TextTransform;
  $preserveWhitespace?: boolean;
  $hyphenate?: boolean;
  $truncate?: boolean | number;
  $maxLines?: number;
  $gradient?: boolean;
  $interactive?: boolean;
  $direction?: TextDirection;
  $isLink?: boolean;
}>`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  text-align: ${({ $align }) => $align};
  text-transform: ${({ $transform }) => $transform};
  direction: ${({ $direction }) => $direction};

  ${({ $preserveWhitespace }) =>
    $preserveWhitespace &&
    css`
      white-space: pre-wrap;
      word-wrap: break-word;
    `}

  ${({ $hyphenate }) =>
    $hyphenate &&
    css`
      hyphens: auto;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
    `}

  ${({ $truncate, $maxLines }) => {
    if ($truncate || $maxLines) {
      return css`
        display: -webkit-box;
        -webkit-line-clamp: ${typeof $truncate === "number"
          ? $truncate
          : $maxLines || 1};
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      `;
    }
  }}

  ${({ $gradient }) =>
    $gradient &&
    css`
      background: linear-gradient(
        90deg,
        ${({ theme }) =>
          theme.gradient?.startColor || theme.colors.interactive.primary},
        ${({ theme }) =>
          theme.gradient?.endColor || theme.colors.interactive.secondary}
      );
      background-size: 200% auto;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: ${gradientAnimation} 3s ease infinite;
    `}

  ${({ $interactive, $isLink, theme }) =>
    $interactive &&
    css`
      transition: color 0.2s ease;
      cursor: pointer;

      &:hover {
        color: ${$isLink
          ? theme.gradient?.highlightColor || theme.colors.interactive.primary
          : theme.colors.interactive.primary};
      }
    `}
`;

export const CleeckyKitText: React.FC<TextProps> = ({
  children,
  align = "left",
  transform = "none",
  preserveWhitespace = false,
  hyphenate = false,
  truncate = false,
  maxLines,
  animation = "none",
  animationDelay = 0,
  gradient = false,
  interactive = false,
  customStyle,
  className,
  lazy = false,
  direction = "ltr",
  href,
  target = "_blank",
}) => {
  const [isVisible, setIsVisible] = useState(!lazy);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (lazy) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
          }
        },
        { threshold: 0.1 }
      );

      if (textRef.current) {
        observer.observe(textRef.current);
      }

      return () => observer.disconnect();
    }
  }, [lazy]);

  const getAnimationProps = () => {
    switch (animation) {
      case "fade":
        return {
          initial: { opacity: 0 },
          animate: { opacity: 1 },
          transition: { delay: animationDelay },
        };
      case "scale":
        return {
          initial: { scale: 0 },
          animate: { scale: 1 },
          transition: { delay: animationDelay },
        };
      case "slide":
        return {
          initial: { x: -20, opacity: 0 },
          animate: { x: 0, opacity: 1 },
          transition: { delay: animationDelay },
        };
      default:
        return {};
    }
  };

  const formattedChildren = React.useMemo(() => {
    return formatText(children);
  }, [children]);

  if (!isVisible) {
    return <div ref={textRef} style={{ minHeight: "1em" }} />;
  }

  const content = (
    <StyledText
      ref={textRef}
      $align={align}
      $transform={transform}
      $preserveWhitespace={preserveWhitespace}
      $hyphenate={hyphenate}
      $truncate={truncate}
      $maxLines={maxLines}
      $gradient={gradient}
      $interactive={interactive || !!href}
      $direction={direction}
      $isLink={!!href}
      className={className}
      style={customStyle}
      {...getAnimationProps()}>
      {formattedChildren}
    </StyledText>
  );

  if (href) {
    return (
      <a
        href={href}
        target={target}
        rel={target === "_blank" ? "noopener noreferrer" : undefined}
        style={{ textDecoration: "none" }}>
        {content}
      </a>
    );
  }

  return content;
};

CleeckyKitText.displayName = "CleeckyKitText";
