import React, { createContext, useContext, useState, useEffect } from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { CleeckyKitTheme } from "../CleeckyKitTheme";
import { CleeckyKitGradientTheme } from "../CleeckyKitGradientTheme";
import { interfaceThemes, gradientThemes } from "../../../themes";
import { CleeckyKitGlobalStyle } from "../CleeckyKitGlobalStyles";
import {
  CleeckyKitGradientAnimation,
  predefinedAnimations,
} from "../animations/CleeckyKitGradientAnimations";

interface CleeckyKitThemeContextType {
  currentTheme: CleeckyKitTheme;
  currentGradient?: CleeckyKitGradientTheme;
  availableThemes: string[];
  availableGradients: string[];
  setTheme: (themeName: string) => void;
  setGradient: (
    gradientName: string,
    options?: {
      enableAnimation?: boolean;
      animation?: CleeckyKitGradientAnimation;
    }
  ) => void;
}

const CleeckyKitThemeContext = createContext<
  CleeckyKitThemeContextType | undefined
>(undefined);

export const useCleeckyKitCurrentTheme = () => {
  const context = useContext(CleeckyKitThemeContext);
  if (!context) {
    throw new Error(
      "useCleeckyKitTheme deve ser usado dentro de CleeckyKitThemeProvider"
    );
  }
  return context;
};

interface CleeckyKitThemeProviderProps {
  children: React.ReactNode;
  defaultTheme?: string;
  defaultGradient?: string;
}

export const CleeckyKitThemeProvider: React.FC<
  CleeckyKitThemeProviderProps
> = ({ children, defaultTheme = "light", defaultGradient = "greenNature" }) => {
  const [currentTheme, setCurrentTheme] = useState<CleeckyKitTheme>(() => {
    const savedTheme = localStorage.getItem("cleecky-theme");
    return savedTheme
      ? interfaceThemes[savedTheme]
      : interfaceThemes[defaultTheme];
  });

  const [currentGradient, setCurrentGradient] = useState<
    CleeckyKitGradientTheme | undefined
  >(() => {
    const savedGradient = localStorage.getItem("cleecky-gradient");
    const savedAnimation = localStorage.getItem("cleecky-animation");
    const savedEnableAnimation = localStorage.getItem(
      "cleecky-enable-animation"
    );

    const gradient = savedGradient
      ? gradientThemes[savedGradient]
      : gradientThemes[defaultGradient];

    return {
      ...gradient,
      enableAnimation: savedEnableAnimation
        ? savedEnableAnimation === "true"
        : false,
      animation: savedAnimation
        ? predefinedAnimations[savedAnimation]
        : predefinedAnimations["wave"],
    };
  });

  const handleSetGradient = (
    name: string,
    options?: {
      enableAnimation?: boolean;
      animation?: CleeckyKitGradientAnimation;
    }
  ) => {
    const gradient = gradientThemes[name];
    if (gradient) {
      const newGradient = {
        ...gradient,
        name,
        enableAnimation: options?.enableAnimation ?? false,
        animation: options?.animation ?? predefinedAnimations["wave"],
      };

      setCurrentGradient(newGradient);
      localStorage.setItem("cleecky-gradient", name);
      localStorage.setItem(
        "cleecky-enable-animation",
        String(newGradient.enableAnimation)
      );
      localStorage.setItem(
        "cleecky-animation",
        newGradient.animation?.name || "wave"
      );
    }
  };

  useEffect(() => {
    localStorage.setItem("cleecky-theme", currentTheme.name);
  }, [currentTheme]);

  const value = {
    currentTheme,
    currentGradient,
    availableThemes: Object.keys(interfaceThemes),
    availableGradients: Object.keys(gradientThemes),
    setTheme: (name: string) => setCurrentTheme(interfaceThemes[name]),
    setGradient: handleSetGradient,
  };

  return (
    <CleeckyKitThemeContext.Provider value={value}>
      <StyledThemeProvider
        theme={{ ...currentTheme, gradient: currentGradient }}>
        <CleeckyKitGlobalStyle
          theme={{ ...currentTheme, gradient: currentGradient }}
        />
        {children}
      </StyledThemeProvider>
    </CleeckyKitThemeContext.Provider>
  );
};
