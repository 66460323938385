import React from "react";
import styled, { css } from "styled-components";

interface MenuItemProps {
  onClick?: () => void;
  icon?: React.ReactNode;
  disabled?: boolean;
  danger?: boolean;
  children: React.ReactNode;
}

const ItemContainer = styled.button<{ $danger?: boolean; $disabled?: boolean }>`
  width: 100%;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  background: none;
  cursor: pointer;
  color: ${({ theme, $danger }) =>
    $danger ? theme.colors.status.error : theme.colors.text.primary};
  font-size: 0.9rem;
  text-align: left;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background: ${({ theme, $danger }) =>
      $danger ? theme.colors.status.error : theme.gradient?.highlightColor};
    transform: translateX(-100%);
    transition: transform 0.2s ease;
  }

  &:hover,
  &:active {
    background: ${({ theme }) => theme.colors.background.hover};
    padding-left: 20px;

    &::before {
      transform: translateX(0);
    }
  }

  ${({ $disabled, theme }) =>
    $disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      color: ${theme.colors.text.disabled};
      &:hover,
      &:active {
        background: none;
        padding-left: 16px;
        &::before {
          transform: translateX(-100%);
        }
      }
    `}
`;

const Divider = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.background.card};
  margin: 4px 0;
`;

export const CleeckyKitDropdownMenuItem: React.FC<MenuItemProps> = ({
  onClick,
  icon,
  disabled,
  danger,
  children,
}) => (
  <ItemContainer
    onClick={disabled ? undefined : onClick}
    onMouseDown={disabled ? undefined : onClick}
    onTouchStart={disabled ? undefined : onClick}
    $disabled={disabled}
    $danger={danger}>
    {icon}
    {children}
  </ItemContainer>
);

export const CleeckyKitDropdownMenuDivider: React.FC = () => <Divider />;
