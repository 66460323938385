import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styled, { useTheme } from "styled-components";
import { CleeckyKitColumn as Column } from "../../../components/Layout/Column/CleeckyKitColumn";
import { CleeckyKitText as Text } from "../../../components/Typography/CleeckyKitText";

interface ActionButtonProps {
  icon: IconProp;
  title: string;
  onClick: () => void;
  color?: string;
}

const ActionButtonContainer = styled.button<{ color?: string }>`
  background: ${({ theme }) => theme.colors.background.card};
  border-radius: 25px;
  padding: 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.card.default};

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.shadows.card.hover};
  }
`;

const IconContainer = styled.div<{ color?: string }>`
  background: ${({ color, theme }) =>
    (color || theme.colors.interactive.primary) + "20"};
  color: ${({ color, theme }) => color || theme.colors.interactive.primary};
  width: 48px;
  height: 48px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
`;

export const CleeckyKitActionButton: React.FC<ActionButtonProps> = ({
  icon,
  title,
  onClick,
  color,
}) => {
  const theme = useTheme();

  return (
    <ActionButtonContainer onClick={onClick} color={color}>
      <Column gap="1rem" align="center">
        <IconContainer color={color}>
          <FontAwesomeIcon icon={icon} />
        </IconContainer>
        <Text
          align="center"
          customStyle={{ color: theme.colors.text.secondary }}>
          {title}
        </Text>
      </Column>
    </ActionButtonContainer>
  );
};
