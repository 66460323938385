import React from "react";
import styled, { css, keyframes } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { formatText } from "CleeckyKit/utilities/textFormatting";

type SubTitleAlign = "left" | "center" | "right";
type SubTitleWeight = "light" | "normal" | "medium" | "semibold" | "bold";
type SubTitleAnimation = "fade" | "scale" | "slide" | "gradient" | "none";
type SubTitleSize = "small" | "medium" | "large";

interface SubTitleProps {
  children: React.ReactNode;
  size?: SubTitleSize;
  align?: SubTitleAlign;
  weight?: SubTitleWeight;
  animation?: SubTitleAnimation;
  animationDelay?: number;
  gradient?: boolean;
  interactive?: boolean;
  muted?: boolean;
  spacing?: string;
  customStyle?: React.CSSProperties;
  className?: string;
  id?: string;
  divider?: boolean;
  marginTop?: string;
  marginBottom?: string;
  href?: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
}

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const getFontSize = (size: SubTitleSize) => {
  const sizes = {
    small: "1rem",
    medium: "1.25rem",
    large: "1.5rem",
  };
  return sizes[size];
};

const getFontWeight = (weight: SubTitleWeight) => {
  const weights = {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  };
  return weights[weight];
};

const StyledSubTitle = styled(motion.h3)<{
  $size: SubTitleSize;
  $align: SubTitleAlign;
  $weight: SubTitleWeight;
  $gradient?: boolean;
  $interactive?: boolean;
  $muted?: boolean;
  $spacing?: string;
  $divider?: boolean;
  $marginTop?: string;
  $marginBottom?: string;
  $isLink?: boolean;
}>`
  margin: 0;
  margin-top: ${({ $marginTop }) => $marginTop || "0.5rem"};
  margin-bottom: ${({ $marginBottom }) => $marginBottom || "1rem"};
  padding: 0;
  font-size: ${({ $size }) => getFontSize($size)};
  font-weight: ${({ $weight }) => getFontWeight($weight)};
  text-align: ${({ $align }) => $align};
  letter-spacing: ${({ $spacing }) => $spacing || "0.5px"};
  color: ${({ theme, $muted }) =>
    $muted ? theme.colors.text.secondary : theme.colors.text.primary};
  transition: all 0.2s ease;
  line-height: 1.4;

  ${({ $divider, theme }) =>
    $divider &&
    css`
      padding-bottom: 0.5rem;
      border-bottom: 1px solid ${theme.colors.border.light};
    `}

  ${({ $gradient, theme }) =>
    $gradient &&
    css`
      background: linear-gradient(
        90deg,
        ${theme.gradient?.startColor || theme.colors.interactive.primary},
        ${theme.gradient?.endColor || theme.colors.interactive.secondary}
      );
      background-size: 200% auto;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: ${gradientAnimation} 3s ease infinite;
    `}

  ${({ $interactive, $isLink, theme }) =>
    $interactive &&
    css`
      cursor: pointer;
      &:hover {
        color: ${$isLink
          ? theme.gradient?.highlightColor || theme.colors.interactive.primary
          : theme.colors.interactive.primary};
      }
    `}

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    font-size: ${({ $size }) => `calc(${getFontSize($size)} * 0.9)`};
  }
`;

export const CleeckyKitSubTitle: React.FC<SubTitleProps> = ({
  children,
  size = "medium",
  align = "left",
  weight = "medium",
  animation = "none",
  animationDelay = 0,
  gradient = false,
  interactive = false,
  muted = false,
  spacing,
  customStyle,
  className,
  id,
  divider = false,
  marginTop,
  marginBottom,
  href,
  target = "_blank",
}) => {
  const formattedChildren = React.useMemo(() => {
    return formatText(children);
  }, [children]);

  const getAnimationProps = () => {
    switch (animation) {
      case "fade":
        return {
          initial: { opacity: 0 },
          animate: { opacity: 1 },
          transition: { delay: animationDelay },
        };
      case "scale":
        return {
          initial: { scale: 0.9, opacity: 0 },
          animate: { scale: 1, opacity: 1 },
          transition: { delay: animationDelay },
        };
      case "slide":
        return {
          initial: { x: -20, opacity: 0 },
          animate: { x: 0, opacity: 1 },
          transition: { delay: animationDelay },
        };
      default:
        return {};
    }
  };

  const content = (
    <StyledSubTitle
      $size={size}
      $align={align}
      $weight={weight}
      $gradient={gradient}
      $interactive={interactive || !!href}
      $muted={muted}
      $spacing={spacing}
      $divider={divider}
      $marginTop={marginTop}
      $marginBottom={marginBottom}
      $isLink={!!href}
      className={className}
      style={customStyle}
      id={id}
      {...getAnimationProps()}>
      {formattedChildren}
    </StyledSubTitle>
  );

  if (href) {
    return (
      <a
        href={href}
        target={target}
        rel={target === "_blank" ? "noopener noreferrer" : undefined}
        style={{ textDecoration: "none" }}>
        {content}
      </a>
    );
  }

  return content;
};

CleeckyKitSubTitle.displayName = "CleeckyKitSubTitle";
