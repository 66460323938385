import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import {
  CleeckyKitBaseInput,
  BaseInputProps,
  InputValidations,
} from "./CleeckyKitBaseInput";
import { formatPhoneNumber } from "../../../utilities/phoneUtils";
import { useTranslation } from "react-i18next";

interface PhoneInputProps extends Omit<BaseInputProps, "type"> {
  format?: "BR" | "US" | "custom";
  customFormat?: string;
  allowInternational?: boolean;
}

export const CleeckyKitPhoneInput = React.forwardRef<
  HTMLInputElement,
  PhoneInputProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">
>((props, ref) => {
  const { t } = useTranslation();
  const {
    format = "BR",
    customFormat,
    allowInternational = false,
    onChange,
    validations,
    ...rest
  } = props;

  const defaultValidations: InputValidations = {
    required: true,
    requiredText: t("cleeckykit:common.form.phone.required"),
    invalidText: t("cleeckykit:common.form.phone.invalid"),
    pattern: format === "BR" ? /^\(\d{2}\) \d{4,5}-\d{4}$/ : /^\+?[\d\s-()]+$/,
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatPhoneNumber(e.target.value, format);

    const syntheticEvent = {
      ...e,
      target: {
        ...e.target,
        value: formattedValue,
      },
    };

    onChange?.(syntheticEvent);
  };

  return (
    <CleeckyKitBaseInput
      ref={ref}
      type="tel"
      prefix={<FontAwesomeIcon icon={faPhone} />}
      onChange={handleChange}
      inputMode="numeric"
      validations={{ ...defaultValidations, ...validations }}
      {...rest}
    />
  );
});

CleeckyKitPhoneInput.displayName = "CleeckyKitPhoneInput";
