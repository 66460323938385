import React from "react";
import styled from "styled-components";

interface FormItemProps {
  children: React.ReactNode;
  span?: number;
  className?: string;
}

const StyledFormItem = styled.div<{ $span?: number }>`
  grid-column: span ${({ $span }) => $span || "auto"};
  min-width: 0;
`;

export const CleeckyKitFormItem: React.FC<FormItemProps> = ({
  children,
  span,
  className,
}) => {
  return (
    <StyledFormItem $span={span} className={className}>
      {children}
    </StyledFormItem>
  );
};
