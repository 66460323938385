import React, { forwardRef } from "react";
import { CleeckyKitBaseInput, BaseInputProps } from "./CleeckyKitBaseInput";
import { useFormContext } from "react-hook-form";

interface TextInputProps extends Omit<BaseInputProps, "type"> {
  maxLength?: number;
  showCharCount?: boolean;
}

export const CleeckyKitTextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const { maxLength, showCharCount, helperText, onChange, ...rest } = props;
    const [charCount, setCharCount] = React.useState(0);
    const formContext = useFormContext();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCharCount(e.target.value.length);
      onChange?.(e);
    };

    const updatedHelperText = showCharCount
      ? `${charCount}${maxLength ? `/${maxLength}` : ""} caracteres${
          helperText ? ` • ${helperText}` : ""
        }`
      : helperText;

    return (
      <CleeckyKitBaseInput
        ref={ref}
        type="text"
        maxLength={maxLength}
        helperText={updatedHelperText}
        onChange={handleChange}
        {...rest}
      />
    );
  }
);

CleeckyKitTextInput.displayName = "CleeckyKitTextInput";
