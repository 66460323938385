import React from "react";
import styled from "styled-components";
import { useCleeckyKitCurrentTheme } from "CleeckyKit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

interface CheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  label?: string;
  labelPosition?: "left" | "right";
  size?: "small" | "medium" | "large";
}

const CheckboxContainer = styled.label<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const CheckboxLabel = styled.span`
  margin: 0 0.5rem;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const HiddenCheckbox = styled.input`
  display: none;
`;

const StyledCheckbox = styled.div<{
  checked: boolean;
  size: "small" | "medium" | "large";
  background: string;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) =>
    ({
      small: "16px",
      medium: "20px",
      large: "24px",
    }[size])};
  height: ${({ size }) =>
    ({
      small: "16px",
      medium: "20px",
      large: "24px",
    }[size])};
  background: ${({ checked, background, theme }) =>
    checked ? background : "transparent"};
  border: 2px solid
    ${({ checked, background, theme }) =>
      checked ? background : theme.colors.border.default};
  border-radius: 4px;
  transition: all 0.2s;
  color: white;
  font-size: ${({ size }) =>
    ({
      small: "10px",
      medium: "12px",
      large: "14px",
    }[size])};

  &:hover {
    border-color: ${({ background }) => background};
  }
`;

export const CleeckyKitCheckbox: React.FC<CheckboxProps> = ({
  checked,
  onChange,
  disabled = false,
  label,
  labelPosition = "right",
  size = "medium",
}) => {
  const { currentGradient } = useCleeckyKitCurrentTheme();

  return (
    <CheckboxContainer disabled={disabled}>
      {label && labelPosition === "left" && (
        <CheckboxLabel>{label}</CheckboxLabel>
      )}
      <HiddenCheckbox
        type="checkbox"
        checked={checked}
        onChange={(e) => !disabled && onChange(e.target.checked)}
        disabled={disabled}
      />
      <StyledCheckbox
        checked={checked}
        size={size}
        background={currentGradient?.highlightColor || "#007bff"}>
        {checked && <FontAwesomeIcon icon={faCheck} />}
      </StyledCheckbox>
      {label && labelPosition === "right" && (
        <CheckboxLabel>{label}</CheckboxLabel>
      )}
    </CheckboxContainer>
  );
};
