import React from "react";
import styled from "styled-components";

interface NavbarSectionProps {
  align?: "start" | "center" | "end";
  flex?: number | string;
  children?: React.ReactNode;
}

const StyledNavbarSection = styled.div<{
  $align?: "start" | "center" | "end";
  $flex?: number | string;
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ $align }) => {
    switch ($align) {
      case "start":
        return "flex-start";
      case "end":
        return "flex-end";
      default:
        return "center";
    }
  }};
  flex: ${({ $flex }) => $flex || "none"};
  gap: 1rem;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.sm}) {
    gap: 0.5rem;
  }
`;

const NavbarSectionComponent = ({
  align,
  flex,
  children,
  ...rest
}: NavbarSectionProps) => {
  return (
    <StyledNavbarSection $align={align} $flex={flex} {...rest}>
      {children}
    </StyledNavbarSection>
  );
};

export const CleeckyKitNavbarSection = styled(NavbarSectionComponent)``;
