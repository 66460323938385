import React from "react";
import styled, { css } from "styled-components";

interface DividerProps {
  lineOrientation?: "horizontal" | "vertical";
  textOrientation?: "horizontal" | "vertical";
  size?: string | number;
  thickness?: string | number;
  color?: string;
  textPosition?: "start" | "center" | "end";
  children?: React.ReactNode;
  className?: string;
}

const Container = styled.div<{
  $lineOrientation: "horizontal" | "vertical";
  $size?: string | number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $lineOrientation, $size }) =>
    $lineOrientation === "horizontal"
      ? css`
          width: ${typeof $size === "number" ? `${$size}px` : $size || "100%"};
          height: auto;
        `
      : css`
          height: ${typeof $size === "number" ? `${$size}px` : $size || "100%"};
          width: auto;
          flex-direction: column;
        `}
`;

const Line = styled.div<{
  $lineOrientation: "horizontal" | "vertical";
  $thickness?: string | number;
  $color?: string;
  $hasText?: boolean;
  $textPosition?: "start" | "center" | "end";
}>`
  background-color: ${({ theme, $color }) =>
    $color || theme.colors.text.secondary};
  flex: 1;

  ${({ $lineOrientation, $thickness }) =>
    $lineOrientation === "horizontal"
      ? css`
          height: ${typeof $thickness === "number"
            ? `${$thickness}px`
            : $thickness || "1px"};
          width: 100%;
        `
      : css`
          width: ${typeof $thickness === "number"
            ? `${$thickness}px`
            : $thickness || "1px"};
          height: 100%;
        `}

  ${({ $hasText, $textPosition }) =>
    $hasText &&
    css`
      flex: ${$textPosition === "center" ? 1 : $textPosition === "end" ? 2 : 1};
    `}
`;

const TextContainer = styled.div<{
  $lineOrientation: "horizontal" | "vertical";
  $textOrientation: "horizontal" | "vertical";
  $textPosition: "start" | "center" | "end";
}>`
  padding: ${({ $lineOrientation }) =>
    $lineOrientation === "horizontal" ? "0 1rem" : "1rem 0"};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.875rem;
  white-space: nowrap;

  ${({ $textOrientation, $lineOrientation }) =>
    $textOrientation === "vertical" &&
    css`
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: ${$lineOrientation === "vertical" ? "rotate(180deg)" : "none"};
    `}
`;

export const CleeckyKitDivider: React.FC<DividerProps> = ({
  lineOrientation = "horizontal",
  textOrientation,
  size,
  thickness = 1,
  color,
  textPosition = "center",
  children,
  className,
}) => {
  const hasText = Boolean(children);
  const finalTextOrientation = textOrientation || lineOrientation;

  return (
    <Container
      $lineOrientation={lineOrientation}
      $size={size}
      className={className}>
      {!hasText && (
        <Line
          $lineOrientation={lineOrientation}
          $thickness={thickness}
          $color={color}
          $hasText={hasText}
          $textPosition={textPosition}
        />
      )}

      {hasText && textPosition === "start" && (
        <>
          <TextContainer
            $lineOrientation={lineOrientation}
            $textOrientation={finalTextOrientation}
            $textPosition={textPosition}>
            {children}
          </TextContainer>
          <Line
            $lineOrientation={lineOrientation}
            $thickness={thickness}
            $color={color}
            $hasText={hasText}
            $textPosition={textPosition}
          />
        </>
      )}

      {hasText && textPosition === "center" && (
        <>
          <Line
            $lineOrientation={lineOrientation}
            $thickness={thickness}
            $color={color}
            $hasText={hasText}
            $textPosition={textPosition}
          />
          <TextContainer
            $lineOrientation={lineOrientation}
            $textOrientation={finalTextOrientation}
            $textPosition={textPosition}>
            {children}
          </TextContainer>
          <Line
            $lineOrientation={lineOrientation}
            $thickness={thickness}
            $color={color}
            $hasText={hasText}
            $textPosition={textPosition}
          />
        </>
      )}

      {hasText && textPosition === "end" && (
        <>
          <Line
            $lineOrientation={lineOrientation}
            $thickness={thickness}
            $color={color}
            $hasText={hasText}
            $textPosition={textPosition}
          />
          <TextContainer
            $lineOrientation={lineOrientation}
            $textOrientation={finalTextOrientation}
            $textPosition={textPosition}>
            {children}
          </TextContainer>
        </>
      )}
    </Container>
  );
};
