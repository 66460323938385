import React, { useState } from "react";
import * as CleeckyKit from "CleeckyKit";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowPointer,
  faClock,
  faFileLines,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

export const QuickSummaryCards: React.FC = () => {
  const { currentGradient, currentTheme } =
    CleeckyKit.useCleeckyKitCurrentTheme();
  const [selectedPeriodHits, setSelectedPeriodHits] = useState("current_week");

  const [selectedPeriodTime, setSelectedPeriodTime] = useState("current_week");

  const periodOptions = [
    {
      label: t("project:common.filters.period.current_week"),
      value: "current_week",
    },
    {
      label: t("project:common.filters.period.current_month"),
      value: "current_month",
    },
    {
      label: t("project:common.filters.period.current_year"),
      value: "current_year",
    },
  ];

  return (
    <CleeckyKit.Section>
      <CleeckyKit.Row>
        <CleeckyKit.Column gap="1rem">
          <CleeckyKit.Title level={3}>
            {t(
              "project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.title"
            )}
          </CleeckyKit.Title>
          <CleeckyKit.Carousel
            navigation={{
              show: true,
              position: "outside-top-right",
              navigationBackground: "none",
            }}
            pagination={{ show: true, position: "outside-bottom" }}
            itemsPerView={3}>
            {/* Total Hits*/}
            <CleeckyKit.Card
              backgroundColor={currentGradient?.secondaryHighlightColor}>
              <CleeckyKit.Column height="100%" justify="between" gap="1rem">
                <CleeckyKit.Row wrap={false} noBreak justify="between">
                  <CleeckyKit.Column>
                    <CleeckyKit.Title level={4}>2.000</CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.cards.access_card.total_hits"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Column>
                  <CleeckyKit.Column>
                    <CleeckyKit.Title align="right" level={3}>
                      <FontAwesomeIcon icon={faArrowPointer} />
                    </CleeckyKit.Title>
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
                <CleeckyKit.Row wrap={false} noBreak justify="between">
                  <CleeckyKit.Column>
                    <CleeckyKit.Title level={6}>20</CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t("project:common.filters.period.average.daily_average")}
                    </CleeckyKit.Text>
                  </CleeckyKit.Column>
                  <CleeckyKit.Column align="end">
                    <CleeckyKit.Select
                      options={periodOptions}
                      value={selectedPeriodHits}
                      onChange={(value) =>
                        setSelectedPeriodHits(value as string)
                      }
                      placeholder={t(
                        "project:common.filters.period.select_period"
                      )}
                    />
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
              </CleeckyKit.Column>
            </CleeckyKit.Card>

            {/* Pages */}
            <CleeckyKit.Card
              backgroundColor={currentGradient?.secondaryHighlightColor}>
              <CleeckyKit.Column height="100%" justify="between" gap="1rem">
                <CleeckyKit.Row wrap={false} noBreak justify="between">
                  <CleeckyKit.Column>
                    <CleeckyKit.Title level={4}>2.000</CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.cards.pages_card.total_pages"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Column>
                  <CleeckyKit.Column>
                    <CleeckyKit.Title align="right" level={3}>
                      <FontAwesomeIcon icon={faFileLines} />
                    </CleeckyKit.Title>
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
                <CleeckyKit.Row wrap={false} noBreak justify="between">
                  <CleeckyKit.Column>
                    <CleeckyKit.Title level={6}>20</CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.cards.pages_card.active_pages"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
              </CleeckyKit.Column>
            </CleeckyKit.Card>

            {/* Period */}
            <CleeckyKit.Card
              backgroundColor={currentGradient?.secondaryHighlightColor}>
              <CleeckyKit.Column height="100%" justify="between" gap="1rem">
                <CleeckyKit.Row wrap={false} noBreak justify="between">
                  <CleeckyKit.Column>
                    <CleeckyKit.Title level={4}>2.000</CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.cards.period_card.period_with_most_hits"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Column>
                  <CleeckyKit.Column minWidth="min-content">
                    <CleeckyKit.Title align="right" level={3}>
                      <FontAwesomeIcon icon={faClock} />
                    </CleeckyKit.Title>
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
                <CleeckyKit.Row wrap={false} noBreak justify="between">
                  <CleeckyKit.Column>
                    <CleeckyKit.Title level={6}>20</CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.cards.period_card.hits_quantity"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Column>
                  <CleeckyKit.Column align="end">
                    <CleeckyKit.Select
                      options={periodOptions}
                      value={selectedPeriodTime}
                      onChange={(value) =>
                        setSelectedPeriodTime(value as string)
                      }
                      placeholder={t(
                        "project:common.filters.period.select_period"
                      )}
                    />
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
              </CleeckyKit.Column>
            </CleeckyKit.Card>

            {/* Sources */}
            <CleeckyKit.Card
              backgroundColor={currentGradient?.secondaryHighlightColor}>
              <CleeckyKit.Column height="100%" justify="between" gap="1rem">
                <CleeckyKit.Row wrap={false} noBreak justify="between">
                  <CleeckyKit.Column>
                    <CleeckyKit.Title level={4}>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.cards.sources_card.social_media"
                      )}
                    </CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.cards.sources_card.access_source"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Column>
                  <CleeckyKit.Column>
                    <CleeckyKit.Title align="right" level={3}>
                      <FontAwesomeIcon icon={faLocationDot} />
                    </CleeckyKit.Title>
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
                <CleeckyKit.Row wrap={false} noBreak justify="between">
                  <CleeckyKit.Column>
                    <CleeckyKit.Title level={6}>Marília - SP</CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.cards.sources_card.most_accessed_location"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
              </CleeckyKit.Column>
            </CleeckyKit.Card>
          </CleeckyKit.Carousel>
        </CleeckyKit.Column>
      </CleeckyKit.Row>
    </CleeckyKit.Section>
  );
};
