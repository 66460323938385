import { useState, useEffect } from "react";

interface UseMinLoadingTimeProps {
  isLoading: boolean;
  minLoadingTime?: number;
}

export const useMinLoadingTime = ({
  isLoading,
  minLoadingTime = 1000,
}: UseMinLoadingTimeProps) => {
  const [shouldShowLoading, setShouldShowLoading] = useState(isLoading);

  useEffect(() => {
    if (isLoading) {
      setShouldShowLoading(true);
    } else {
      const timer = setTimeout(() => {
        setShouldShowLoading(false);
      }, minLoadingTime);

      return () => clearTimeout(timer);
    }
  }, [isLoading, minLoadingTime]);

  return shouldShowLoading;
};
