import { ComponentType } from "react";
import { faHome, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import {
  faLink,
  faQrcode,
  faChain,
  faToolbox,
  faCloud,
  faCode,
} from "@fortawesome/free-solid-svg-icons";
import {
  Home,
  Toolbox,
  AggregatorDashboard,
  AggregatorPageList,
  AggregatorCreatePage,
} from "../pages";
import i18next, { t } from "i18next";

export interface RouteDefinition {
  id: string;
  path: string;
  name: string;
  icon: IconDefinition;
  component?: ComponentType<{ hideNavigation?: boolean }>;
  isPublic?: boolean;
  isDynamic?: boolean;
  dynamicPath?: string;
  showOnMenu?: boolean;
  hideNavigation?: boolean;
  children?: RouteDefinition[];
}

const getRoutes = (): RouteDefinition[] => [
  {
    id: "home",
    path: "/",
    name: t("project:pages.home.title"),
    icon: faHome,
    component: Home,
    isPublic: true,
    showOnMenu: false,
    hideNavigation: false,
  },
  {
    id: "toolbox",
    path: "/toolbox",
    name: t("project:apps.toolbox.app_name"),
    icon: faToolbox,
    component: Toolbox,
    isPublic: true,
    showOnMenu: true,
    hideNavigation: false,
    children: [
      {
        id: "link_aggregator",
        path: "/toolbox/link-aggregator",
        name: t("project:apps.toolbox.tools.link_aggregator.name"),
        icon: faLink,
        component: AggregatorDashboard,
        isDynamic: true,
        dynamicPath: "/@:username/:slug",
        showOnMenu: true,
        isPublic: false,
        hideNavigation: false,
        children: [
          {
            id: "link_aggregator_pages",
            path: "/toolbox/link-aggregator/pages",
            name: t("project:aggregator.pageList.title"),
            icon: faLink,
            component: AggregatorPageList,
            showOnMenu: false,
            isPublic: false,
            hideNavigation: false,
          },
          {
            id: "link_aggregator_create",
            path: "/toolbox/link-aggregator/pages/create",
            name: t("project:aggregator.createPage.title"),
            icon: faLink,
            component: AggregatorCreatePage,
            showOnMenu: false,
            isPublic: false,
            hideNavigation: false,
          },
        ],
      },
      {
        id: "link_shortener",
        path: "/toolbox/link-shortener",
        name: t("project:apps.toolbox.tools.link_shortener.name"),
        icon: faChain,
        isDynamic: true,
        dynamicPath: "/:slug",
        showOnMenu: true,
        isPublic: false,
        hideNavigation: true,
      },
      {
        id: "qr_code_generator",
        path: "/toolbox/qr-code-generator",
        name: t("project:apps.toolbox.tools.qr_code_generator.name"),
        icon: faQrcode,
        showOnMenu: true,
        isPublic: false,
        hideNavigation: false,
      },
    ],
  },
  {
    id: "services",
    path: "/services",
    name: t("project:apps.services.app_name", "Serviços"),
    icon: faCloud,
    showOnMenu: true,
    isPublic: true,
    hideNavigation: false,
    children: [
      {
        id: "api",
        path: "/services/api",
        name: t("project:apps.services.tools.api.name", "API"),
        icon: faCode,
        showOnMenu: true,
        isPublic: false,
        hideNavigation: false,
      },
    ],
  },
];

export const routes = getRoutes();

// Atualiza as rotas quando o idioma mudar
i18next.on("languageChanged", () => {
  Object.assign(routes, getRoutes());
});
