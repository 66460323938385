import { CleeckyKitTheme } from "./themes/CleeckyKitTheme";
import { ReactNode } from "react";

// Re-export do tipo Theme
export type Theme = CleeckyKitTheme;

// Layout Types
export type Spacing = number | string;
export type Alignment = "start" | "center" | "end" | "stretch" | "baseline";
export type Justify =
  | "start"
  | "center"
  | "end"
  | "between"
  | "around"
  | "evenly";

export type Direction =
  | "row"
  | "column"
  | "row-reverse"
  | "column-reverse"
  | "inherit";

export interface BaseLayoutProps {
  children: ReactNode;
  gap?: Spacing;
  padding?: Spacing;
  margin?: Spacing;
  width?: string;
  height?: string;
  align?: Alignment;
  justify?: Justify;
  wrap?: boolean;
  className?: string;
}

export interface ColumnProps extends BaseLayoutProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  "2xl"?: number;
  minWidth?: string;
  maxWidth?: string;
}

export interface RowProps extends BaseLayoutProps {
  reverse?: boolean;
  noGutters?: boolean;
  minHeight?: string;
  maxHeight?: string;
  noBreak?: boolean;
}

export type GridMode = "app" | "list" | "bento";

export interface GridItemProps {
  children: ReactNode;
  row?: number;
  col?: number;
  className?: string;
}

export interface GridProps extends BaseLayoutProps {
  columns?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    "2xl"?: number;
  };
  autoFit?: boolean;
  minColumnWidth?: string;
  mode?: GridMode;
}

//Canvas Types
export type TextAlignment = "START" | "CENTER" | "END";
export type ImagePosition = "BACKGROUND" | "TOP" | "BOTTOM" | "LEFT" | "RIGHT";
export type TextPosition = "TOP" | "BOTTOM" | "OVERLAY";
export type SocialPlatform =
  | "INSTAGRAM"
  | "FACEBOOK"
  | "YOUTUBE"
  | "TIKTOK"
  | "LINKEDIN"
  | "X"
  | "PINTEREST"
  | "REDDIT"
  | "SNAPCHAT"
  | "SPOTIFY"
  | "TUMBLR"
  | "VIMEO"
  | "WHATSAPP"
  | "GITHUB"
  | "GMAIL";

export const SOCIAL_PLATFORMS = {
  INSTAGRAM: "INSTAGRAM",
  FACEBOOK: "FACEBOOK",
  YOUTUBE: "YOUTUBE",
  TIKTOK: "TIKTOK",
  LINKEDIN: "LINKEDIN",
  X: "X",
  PINTEREST: "PINTEREST",
  REDDIT: "REDDIT",
  SNAPCHAT: "SNAPCHAT",
  SPOTIFY: "SPOTIFY",
  TUMBLR: "TUMBLR",
  VIMEO: "VIMEO",
  WHATSAPP: "WHATSAPP",
  GITHUB: "GITHUB",
  GMAIL: "GMAIL",
} as const;

export type Orientation = "HORIZONTAL" | "VERTICAL";

export interface BaseContent {
  order: number;
  show: boolean;
}

export interface FileData {
  path: string;
  type: "EXTERNAL" | "FILE";
}

export interface TextContent extends BaseContent {
  size?: string;
  color?: string;
  decoration?: string;
  text: string;
  alignment?: TextAlignment;
}

export interface ImageContent extends BaseContent {
  file: FileData;
  border?: string;
  borderRadius?: string;
  borderColor?: string;
  title?: string;
  description?: string;
  textPosition?: TextPosition;
}

export interface AvatarContent extends BaseContent {
  file: FileData;
  border?: string;
  borderRadius?: string;
  borderColor?: string;
}

export interface SocialButton {
  platform: SocialPlatform;
  url: string;
  title: string | null;
}

export interface SocialButtonsContent extends BaseContent {
  buttons: SocialButton[];
  background?: string;
  border?: string;
  borderRadius?: string;
  borderSize?: string;
  highlightColor?: string;
  orientation: Orientation;
}

export interface LinkContent extends BaseContent {
  url: string;
  label: string;
  image?: {
    path: string;
    type: "EXTERNAL" | "FILE";
    imagePosition: ImagePosition;
  };
  background?: string;
  border?: string;
  borderRadius?: string;
  borderSize?: string;
  highlightColor?: string;
  color?: string;
}

export interface GroupContent extends BaseContent {
  items: any[];
  background?: string;
  border?: string;
  borderRadius?: string;
  borderSize?: string;
}

export type ElementContent =
  | TextContent
  | ImageContent
  | AvatarContent
  | SocialButtonsContent
  | LinkContent
  | GroupContent;
