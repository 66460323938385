import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import * as CleeckyKit from "CleeckyKit";

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100dvh;
  padding: 2rem;
  text-align: center;
  background: ${({ theme }) => theme.colors.background.base};
`;

const IconWrapper = styled(motion.div)`
  font-size: 6rem;
  color: ${({ theme }) => theme.colors.interactive.primary};
  margin-bottom: 2rem;
  opacity: 0.8;
`;

export const CleeckyKitNotFoundPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}>
      <CleeckyKit.Column maxWidth="600px" gap="1rem">
        <CleeckyKit.Row justify="center">
          <IconWrapper
            animate={{
              scale: [1, 1.1, 1],
              rotate: [0, 10, -10, 0],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              repeatType: "reverse",
            }}>
            <FontAwesomeIcon icon={faSearch} />
          </IconWrapper>
        </CleeckyKit.Row>

        <CleeckyKit.Row justify="center">
          <CleeckyKit.Title level={1}>
            {t("cleeckykit:pages.error_pages.404.title")}
          </CleeckyKit.Title>
        </CleeckyKit.Row>

        <CleeckyKit.Row justify="center">
          <CleeckyKit.Text>
            {t("cleeckykit:pages.error_pages.404.description")}
          </CleeckyKit.Text>
        </CleeckyKit.Row>

        <CleeckyKit.Row justify="center">
          <CleeckyKit.Button
            variant="solid"
            textPosition="inside-right"
            href="/"
            text={t("cleeckykit:pages.error_pages.404.back_to_home")}
            icon={<FontAwesomeIcon icon={faHome} />}
          />
        </CleeckyKit.Row>
      </CleeckyKit.Column>
    </Container>
  );
};
