import api from "../axiosConfig";

export const authService = {
  async login(identifier: string, password: string) {
    const response = await api.post("/auth/sign-in", { identifier, password });
    return response.data;
  },

  async signup(userData: {
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    password: string;
    mobilePhone?: string;
    landingPhone?: string;
  }) {
    const response = await api.post("/auth/sign-up", userData);
    return response.data;
  },

  async logout() {
    try {
      await api.post("/auth/sign-out");
      return true;
    } catch (error) {
      console.error("Logout error:", error);
      throw error;
    }
  },

  async getCurrentUser() {
    try {
      const response = await api.get("/auth/current-user");
      return response.data;
    } catch (error: any) {
      if (error.response?.status === 401) {
        document.cookie =
          "auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        localStorage.removeItem("user");
      }
      return null;
    }
  },
};
