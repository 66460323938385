import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { CleeckyKitButton } from "../Button/CleeckyKitButton";
import { useCleeckyKitOutsideClick } from "../../../hooks";
import { Z_INDEX } from "CleeckyKit/themes/zindex";
import { createPortal } from "react-dom";

interface DropdownButtonProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  label?: string;
  showArrow?: boolean;
  variant?: "solid" | "outline" | "link" | "icon";
  size?: "xsmall" | "small" | "medium" | "large";
  disabled?: boolean;
  className?: string;
  borderRadius?: boolean | number | string;
  fullWidth?: boolean;
  autoClose?: boolean;
  autoCloseDelay?: number;
  onOpenChange?: (isOpen: boolean) => void;
}

interface ChildProps {
  onClick?: (e: React.MouseEvent) => void | Promise<void>;
}

const DropdownContainer = styled.div<{ $fullWidth?: boolean }>`
  position: relative;
  display: inline-block;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
`;

const ArrowIcon = styled(motion.span)`
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
`;

const MenuContainer = styled(motion.div)<{
  $position: { top: boolean; left: boolean };
  $buttonRect: DOMRect | null;
}>`
  position: fixed;
  background: ${({ theme }) => theme.colors.background.dropdown};
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: ${Z_INDEX.buttons.dropdown.menu};
  min-width: 200px;
  max-width: 300px;
  overflow: hidden;
  transform-origin: top left;

  ${({ $position, $buttonRect }) => {
    if (!$buttonRect) return "";

    const topSpace = $buttonRect.top;
    const bottomSpace = window.innerHeight - $buttonRect.bottom;
    const leftSpace = $buttonRect.left;
    const rightSpace = window.innerWidth - $buttonRect.right;

    const openUpwards = bottomSpace < 200 && topSpace > bottomSpace;
    const openLeft = rightSpace < 200 && leftSpace > rightSpace;

    return `
      ${
        openUpwards
          ? `bottom: ${window.innerHeight - $buttonRect.top}px;`
          : `top: ${$buttonRect.bottom}px;`
      }
      
      ${
        openLeft
          ? `right: ${window.innerWidth - $buttonRect.right}px;`
          : `left: ${$buttonRect.left}px;`
      }
    `;
  }}

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.sm}) {
    max-width: 90dvw;
  }
`;

export const CleeckyKitDropdown: React.FC<DropdownButtonProps> = ({
  children,
  icon,
  label,
  showArrow = true,
  variant = "solid",
  size = "medium",
  disabled = false,
  className,
  borderRadius = true,
  fullWidth = false,
  autoClose = true,
  autoCloseDelay = 3000,
  onOpenChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [buttonRect, setButtonRect] = useState<DOMRect | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const autoCloseTimerRef = useRef<NodeJS.Timeout>();
  const [position, setPosition] = useState({ top: true, left: true });

  const handleClose = useCallback(() => {
    setIsOpen(false);
    onOpenChange?.(false);
  }, [onOpenChange]);

  const handleOpen = useCallback(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setButtonRect(rect);
    }
    setIsOpen(true);
    onOpenChange?.(true);

    if (autoClose) {
      if (autoCloseTimerRef.current) {
        clearTimeout(autoCloseTimerRef.current);
      }
      autoCloseTimerRef.current = setTimeout(handleClose, autoCloseDelay);
    }
  }, [autoClose, autoCloseDelay, handleClose, onOpenChange]);

  useEffect(() => {
    if (isOpen && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      setButtonRect(rect);
      setPosition({
        top: rect.bottom + 200 <= viewportHeight,
        left: rect.left + 200 <= viewportWidth,
      });
    }
  }, [isOpen]);

  // Limpa o timer quando o componente é desmontado
  useEffect(() => {
    return () => {
      if (autoCloseTimerRef.current) {
        clearTimeout(autoCloseTimerRef.current);
      }
    };
  }, []);

  useCleeckyKitOutsideClick(containerRef, handleClose);

  const wrappedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement<ChildProps>(child)) {
      return React.cloneElement(child, {
        onClick: async (e: React.MouseEvent) => {
          handleClose();
          if (child.props.onClick) {
            await child.props.onClick(e);
          }
        },
      });
    }
    return child;
  });

  return (
    <DropdownContainer
      ref={containerRef}
      $fullWidth={fullWidth}
      onMouseLeave={() => {
        if (autoClose && autoCloseTimerRef.current) {
          clearTimeout(autoCloseTimerRef.current);
          autoCloseTimerRef.current = setTimeout(handleClose, autoCloseDelay);
        }
      }}
      onMouseEnter={() => {
        if (autoCloseTimerRef.current) {
          clearTimeout(autoCloseTimerRef.current);
        }
      }}>
      <CleeckyKitButton
        onClick={() => (isOpen ? handleClose() : handleOpen())}
        icon={icon}
        text={label}
        textPosition="inside-right"
        variant={variant}
        size={size}
        isDisabled={disabled}
        className={className}
        borderRadius={borderRadius}
        fullWidth={fullWidth}
        suffix={
          showArrow && (
            <ArrowIcon
              animate={{ rotate: isOpen ? 180 : 0 }}
              transition={{ duration: 0.2 }}>
              <FontAwesomeIcon icon={faChevronDown} />
            </ArrowIcon>
          )
        }
      />

      {isOpen &&
        createPortal(
          <AnimatePresence>
            <MenuContainer
              ref={menuRef}
              $position={position}
              $buttonRect={buttonRect}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 0.1 }}>
              {wrappedChildren}
            </MenuContainer>
          </AnimatePresence>,
          document.body
        )}
    </DropdownContainer>
  );
};
