import React from "react";
import styled from "styled-components";

interface SectionProps {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  gap?: string | number;
  padding?: string | number;
  margin?: string | number;
  width?: string;
  height?: string;
  background?: string;
  borderRadius?: string | number;
  className?: string;
}

const StyledSection = styled.section<{
  $gap?: string | number;
  $padding?: string | number;
  $margin?: string | number;
  $width?: string;
  $height?: string;
  $borderRadius?: string | number;
}>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: ${({ $width }) => $width || "100%"};
  min-height: min-content;
  position: relative;
  z-index: 1;

  ${({ $gap }) =>
    $gap && `gap: ${typeof $gap === "number" ? `${$gap}px` : $gap};`}
  ${({ $padding }) =>
    $padding &&
    `padding: ${typeof $padding === "number" ? `${$padding}px` : $padding};`}
  ${({ $margin }) =>
    $margin &&
    `margin: ${typeof $margin === "number" ? `${$margin}px` : $margin};`}
  ${({ $height }) => $height && `height: ${$height};`}
  ${({ $borderRadius }) =>
    $borderRadius &&
    `border-radius: ${
      typeof $borderRadius === "number" ? `${$borderRadius}px` : $borderRadius
    };`}
`;

const BackgroundLayer = styled.div<{
  $background?: string;
  $borderRadius?: string | number;
}>`
  position: absolute;
  inset: 0;
  background: ${({ $background, theme }) =>
    $background ||
    theme.gradient?.highlightColor ||
    theme.colors.background.base};
  border-radius: ${({ $borderRadius }) =>
    $borderRadius
      ? typeof $borderRadius === "number"
        ? `${$borderRadius}px`
        : $borderRadius
      : "inherit"};
  z-index: -1;
`;

const SectionHeader = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 0;
`;

const SectionSubtitle = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin: 0.5rem 0 0 0;
`;

const ContentWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const CleeckyKitSection: React.FC<SectionProps> = ({
  children,
  title,
  subtitle,
  gap,
  padding,
  margin,
  width,
  height,
  background,
  borderRadius,
  className,
}) => {
  const sectionRef = React.useRef<HTMLElement>(null);

  return (
    <StyledSection
      ref={sectionRef}
      $gap={gap}
      $padding={padding}
      $margin={margin}
      $width={width}
      $height={height}
      $borderRadius={borderRadius}
      className={className}>
      {background && (
        <BackgroundLayer
          $background={background}
          $borderRadius={borderRadius}
        />
      )}
      {(title || subtitle) && (
        <SectionHeader>
          {title && <SectionTitle>{title}</SectionTitle>}
          {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
        </SectionHeader>
      )}
      <ContentWrapper>{children}</ContentWrapper>
    </StyledSection>
  );
};
